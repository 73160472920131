import background from '../../assets/vectors/background.svg';

export default (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.screenGrey.main,
    color: theme.palette.text.dark,
    padding: theme.spacing(5),
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'none',
      padding: theme.spacing(5, 0),
      width: '100%',
    },
  },
  header: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    opacity: '0.3',
    marginBottom: theme.spacing(5),
    display: 'flex',
  },
  icon: {
    fill: theme.palette.text.dark,
  },
  divider: {
    border: 'none',
    margin: 0,
    borderBottom: `1px solid ${theme.palette.text.dark}`,
    width: theme.spacing(6),
    opacity: '0.3',
  },
  video: {
    width: '640px',
    height: '360px',
    backgroundColor: theme.palette.text.dark,
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
    },
  },
  buttonWrapper: {
    width: theme.spacing(90),
    margin: theme.spacing(8, 0),
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      margin: theme.spacing(4, 0, 0),
      flexDirection: 'column-reverse',
      justifyContent: 'none',
    },
  },
  onlyButton: {
    justifyContent: 'center',
  },
  backButtonRoot: {
    color: theme.palette.text.main,
    height: theme.spacing(5),
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.spacing(1),
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      width: '100%',
      height: '100%',
      marginTop: theme.spacing(2),
    },
  },
  backButtonLabel: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
  },
  backButtonIcon: {
    stroke: theme.palette.primary.main,
  },
});
