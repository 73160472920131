import React from 'react';
import SectionHeader from '../../components/SectionHeader';
import LandingPage from '../../containers/LandingPage';

function HomePage({ classes }) {
  return (
    <div>
      <main>
        <SectionHeader
          title="Início"
          subTitle="Monte seu funil"
          showSignOut={false}
        />
        <LandingPage />
      </main>
    </div>
  );
}

export default HomePage;
