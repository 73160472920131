import { getWeekdaysDiff } from './date';
import { paths } from './paths';

export const workingDays = 21.74;

export const vh = (viewportUnits) => {
  const height = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0,
  );
  return (viewportUnits * height) / 100;
};

export const handleErrors = (error) => {
  if (error.code) {
    const { code } = error;
    if (code === 'auth/email-already-exists') {
      return alert('Já existe uma conta com esse email, por favor, informe outro email');
    }
    if (code === 'auth/invalid-email') {
      return alert('Informe um email válido');
    }
    if (code === 'auth/invalid-password') {
      return alert('Informe uma senha com pelo menos 6 caracteres');
    }
    if (code === 'auth/wrong-password') {
      return alert('Senha incorreta');
    }
    if (code === 'auth/user-not-found') {
      return alert('Email não existente');
    }
  }
  return alert('Houve um erro tente novamente mais tarde');
};

export const getActivityName = (activity) => {
  const activities = {
    email: 'Emails',
    call: 'Ligações',
    visit: 'Visitas',
    meeting: 'Reuniões',
    proposal: 'Propostas',
  };
  return activities[activity];
};

export const getPeriodName = (period) => {
  const periods = {
    day: 'dia',
    week: 'sem.',
    month: 'mês',
  };

  return periods[period];
};

export const roleOptions = [
  { value: '', label: '-' },
  { value: 'Empresário / CEO', label: 'Empresário / CEO' },
  { value: 'Diretor Comercial', label: 'Diretor Comercial' },
  { value: 'Gestor de Vendas', label: 'Gestor de Vendas' },
  { value: 'Gestor de Marketing', label: 'Gestor de Marketing' },
  { value: 'Supervisor(a) / Coordenador(a)', label: 'Supervisor(a) / Coordenador(a)' },
  { value: 'Profissional de TI', label: 'Profissional de TI' },
  { value: 'Vendedor Externo', label: 'Vendedor Externo' },
  { value: 'Vendedor Interno', label: 'Vendedor Interno' },
  { value: 'Representante Comercial', label: 'Representante Comercial' },
  { value: 'Consultor empresarial', label: 'Consultor empresarial' },
  { value: 'Secretária(o) / Assistente', label: 'Secretária(o) / Assistente' },
  { value: 'Outro', label: 'Outro' },
];

export const fieldOptions = [
  { value: '', label: '-' },
  { value: 'Autônomo', label: 'Autônomo' },
  { value: 'Agência de Comunicação / Digital', label: 'Agência de Comunicação / Digital' },
  { value: 'Agência de Turismo / Viagens', label: 'Agência de Turismo / Viagens' },
  { value: 'Automação comercial / residencial / industrial', label: 'Automação comercial / residencial / industrial' },
  { value: 'Beleza', label: 'Beleza' },
  { value: 'Concessionária de veículos', label: 'Concessionária de veículos' },
  { value: 'Consultoria de TI / RH / Marketing e outros', label: 'Consultoria de TI / RH / Marketing e outros' },
  { value: 'Contabilidade', label: 'Contabilidade' },
  { value: 'Corretora de Seguros', label: 'Corretora de Seguros' },
  { value: 'Distribuidora', label: 'Distribuidora' },
  { value: 'Ecommerce', label: 'Ecommerce' },
  { value: 'Editora', label: 'Editora' },
  { value: 'Educação', label: 'Educação' },
  { value: 'Escritório de advocacia', label: 'Escritório de advocacia' },
  { value: 'Eventos', label: 'Eventos' },
  { value: 'Imobiliária', label: 'Imobiliária' },
  { value: 'Importação / Exportação', label: 'Importação / Exportação' },
  { value: 'Indústria', label: 'Indústria' },
  { value: 'Lojista / Loja Física', label: 'Lojista / Loja Física' },
  { value: 'Prestador de serviços', label: 'Prestador de serviços' },
  { value: 'Representante comercial', label: 'Representante comercial' },
  { value: 'Saúde', label: 'Saúde' },
  { value: 'Software', label: 'Software' },
  { value: 'Tecnologia', label: 'Tecnologia' },
  { value: 'Telecomunicações', label: 'Telecomunicações' },
  { value: 'Outros', label: 'Outros' },
];

export const needsOptions = [
  { value: '', label: '-' },
  { value: 'Criar e acompanhar o processo de vendas', label: 'Criar e acompanhar o processo de vendas' },
  { value: 'Lembrar de fazer follow-up com os clientes', label: 'Lembrar de fazer follow-up com os clientes' },
  { value: 'Aprimorar a agilidade e eficiência nas vendas', label: 'Aprimorar a agilidade e eficiência nas vendas' },
  { value: 'Melhorar o poder de negociação durante as vendas', label: 'Melhorar o poder de negociação durante as vendas' },
  { value: 'Organizar e prospectar novos clientes', label: 'Organizar e prospectar novos clientes' },
  { value: 'Gerenciar a equipe de vendas e seu desempenho', label: 'Gerenciar a equipe de vendas e seu desempenho' },
  { value: 'Segmentar a base e trabalhar nos clientes ideiais', label: 'Segmentar a base e trabalhar nos clientes ideiais' },
  { value: 'Todas as opções acima', label: 'Todas as opções acima' },
  { value: 'Outra', label: 'Outra' },
];

export const teamSizeOptions = [
  { value: '', label: '-' },
  { value: '1 pessoa', label: '1 pessoa' },
  { value: '2 pessoas', label: '2 pessoas' },
  { value: '3 pessoas', label: '3 pessoas' },
  { value: '4 pessoas', label: '4 pessoas' },
  { value: '5 pessoas', label: '5 pessoas' },
  { value: '6 pessoas', label: '6 pessoas' },
  { value: '7 pessoas', label: '7 pessoas' },
  { value: '8 pessoas', label: '8 pessoas' },
  { value: '9 pessoas', label: '9 pessoas' },
  { value: '10 pessoas', label: '10 pessoas' },
  { value: '11 até 20 pessoas', label: '11 até 20 pessoas' },
  { value: '21 até 50 pessoas', label: '21 até 50 pessoas' },
  { value: '51 até 100 pessoas', label: '51 até 100 pessoas' },
  { value: 'mais que 101 pessoas', label: 'mais que 101 pessoas' },
];

export const getAvgActivitiesPerMonth = (period, activityCount) => {
  let avgActivitiesPerMonth = 0;
  if (period === 'month') avgActivitiesPerMonth = activityCount;
  else if (period === 'week') avgActivitiesPerMonth = activityCount * 4;
  else if (period === 'day') avgActivitiesPerMonth = activityCount * workingDays;
  return avgActivitiesPerMonth;
};

export const calculateActivityFrequency = ({
  avgActivitiesPerMonth,
  avgSalesPerMonth,
  numberOfSales,
  soldQuantity,
  endDate,
  period
}) => {

  const numberOfSalesLeft = (numberOfSales - soldQuantity) <= 0 ? 0 : numberOfSales - soldQuantity;
  const avgActivitiesPerSale = avgActivitiesPerMonth / avgSalesPerMonth;
  const totalActivities = avgActivitiesPerSale * numberOfSalesLeft;
  const weekdaysDiff = getWeekdaysDiff(new Date(), new Date(endDate));
  const activitiesPerWeekday = totalActivities / weekdaysDiff;

  let frequency = 0;

  if (period === 'month') frequency = activitiesPerWeekday * workingDays;
  else if (period === 'week') frequency = activitiesPerWeekday * 5;
  else if (period === 'day') frequency = activitiesPerWeekday;

  return Math.ceil(frequency);
};

export const calcAvgDoneActivities = (doneActivities, days, period) => {
  let avgDoneActivities = 0;

  if (days === 7) {
    if (period === 'day') avgDoneActivities = doneActivities / 5;
    else if (period === 'week') avgDoneActivities = doneActivities;
    else if (period === 'month') avgDoneActivities = doneActivities;
  } else if (days === 14) {
    if (period === 'day') avgDoneActivities = doneActivities / 10;
    else if (period === 'week') avgDoneActivities = doneActivities / 2;
    else if (period === 'month') avgDoneActivities = doneActivities;
  } else if (days === 30) {
    if (period === 'day') avgDoneActivities = doneActivities / workingDays;
    else if (period === 'week') avgDoneActivities = doneActivities / 4;
    else if (period === 'month') avgDoneActivities = doneActivities;
  }

  return Math.floor(avgDoneActivities);
};

export const calcCurrentFrequency = ({ lookbackMonths, count }) => {
  let currentFrequency = 0;
  let period = 'day';

  let avgPerMonth = count / lookbackMonths;

  if (avgPerMonth <= 3) { currentFrequency = Math.round(avgPerMonth); period = 'month'; }
  else if (avgPerMonth > 3 && avgPerMonth <= Math.ceil(workingDays)) { currentFrequency = Math.round(avgPerMonth/4); period = 'week'; }
  else { currentFrequency = Math.round(avgPerMonth/workingDays); period = 'day' }

  return { period, currentFrequency };
};

export const calculateSalesGoal = (dreamPrice, commission) => {
  return (dreamPrice && commission) ? parseInt(Math.ceil(dreamPrice / (commission / 100))) : 0;
};

export const calculateNumberOfSales = (salesGoal, avgTicket) => {
  return (salesGoal && avgTicket) ? parseInt(Math.ceil(salesGoal / avgTicket)) : 0;
};

export function isEmpty (prop, obj, notZero = false) {
  return (!(prop in obj) || obj[prop] === "" || obj[prop] === null || (notZero && obj[prop] === 0) )
}

export function getFirstUncompletedFunnelStep (funnelData) {
  var step = null;

  try {

    if ( isEmpty("dreamType", funnelData) || isEmpty("dreamText", funnelData) || isEmpty("dreamPrice", funnelData, true) || isEmpty("dreamDate", funnelData)) step = paths.dream;
    else if ( isEmpty("commission", funnelData, true) || isEmpty("salesGoal", funnelData, true) ) step = paths.salesGoal;
    else if ( isEmpty("avgTicket", funnelData, true) ) step = paths.avgTicket;
    else if ( isEmpty("activitiesFrequency", funnelData) || (
      isEmpty("currentFrequency", funnelData.activitiesFrequency[0], true)
      && isEmpty("currentFrequency", funnelData.activitiesFrequency[1], true)
      && isEmpty("currentFrequency", funnelData.activitiesFrequency[2], true)
      && isEmpty("currentFrequency", funnelData.activitiesFrequency[3], true)
      && isEmpty("currentFrequency", funnelData.activitiesFrequency[4], true)
    )) step = paths.activity;
    else if ( isEmpty("competences", funnelData) ) step = paths.competences;
    else step = paths.myFunnel;

  } catch (error) {
    handleErrors(error);
  }

  return step;
}
