export default (theme) => ({
  header: {
    display: 'flex',
    padding: theme.spacing(2, 3),
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logoWrapper:{
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    paddingLeft: 10,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 14,
    letterSpacing: '0.16em',
    textTransform: 'uppercase',
    color: '#0C0030',
  },
  subTitle: {
    color: theme.palette.text.light,
  },
  loginButtonRoot: {
    borderRadius: theme.spacing(3),
    padding: theme.spacing(1.5, 3.5),
    color: theme.palette.text.main,
    backgroundColor: theme.palette.screenGrey.main,
    '&:hover': {
      backgroundColor: theme.palette.screenGrey.dark,
    },
  },
  buttonLabel: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
    marginLeft: theme.spacing(1),
    textDecoration: 'none',
  },
  headerButtons: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    }
  },
  linkButtonRoot: {
    borderRadius: theme.spacing(3),
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
    height: 48,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'inherit',
    },
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
      textDecorationColor: theme.palette.primary.main,
    },
  },
});
