import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Hidden from '@material-ui/core/Hidden';
import SectionIntro from '../../components/SectionIntro';
import SideBar from '../../components/SideBar';
import SurveyHeader from '../../components/SurveyHeader';

import DreamType from './DreamType';
import DreamText from './DreamText';
import DreamPrice from './DreamPrice';
import DreamDate from './DreamDate';
import DreamInitDate from './DreamInitDate';

import { FunnelContext } from '../../contexts/FunnelContext';
import Api from '../../services/api';

import styles from './styles';
import { useDreamState } from './hooks';

import Analytics from '../../utils/analytics';

function Dream({ classes }) {
  const history = useHistory();
  const historyState = history.location && history.location.state;
  const step = historyState && historyState.step;

  const { funnelState, funnelDispatch, funnelUpdate } = useContext(FunnelContext);

  const [showIntro, setShowIntro] = useState(!step);
  const [dreamData, setDreamData] = useDreamState();

  const [questionIndex, setQuestionIndex] = useState(step || 0);

  useEffect(() => {
    let stepName = null;

    if (showIntro) stepName = 'Dream Intro';
    else if (questionIndex === 0) stepName = 'Dream Type';
    else if (questionIndex === 1) stepName = 'Dream Text';
    else if (questionIndex === 2) stepName = 'Dream Price';
    else if (questionIndex === 3) stepName = 'Dream Date';
    else if (questionIndex === 4) stepName = 'Dream Init Date';

    Analytics.log("onboarding_step_viewed", {
      step_name: stepName
    });
  }, [showIntro, questionIndex]);

  const handlePriceChange = (value) => {
    const price = value.replace(/\./g, '');
    if (price.match(/^[0-9]*$/)) {
      setDreamData({ ...dreamData, price: Number(price) });
    }
  };

  const handleChange = (key, value) => {
    setDreamData({ ...dreamData, [key]: value })
  };

  const goToNextSection = () => {
    const formatedDream = formatDream(dreamData);
    const newFunnelState = funnelUpdate({
      type: 'UPDATE',
      ...formatedDream
    });

    (() => {
      if (funnelState.createdAt) return Api.patch(`/funnels/${funnelState.id}`, newFunnelState);
      else return Api.post('/funnels', newFunnelState);
    })()
      .then(({ data }) => {
        if (data.id && data.createdAt) return funnelDispatch({ type: 'UPDATE', ...data });
      })
      .catch(() => alert('Houve um erro tente novamente mais tarde'));
    history.push('/meta-de-vendas');
  };

  const handleBackClick = () => setQuestionIndex(questionIndex - 1);
  const handleForwardClick = () => setQuestionIndex(questionIndex + 1);

  if (showIntro) {
    return (
      <SectionIntro
        header="Meu Sonho"
        title="Qual é o seu sonho?"
        subtitle="Seja bem-vindo(a). Sua jornada em busca da realização já começou! Veja no vídeo abaixo como definir seu Sonho da forma correta e aumente as chances de concretizá-lo."
        videoSrc="https://www.youtube.com/embed/-a0ogWajWHw?autoplay=1&rel=0&showinfo=0"
        onClick={() => setShowIntro(false)}
      />
    );
  }

  const questionsList = {
    0: (
      <DreamType
        type={dreamData.type}
        onChange={handleChange}
        onBackClick={() => setShowIntro(true)}
        onForwardClick={handleForwardClick}
      />
    ),
    1: (
      <DreamText
        text={dreamData.text}
        dreamType={dreamData.type}
        onTextChange={handleChange}
        onBackClick={handleBackClick}
        onForwardClick={handleForwardClick}
      />
    ),
    2: (
      <DreamPrice
        price={dreamData.price}
        onPriceChange={handlePriceChange}
        onBackClick={handleBackClick}
        onForwardClick={handleForwardClick}
      />
    ),
    3: (
      <DreamDate
        date={dreamData.date}
        onDateChange={handleChange}
        onBackClick={handleBackClick}
        onForwardClick={handleForwardClick}
      />
    ),
    4: (
      <DreamInitDate
        initDate={dreamData.initDate}
        onDateChange={handleChange}
        onBackClick={handleBackClick}
        onForwardClick={goToNextSection}
      />
    )
  };
  return (
    <section>
      <SideBar />
      <main className={classes.main}>
        <Hidden smDown>
          <SurveyHeader
            title="Início"
            subTitle="Monte seu funil"
          />
        </Hidden>
        { questionsList[questionIndex] }
      </main>
    </section>
  );
}

const formatDream = (dream) => {
  const today = new Date();
  const isToday = dream.date.toDateString() === today.toDateString();
  const date = isToday
    ? new Date(today.setMonth(today.getMonth() + 3))
    : dream.date;

  return {
    dreamType: dream.type,
    dreamText: dream.text,
    dreamPrice: dream.price,
    dreamDate: date,
    dreamInitDate: dream.initDate
  };
};

export default withStyles(styles)(Dream);
