import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';

import { FunnelContext } from '../../contexts/FunnelContext';
import { competences } from '../../utils/competences';

import SignOutButton from '../SignOutButton';
import PrintButton from '../PrintButton';
import Icon from '../Icon';

import Api from '../../services/api';
import { paths } from '../../utils/paths';

import styles from './styles';

function FunnelHeader({ classes, userName }) {
  const history = useHistory();
  const [isMenuOpen, toggleMenuOpen] = useState(false);
  const { funnelState, funnelDispatch } = useContext(FunnelContext);
  const [headerShadow, setHeaderShadow] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', showHeaderShadow);
    return () => window.removeEventListener('scroll', showHeaderShadow);
  }, []);

  const showHeaderShadow = () => {
    const scrollTop = (window.pageYOffset !== undefined)
      ? window.pageYOffset
      : (document.documentElement || document.body).scrollTop;
    setHeaderShadow(scrollTop > 40);
  };

  const resetFunnel = () => {
    if (window.confirm("Deseja realmente resetar seu funil? Você será direcionado para a tela inicial.")) {
      Api.delete('/funnels/me')
        .then((response) => {
          funnelDispatch({ type: 'RESET' });
          history.push(paths.dream);
        })
        .catch(e => alert('Não foi possível resetar seu funil. Por favor, relate o problema para contato@agendor.com.br'));
    }
  };

  const headerClasses = clsx(classes.header, {
    [classes.headerShadow]: headerShadow,
  });
  const separatorClasses = clsx(classes.separator, {
    [classes.separatorSmall]: headerShadow,
  });
  const sectionTextClasses = clsx(classes.sectionText, {
    [classes.sectionTextSmall]: headerShadow,
  });

  return (
    <header className={headerClasses}>
      <Icon name="logo" className={classes.logoIcon} />
      <Hidden smDown>
        <div className={classes.headerCompetences}>
          <div>
            <div className={classes.sectionHeader}>
              <p className={classes.sectionTitle}>Competências a aprimorar</p>
              <Icon
                name="edit"
                onClick={() => {
                  const { pathname } = history.location;
                  history.push(`${pathname}/atualizar-competencias`);
                }}
                className={classes.editIcon}
              />
            </div>
            {
              headerShadow
                ? (
                  <span className={sectionTextClasses}>
                    {funnelState.competences.length}
                    {' '}
                    competências
                  </span>
                )
                : (
                    funnelState.competences.length
                    ? (
                      funnelState.competences.map((competence) => (
                        <CompetenceCard
                          key={competence}
                          classes={classes}
                          competence={competence}
                        />
                      ))
                    )
                    : (
                      <p className={classes.noCompetenceText}>
                        Aguardando definição do seu gestor
                      </p>
                    )
                )
            }
          </div>
          <hr className={separatorClasses} />
          <div>
            <div className={classes.sectionHeader}>
              <p className={classes.sectionTitle}>Acompanhamento</p>
              <Icon
                name="edit"
                onClick={() => {
                  window.open('https://calendar.google.com/');
                }}
                className={classes.editIcon}
              />
            </div>
            {
              !headerShadow && (
                <Icon name="calendar" className={classes.calendarIcon} />
              )
            }
            <a
              className={sectionTextClasses}
              href="https://calendar.google.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Calendário
            </a>
          </div>
        </div>
      </Hidden>
      <div className={classes.menuWrapper}>

        <PrintButton />

        <span className={classes.name}>
          <span className={classes.nameIcon}><Icon name="permIdentity" className={classes.permIdentity} /></span>
          <div className={classes.nameText}>{userName}</div>
        </span>

        <Icon name="menu" className={classes.menuIcon} onClick={ e => toggleMenuOpen(!isMenuOpen)} />

        <Drawer
          anchor="right"
          open={isMenuOpen}
          onClose={ e => toggleMenuOpen(!isMenuOpen)}
          classes={{
            root: classes.drawer,
            paper: classes.drawerPaper
          }}
        >
          <List>
            <ListItem classes={{
              button: clsx(classes.drawerButton, classes.drawerButtonAlignRight)
            }} button onClick={ e => toggleMenuOpen(!isMenuOpen) }>
              <Icon name="close" className={classes.closeIcon} />
            </ListItem>
            <ListItem classes={{ button: classes.drawerButton }} button onClick={() => {
              const { pathname } = history.location;
              history.push(`${pathname}/atualizar-competencias`);
            }}>
              Minhas competências
            </ListItem>
            <ListItem classes={{ button: classes.drawerButton }} button onClick={() => {
              window.open('https://calendar.google.com/');
            }}>
              Abrir calendário do Google
            </ListItem>
            <ListItem classes={{ button: classes.drawerButton }} button onClick={resetFunnel}>
              Refazer meu funil
            </ListItem>
            <ListItem classes={{ root: classes.drawerItemDense }}>
              <SignOutButton
                classes={{
                  root: classes.signOutButtonRoot,
                  label: classes.signOutButtonLabel
                }}
              />
            </ListItem>
          </List>
        </Drawer>
      </div>
    </header>
  );
}

function CompetenceCard({ classes, competence }) {
  const selectedCompetence = competences.find(({ title }) => title === competence);
  return (
    <Tooltip arrow placement="top" title={competence}>
      <div className={classes.competenceCard}>
        <Icon name={selectedCompetence.icon} className={classes.competenceIcon} />
      </div>
    </Tooltip>
  );
}

export default withStyles(styles)(FunnelHeader);
