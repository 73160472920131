import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import Icon from '../Icon';
import styles from './styles';

function SuccessSquare({ classes, large }) {
  const squareClasses = clsx(classes.square, {
    [classes.squareLarge]: large,
  });
  const checkClasses = clsx(classes.checkIcon, {
    [classes.checkIconLarge]: large,
  });
  return (
    <div className={squareClasses}>
      <Icon name="check" className={checkClasses} />
    </div>
  );
}

export default withStyles(styles)(SuccessSquare);
