export default (theme) => ({
  card: {
    border: `4px solid ${theme.palette.screenGrey.light}`,
    borderRadius: theme.spacing(1.5),
    width: theme.spacing(60),
    margin: '0 auto',
    position: 'relative',
    padding: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      width: '70vw',
      padding: theme.spacing(2),
      boxSizing: 'border-box',
    },
    '&:hover': {
      backgroundColor: theme.palette.screenGrey.light,
    },
  },
  title: {
    fontWeight: 'bold',
    letterSpacing: '0.04em',
    color: '#0C0030',
    marginTop: 0,
    textTransform: 'uppercase',
    width: theme.spacing(31),
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    '@media print': {
      marginBottom: '0px'
    }
  },
  updatedAt: {
    cursor: 'pointer',
    marginLeft: theme.spacing(10),
    display: 'inline-block',
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    textAlign: 'right',
    color: '#A49AB8',
    alignSelf: 'end',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    '@media print': {
      display: 'none'
    }
  },
  progressHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    alignItems: 'baseline',
  },
  missingSales: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    lineHeight: '1.625rem',
    color: theme.palette.primary.main,
  },
  soldSales: {
    fontWeight: 'bold',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: theme.palette.grey.dark,
  },
  barWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  missingBar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    height: theme.spacing(1),
    display: 'inline-block',
  },
  soldBar: {
    backgroundColor: theme.palette.success.main,
    borderRadius: theme.spacing(1),
    height: theme.spacing(1),
    display: 'inline-block',
  },
  missingSalesLegend: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    color: '#A49AB8',
    paddingLeft: theme.spacing(2),
    display: 'inline-block',
    marginBottom: theme.spacing(1),
    position: 'relative',
    '&:before': {
      content: '""',
      top: 7,
      left: 0,
      position: 'absolute',
      width: 6,
      height: 6,
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
  },
  soldSalesLegend: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    color: '#A49AB8',
    paddingLeft: theme.spacing(2),
    marginLeft: theme.spacing(8),
    display: 'inline-block',
    position: 'relative',
    '&:before': {
      content: '""',
      top: 7,
      left: 0,
      position: 'absolute',
      width: 6,
      height: 6,
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.success.main,
    },
  },
  legend: {
    marginTop: theme.spacing(2.5),
    paddingRight: theme.spacing(1),
    display: 'flex',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
  },
  extraInfo: {
    marginTop: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  infoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoTitle: {
    fontWeight: 400,
    fontSize: '0.875rem',
    color: '#0C0030',
    marginBottom: theme.spacing(1),
  },
  infoValue: {
    fontWeight: 700,
    fontSize: '0.75rem',
    color: '#0C0030',
    marginBottom: theme.spacing(1),
  },
});
