/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import FunnelHeader from '../../components/FunnelHeader';
import Funnel from '../../containers/Funnel';
import { UserContext } from '../../contexts/UserContext';
import Api from '../../services/api';

function UserFunnelPage() {
  const [funnel, setFunnel] = useState();
  const [funnelUser, setFunnelUser] = useState();

  const { userState } = useContext(UserContext);

  let { id } = useParams();

  useEffect(() => {
    if (userState && userState.id) {

      Promise.all([
        Api.get(`/funnels/${id}`),
        Api.get(`/users/${id}`),
      ])
        .then(([funnelResp, userResp]) => {
          setFunnel(funnelResp.data);
          setFunnelUser(userResp.data);
        });
    }
  }, [userState]);

  if (userState.id === null) {
    return <Redirect to='/' />;
  }

  if (funnel && funnelUser) {
    return (
      <>
        <FunnelHeader userName={userState.name} />
        <Funnel funnel={funnel} funnelUser={funnelUser} isManager />
      </>
    );
  }

  return null;
}

export default UserFunnelPage;
