import React, {
  useState,
  useContext,
  useRef,
  useEffect,
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Hidden from '@material-ui/core/Hidden';
import { vh } from '../../utils';
import Analytics from '../../utils/analytics';

import ActivitiesCard from './ActivitiesCard';
import SalesCard from './SalesCard';
import GoalCard from './GoalCard';
import FunnelResultModal from './FunnelResultModal';
import DreamStep from './DreamStep';
import styles from './styles';
import SideFunnel from './SideFunnel';
import Separator from './Separator';
import AgendorIntegrationButton from '../../components/AgendorIntegrationButton';
import Toast from '../../components/Toast';

import { UserContext } from '../../contexts/UserContext';

import Api from '../../services/api';

function Funnel({
  classes, funnel, funnelUser, getDataFromAgendor, isManager
}) {
  const funnelRef = useRef();
  const activitiesRef = useRef();
  const salesRef = useRef();
  const goalRef = useRef();
  const dreamRef = useRef();

  const {userState, userDispatch} = useContext(UserContext);

  const [selectedFunnel, setSelectedFunnel] = useState('activities');
  const [isIntegrationActive, setIsIntegrationActive] = useState(Boolean(userState.agendorToken));

  const [isOpenEditGoalModal, setIsOpenEditGoalModal] = useState(Boolean(funnel.viewedFunnelResult));
  const [isOpenFunnelResultModal, setIsOpenFunnelResultModal] = useState(!Boolean(funnel.viewedFunnelResult));

  const [toastProps, setToastProps] = useState({
    slide: false,
    show: false,
    title: null,
    text: null
  });

  useEffect(() => {
    Analytics.log("completed_funnel_viewed");

    selectSideMenu();
    window.addEventListener('scroll', selectSideMenu);
    return () => window.removeEventListener('scroll', selectSideMenu);
  }, []);

  useEffect(() => {
    setIsOpenEditGoalModal(Boolean(funnel.viewedFunnelResult) && !isOpenFunnelResultModal);
  }, [funnel.viewedFunnelResult, isOpenFunnelResultModal]);

  const selectSideMenu = () => {
    const activitiesY = activitiesRef.current.getBoundingClientRect().y;
    const salesY = salesRef.current.getBoundingClientRect().y;
    const goalY = goalRef.current.getBoundingClientRect().y;
    const dreamY = dreamRef.current.getBoundingClientRect().y;

    if (activitiesY >= vh(20) && activitiesY <= vh(80)) {
      setSelectedFunnel('activities');
    }
    if (salesY >= vh(20) && salesY <= vh(80)) {
      setSelectedFunnel('sales');
    }
    if (goalY >= vh(20) && goalY <= vh(80)) {
      setSelectedFunnel('goal');
    }
    if (dreamY >= vh(20) && dreamY <= vh(80)) {
      setSelectedFunnel('dream');
    }
  };

  const scrollToActivites = () => {
    const { y } = activitiesRef.current.getBoundingClientRect();
    scrollTo(y);
  };

  const scrollToSales = () => {
    const { y } = salesRef.current.getBoundingClientRect();
    scrollTo(y);
  };

  const scrollToGoal = () => {
    const { y } = goalRef.current.getBoundingClientRect();
    scrollTo(y);
  };

  const scrollToDream = () => {
    const { y } = dreamRef.current.getBoundingClientRect();
    scrollTo(y);
  };

  const scrollTo = (elementY) => {
    const { y } = funnelRef.current.getBoundingClientRect();
    const scrollTop = elementY - vh(62) - y + 248;
    window.scrollTo({ top: scrollTop, behavior: 'smooth' });
  };

  const handleIntegrationActivation = () => {
    getDataFromAgendor();
    setIsIntegrationActive(true);
  };

  const handleIntegrationDeactivation = () => {
    setIsIntegrationActive(false);
    userDispatch({ type: "UPDATE", "agendorToken": "" });
    return Api.patch('/profiles/me', {
      agendorToken: null
    })
  };

  return (
    <>
      <Hidden smDown>
        <SideFunnel
          selectedFunnel={selectedFunnel}
          firstStepClick={scrollToActivites}
          secondStepClick={scrollToSales}
          thirdStepClick={scrollToGoal}
          fourthStepClick={scrollToDream}
        />
      </Hidden>
      <main ref={funnelRef} className={clsx(classes.main)}>
        { !isManager && (
        <AgendorIntegrationButton
          classes={{
            button: classes.agendorIntegrationButton
          }}
          isActive={isIntegrationActive}
          onActiveClick={handleIntegrationDeactivation}
          onInactiveClick={handleIntegrationActivation}
          inactiveButtonLabel='Integrar com dados do Agendor'
          activeButtonLabel='Integrado com o Agendor'
          location='Completed Funnel'
        />
        )}
        <FunnelResultModal
          open={isOpenFunnelResultModal}
          onClose={() => setIsOpenFunnelResultModal(false)}
          onChangeDate={() => setToastProps({
            slide: true,
            show: true,
            title: 'Alteração realizada',
            text: 'O prazo do seu sonho foi atualizado',
          })}
          onChangePrice={() => setToastProps({
            slide: true,
            show: true,
            title: 'Alteração realizada',
            text: 'O valor do seu sonho foi atualizado',
          })}
        />
        <ActivitiesCard
          ref={activitiesRef}
          activites={funnel.activitiesFrequency}
          funnelUser={funnelUser}
          funnel={funnel}
          isManager={isManager}
          dreamDate={funnel.dreamDate}
          hasAgendor={Boolean(funnelUser.agendorToken)}
        />
        <Separator
          handleUpClick={scrollToActivites}
          handleDownClick={scrollToSales}
        />
        <SalesCard
          ref={salesRef}
          isManager={isManager}
          avgTicket={funnel.avgTicket}
          numberOfSales={funnel.numberOfSales}
          salesGoal={funnel.salesGoal}
          soldValue={funnel.soldValue}
          soldQuantity={funnel.soldQuantity}
          funnelUser={funnelUser}
          funnel={funnel}
        />
        <Separator
          handleUpClick={scrollToSales}
          handleDownClick={scrollToGoal}
        />
        <GoalCard
          ref={goalRef}
          funnel={funnel}
          funnelUser={funnelUser}
          isManager={isManager}
          isOpenEditGoalModal={isOpenEditGoalModal}
          handleToggleEditGoalModal={setIsOpenEditGoalModal}
        />
        <Separator
          handleUpClick={scrollToGoal}
          handleDownClick={scrollToDream}
        />
        <DreamStep
          ref={dreamRef}
          dreamText={funnel.dreamText}
          dreamPrice={funnel.dreamPrice}
          dreamDate={funnel.dreamDate}
          funnelUser={funnelUser}
          isManager={isManager}
        />
        <Toast
          toastClasses={{
            wrapper: classes.toast
          }}
          onEnter={() => {
            setTimeout(function(){
              setToastProps({
                ...toastProps,
                show: false
              })
            }, 3000);
          }}
          floating={true}
          slide={toastProps.slide}
          show={toastProps.show}
          title={toastProps.title}
          text={toastProps.text}
        />
      </main>
    </>
  );
}

export default withStyles(styles)(Funnel);
