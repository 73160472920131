export default (theme) => ({
  tooltip: {
    display: 'block',
    color: '#fff',
    backgroundColor: theme.palette.grey.dark,
    boxShadow: '0px 16px 40px rgba(65, 81, 122, 0.2)',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5),
    maxWidth: '200px',
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: '16px',
    textAlign: 'center'
  },
  arrow: {
    color: theme.palette.grey.dark
  }
});