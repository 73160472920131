import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import SectionHeader from '../../components/SectionHeader';
import Auth from '../../components/Auth';
import { UserContext } from '../../contexts/UserContext';

import Api from '../../services/api';
import { auth } from '../../services/firebase';
import { handleErrors } from '../../utils';
import Analytics from '../../utils/analytics';

function Signup() {
  const history = useHistory();
  const historyState = history.location && history.location.state;
  const emailFromLogin = historyState && historyState.email;
  const [isLoading, setIsLoading] = useState(false);
  const { userDispatch } = useContext(UserContext);

  const inviteeId = sessionStorage.getItem("inviteeId") || null;

  useEffect(() => {
    Analytics.log("sign_up_visited");
  }, []);

  const handleSignupClick = ({ name, email, password }) => {

    setIsLoading(true);

    const newUser = { name, email, password };
    if (inviteeId) newUser.lastViewedTeamMemberId = inviteeId;

    Api
      .post('/signup', newUser)
      .then(() => {
        auth
          .signInWithEmailAndPassword(email, password)
          .then(() => {
            Analytics.log("sign_up", { name, email });
            Api.get('/users/me')
            .then((userResp) => {
              userDispatch({ type: 'UPDATE', ...userResp.data });
              setIsLoading(false);
              history.push('/perfil');
            });
          });
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          handleErrors(error.response.data);
        } else {
          handleErrors(error);
        }
      });
  };

  return (
    <section>
      <SectionHeader
        title="Início"
        subTitle="Monte seu funil"
        showSignup={false}
        showLogin={true}
        showSignOut={false}
      />
      <Auth
        title="Crie seu perfil"
        subtitle="Preencha o formulário abaixo para criar uma conta gratuita no Funil dos Sonhos."
        passCaption="(deve possuir pelo menos 6 caracteres)"
        buttonText="Criar Perfil"
        onClick={handleSignupClick}
        isSignup
        isLoading={isLoading}
        email={emailFromLogin}
      />
    </section>
  );
}

export default Signup;
