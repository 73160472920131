export default (theme) => ({
  main: {
    marginLeft: '18.75vw',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      alignItems: 'center',
    },
  },
});
