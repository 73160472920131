/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  useEffect,
  createContext,
  useContext,
  useReducer,
} from 'react';

import { auth } from '../services/firebase';
import Api from '../services/api';

import { FunnelContext } from './FunnelContext';

const initialState = { };

const exposeUserToWindow = (userState) => {
  window.userId = userState.id;
  window.userName = userState.name;
  window.userEmail = userState.email;
  window.jobTitle = userState.jobTitle;
  window.businessField = userState.businessField;
  window.teamSize = userState.teamSize;
  window.phone = userState.phone;
  window.website = userState.website;
  window.leadOrigin = userState.leadOrigin;
}

export const UserContext = createContext(initialState);

export const UserProvider = ({ children }) => {
  const { funnelDispatch } = useContext(FunnelContext);

  const [userState, userDispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'UPDATE':
        return {
          ...prevState,
          ...action,
        };
      case 'RESET':
        return initialState;
      default:
        return prevState;
    }
  }, initialState);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        Api.get('/users/me')
          .then(userResp => {
            const funnelId = userResp.data.lastViewedTeamMemberId ? userResp.data.lastViewedTeamMemberId : userResp.data.id;

            exposeUserToWindow(userResp.data);

            return Api.get('/funnels/' + funnelId)
              .then((funnelResp) => {
                funnelDispatch({ type: 'UPDATE', ...funnelResp.data });
                return userDispatch({ type: 'UPDATE', ...userResp.data });
              });
          });
      } else {
        return userDispatch({ type: 'UPDATE', id: null });
      }
    });
  }, []);

  return (
    <UserContext.Provider value={{ userState, userDispatch }}>
      {children}
    </UserContext.Provider>
  );
};
