/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Button as MuiButton } from '@material-ui/core';
import Icon from '../Icon';
import Button from '../Button';

import styles from './styles.js';

function QuestionStructure({
  classes,
  children,
  title,
  subtitle,
  titleNumber,
  onBackClick,
  onForwardClick,
  isForwardDisabled,
  forwardButtonLabel = "Prosseguir"
}) {

  useEffect(() => {
    const handleEnter = (e) => {
      if (!isForwardDisabled && e && e.keyCode === 13) {
        onForwardClick();
      }
    };
    window.addEventListener('keydown', handleEnter);
    return () => window.removeEventListener('keydown', handleEnter);
  }, [isForwardDisabled, onForwardClick]);

  return (
    <div className={classes.wrapper}>
      <Question
        classes={classes}
        number={titleNumber}
        title={title}
        subtitle={subtitle}
      />
      <div className={classes.content}>
        { children }
      </div>
      <div className={classes.stepButtons}>
        <MuiButton
          startIcon={
            <Icon name="arrow" className={classes.backButtonIcon} />
          }
          classes={{
            root: classes.backButtonRoot,
            label: classes.backButtonLabel,
          }}
          onClick={onBackClick}
        >
          VOLTAR
        </MuiButton>
        <Button
          disabled={isForwardDisabled}
          onClick={onForwardClick}
          completed={!isForwardDisabled}
          type="submit"
        >
          {forwardButtonLabel}
        </Button>
      </div>
    </div>
  );
}

function Question({
  classes, title, number, subtitle,
}) {
  return (
    <div className={classes.question}>
      {
        number && (
          <span className={clsx(classes.text, classes.number)}>
            {number}
            .
          </span>
        )
      }
      <div>
        <h2 className={classes.text}>
          { title }
        </h2>
        <h4 className={classes.subtitle}>
          { subtitle }
        </h4>
      </div>
    </div>
  );
}

export default withStyles(styles)(QuestionStructure);
