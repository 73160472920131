export const competences = [
  {
    icon: 'investigativeAbility',
    title: 'Capacidade Investigativa',
    description: 'Fazer boas perguntas e saber ouvir o cliente com atenção é fundamental para entender as necessidades dos clientes e realizar uma boa venda. O bom vendedor primeiro entende as dificuldades do cliente para depois apresentar a solução.',
  },
  {
    icon: 'comunication',
    title: 'Comunicação',
    description: 'Seja transparente e objetivo na sua comunicação, seja com os seus clientes, colegas ou líder. Saiba o momento certo de falar e o momento de ouvir.',
  },
  {
    icon: 'discipline',
    title: 'Disciplina',
    description: 'Disciplina para seguir os processos de vendas, honrar os seus compromissos com clientes e as atividades diárias.',
  },
  {
    icon: 'resultsOrientation',
    title: 'Orientação para resultados',
    description: 'Seja capaz de focar em seus objetivos e trabalhar duro para garantir que os resultados sejam entregues.',
  },
  {
    icon: 'commercialTenacity',
    title: 'Tenacidade comercial',
    description: 'Seja otimista, persistente e jamais desista de uma venda na primeira negativa. Seja agradavelmente insistente com as suas oportunidades de venda.',
  },
  {
    icon: 'agility',
    title: 'Agilidade',
    description: 'Seja ágil nas respostas aos seus clientes e na resolução de problemas. Isto demonstra profissionalismo, maturidade e gera confiança.',
  },
  {
    icon: 'selfMotivation',
    title: 'Automotivação',
    description: 'Capacidade de motivar a si mesmo, provocada por estímulos internos. Bons vendedores encontram motivos dentro de si para alcançar os seus objetivos e agem de acordo com o que tem que ser feito.',
  },
];
