/* eslint-disable no-unused-vars */

import Joi from 'joi';
import DataLayer from './datalayer';
import RDStation from '../services/rdstation';
import Mautic from '../services/mautic';
import { Firebase } from '../services/firebase';

const events = [
	'homepage_visited',
	'sign_up_clicked',
	'login_clicked',
	'whatsapp_share_clicked',
	'login_visited',
	'sign_up_visited',
	'sign_up',
	'sign_out',
	'login',
	'profile_updated',
	'profile_update_visited',
	'integration_activated',
	'agendor_sign_up_clicked',
	'onboarding_step_viewed',
	'manager_invited',
	'manager_invitation_skipped',
	'follow_up_defined',
	'follow_up_definition_skipped',
	'completed_funnel_viewed',
	'print_clicked',
	'total_sales_updated',
	'funnel_result_hint_accepted',
	'funnel_result_skipped'
];

const userPropertiesSchema = Joi.object({
	is_manager: Joi.boolean().allow(null, ''),
	company_needs: Joi.string().allow(null, ''),
	team_size: Joi.string().allow(null, ''),
	business_field: Joi.string().allow(null, ''),
	job_title: Joi.string().allow(null, '')
});

const Analytics = {
	log: (eventName, properties = {}, value = 0) => {
		const isValidEvent = events.includes(eventName);
		if (isValidEvent) {
			DataLayer.push(eventName, properties, value);
			Firebase.exec(eventName, properties);
			// RDStation.exec(eventName, properties);
			Mautic.exec(eventName, properties);
		}
	},
	setUserProperties: function(properties = {}){
		const isValidUserProperties = userPropertiesSchema.validate(properties);
		if (!isValidUserProperties.error) {
			Firebase.setUserProperties(properties);
		} else {
			console.log(isValidUserProperties);
			return isValidUserProperties;
		}
	}
};

export default Analytics;
