/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { UserContext } from '../../contexts/UserContext';
import Competences from '../../containers/Competences';

import styles from './styles';

function ManagerInvite({ classes }) {
  const { userState } = useContext(UserContext);
  const history = useHistory();

  const historyState = history.location.state;

  let { inviteeId } = useParams();

  useEffect(() => {
    if (inviteeId) {
      sessionStorage.setItem('inviteeId', inviteeId);
    } else {
      sessionStorage.removeItem('inviteeId');
    }
  }, []);

  if (userState && Object.keys(userState).length) {
    if (userState.id) {
      return <Competences />;
    }

    if (!(historyState && historyState.isManager)) {
      return <Redirect to="/cadastrar" />;
    }
  }

  return (
    <div className={classes.loadingWrapper}>
      <CircularProgress />
    </div>
  );
}

export default withStyles(styles)(ManagerInvite);
