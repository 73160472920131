/* eslint-disable no-unused-vars */

const DataLayer = {
	push: function(eventName, properties = {}, value = 0) {
		if (window.dataLayer && eventName) {
			let data = {
				event: 'eventTrigger',
				eventCategory: eventName,
				eventAction: eventName,
				eventLabel: eventName,
				eventProperties: properties,
				eventValue: value
			}
			window.dataLayer.push(data);
		}
	}
};

export default DataLayer;