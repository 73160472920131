import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import PrivateRoute from './components/PrivateRoute';
import ScrollToTop from './components/ScrollToTop';
import { paths } from './utils/paths';

import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dream from './pages/Dream';
import SalesGoal from './pages/SalesGoal';
import AvgTicket from './pages/AvgTicket';
import Activity from './pages/Activity';
import Competences from './pages/Competences';
import FunnelComplete from './pages/FunnelComplete';
import Profile from './pages/Profile';
import ManagerInvite from './pages/ManagerInvite';
import MyFunnel from './pages/MyFunnel';
import UserFunnel from './pages/UserFunnel';
import UpdateCompetences from './pages/UpdateCompetences';

function Routes() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/entrar" component={Login} />
          <Route path="/cadastrar" component={Signup} />
          <PrivateRoute path={paths.profile} component={Profile} />
          <PrivateRoute path={paths.dream} component={Dream} />
          <PrivateRoute path={paths.salesGoal} component={SalesGoal} />
          <PrivateRoute path={paths.avgTicket} component={AvgTicket} />
          <PrivateRoute path={paths.activity} component={Activity} />
          <PrivateRoute path={paths.funnelComplete} component={FunnelComplete} />
          <Route path={paths.competences} component={Competences} />
          <Route path="/convite/:id" component={ManagerInvite} />
          <Route exact path="/funil/atualizar-competencias" component={UpdateCompetences} />
          <Route exact path="/funil/:id/atualizar-competencias" component={UpdateCompetences} />
          <Route exact path="/funil/:id" component={UserFunnel} />
          <PrivateRoute exact path={paths.myFunnel} component={MyFunnel} />
          <Route path="*" component={Home} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default Routes;
