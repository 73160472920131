import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import { FunnelContext } from '../../contexts/FunnelContext';
import { UserContext } from '../../contexts/UserContext';

import { auth } from '../../services/firebase';

import Analytics from '../../utils/analytics';

function SignOutButton ({ classes, label = 'Sair' }) {

	const history = useHistory();
	const { funnelDispatch } = useContext(FunnelContext);
	const { userDispatch } = useContext(UserContext);

	return (
		<Button
		  classes={classes}
		  onClick={() => {
		    auth
		      .signOut()
		      .then(() => {
						Analytics.log("sign_out");
		        funnelDispatch({ type: 'RESET' });
		        userDispatch({ type: 'RESET' });
		        sessionStorage.removeItem('inviteeId');
		        history.push('/');
		      });
		  }
		}>
		  {label}
		</Button>
	);
};

export default SignOutButton;