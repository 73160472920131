export default (theme) => ({
  slider: {
    width: theme.spacing(60),
    paddingLeft: theme.spacing(8),
    marginTop: theme.spacing(11),
    marginBottom: theme.spacing(5),
    display: 'inline-block',
  },
  thumb: {
    height: '20px',
    width: '20px',
    marginTop: '-7px',
    marginLeft: '-7px'
  },
  markLabel: {
    color: theme.palette.text.main,
    fontSize: '1.125rem',
    marginTop: '10px',
    marginLeft: '3px'
  },
  track: {
    height: '6px'
  },
  rail: {
    height: '6px',
    backgroundColor: '#e9e3f5'
  },
  mark: {
    marginTop: '-4px',
    marginLeft: '-4px',
    width: '16px',
    height: '16px',
    borderRadius: '8px',
    background: '#FFFFFF',
    border: '4px solid #A179FF',
    boxSizing: 'border-box'
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
    borderColor: '#7249E8'
  },
});
