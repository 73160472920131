import ball from '../../assets/vectors/landingPage/ball.svg';
import dots from '../../assets/vectors/landingPage/dots.svg';
import jujuba from '../../assets/vectors/landingPage/jujuba.svg';
import darkJujuba from '../../assets/vectors/landingPage/dark-jujuba.svg';
import lines from '../../assets/vectors/landingPage/lines.svg';
import pastel from '../../assets/vectors/landingPage/pastel.svg';
import triangleBlue from '../../assets/vectors/landingPage/triangle-blue.svg';
import triangleYellow from '../../assets/vectors/landingPage/triangle-yellow.svg';
import dreamer from '../../assets/vectors/landingPage/dreamer.svg';

export default (theme) => ({
  ball: {
    position: 'absolute',
    backgroundImage: `url(${ball})`,
    width: 33,
    height: 33,
  },
  topBallPosition: {
    top: '-10%',
    left: '-8%',
    [theme.breakpoints.down('sm')]: {
      left: 0,
    },
  },
  bottomBallPosition: {
    left: '62%',
    top: '85%',
    [theme.breakpoints.down('sm')]: {
      top: '100%',
      left: 'inherit',
      right: '22%',
    },
  },
  showSomeoneBallPosition: {
    left: '4%',
    top: '50%',
    [theme.breakpoints.down('sm')]: {
      top: '87%',
      left: 'inherit',
      right: '10%',
    },
  },
  dots: {
    position: 'absolute',
    backgroundImage: `url(${dots})`,
    width: 80,
    height: 206,
  },
  dotsPosition:{
    right: 0,
    top: '50vh',
    [theme.breakpoints.down('md')]: {
      top: 128,
    }
  },
  dreamer: {
    position: 'absolute',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${dreamer})`,
    width: 526,
    height: 470,
  },
  dreamerPosition: {
    right: '-5%',
    top: -15 ,
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      top: '20%',
      right: 0,
      zIndex: -10,
      width: '60%',
    },
    [theme.breakpoints.down('xs')]: {
      top: '30%',
      width: '60%',
    },
  },
  jujuba: {
    position: 'absolute',
    backgroundImage: `url(${jujuba})`,
    width: 67,
    height: 67,
  },
  darkJujuba: {
    position: 'absolute',
    backgroundImage: `url(${darkJujuba})`,
    width: 84,
    height: 98,
  },
  showSomeoneJujubaPosition: {
    left: '73%',
    top: '60%',
    [theme.breakpoints.down('sm')]: {
      top: '12%',
      left: 'inherit',
      right: 0,
      width: 52,
    },
  },
  lines: {
    position: 'absolute',
    backgroundImage: `url(${lines})`,
    width: 103,
    height: 225,
  },
  linesPosition: {
    left: 0,
    top: '60vh',
    [theme.breakpoints.down('sm')]: {
      top: '40vh',
    },
  },
  pastel: {
    position: 'absolute',
    backgroundImage: `url(${pastel})`,
    width: 132,
    height: 84,
  },
  showSomeonePastelPosition: {
    left: '30%',
    top: 0,
    [theme.breakpoints.down('sm')]: {
      left: '-20%',
    },
  },
  triangleBlue: {
    position: 'absolute',
    backgroundImage: `url(${triangleBlue})`,
    width: 110,
    height: 104,
  },
  triangleBluePosition: {
    top: '10%',
    left: '-15%',
    [theme.breakpoints.down('sm')]: {
      top: '44%',
      left: '-20%',
    },
  },
  showSomeoneTriangleBluePosition: {
    left: '-3%',
    top: '56%',
    [theme.breakpoints.down('sm')]: {
      left: '-17%',
      top: '74%',
    },
  },
  triangleYellow: {
    position: 'absolute',
    backgroundImage: `url(${triangleYellow})`,
    width: 86,
    height: 75,
  },
  triangleYellowPosition: {
    top: 17,
    right: '43%',
    [theme.breakpoints.down('sm')]: {
      top: -67,
      right: '28%',
    }
  },
  emojiBubble: {
    position: 'absolute',
    padding: 16,
    fontSize: '2.5em',
    background: 'white',
    boxShadow: '0px 20px 50px rgba(99, 81, 153, 0.2)',
    borderRadius: 22,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em',
      padding: 5,
    }
  },
  emojiBubbleSm: {
    fontSize: '2em',
    padding: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em',
      padding: 5,
    }
  },
  emojiBubbleLg: {
    fontSize: '2.75em',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em',
      padding: 5,
    }
  },
  emojiBubbleHousePosition: {
    bottom: '-17%',
    right: '38%',
    [theme.breakpoints.down('sm')]: {
      right: '4%',
      top: '73%',
      bottom: 'inherit',
    },
  },
  emojiBubbleBeachPosition: {
    top: '-22%',
    right: '27%',
    [theme.breakpoints.down('sm')]: {
      top: '10%',
      right: '0%',
    },
  },

  emojiBubbleHatPosition: {
    top: '-10%',
    right: '-14%',
    [theme.breakpoints.down('md')]: {
      right: 0,
    },
    [theme.breakpoints.down('sm')]: {
      left: 10,
      top: 40,
      right: 'inherit'
    }
  },

  buttonIcon: {
    paddingLeft: 12,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  buttonText: {
    fontWeight: 'bold',
    lineHeight: '1.4em',
    textAlign: 'left',
    padding: '0 17px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 5px',
    },
  },

  fontBlack: {
    color: 'black',
  },

  mainContainer: {
    position: 'relative',
  },

  title: {
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: 'inherit',
    }
  },

  mainTitleText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2em',
      textAlign: 'center',
    }
  },

  mainTitleSubtext: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    }
  },

  ctaButtonMain: {
    width: '50%',
    marginLeft: -16,
    [theme.breakpoints.down('sm')]: {
      width: 'inherit',
      marginLeft: 'inherit',
    }
  },

  justifyCenter: {
    justifyContent: 'center',
    display: 'flex',
  },

  scrollDownButton: {
    paddingTop: 10,
    paddingBottom: 30,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      '& img': {
        width: '33%',
      }
    },
    [theme.breakpoints.down('md')]: {
      '& img': {
        width: '25%',
      }
    },
  },

  whatIs: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  whatIsImg: {
    marginLeft: '-300px'
  },
  whatIsText: {
    '& h3': {
      'marginTop': '3em'
    },
    '& h2': {
      letterSpacing: '-0.04em',
    },
    '& p': {
      color: '#303440',
    },
  },

  ctaButtonWhatIs: {
    width: 'inherit',
  },

  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    zIndex: 100,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
    display: 'flex',
    width: '100%'
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 0 0 black',
    textAlign: 'center',
  },
  benefitsIcon: {
    paddingBottom: 15,
    height: 103,
  },
  cardTitle: {
    padding: '22px 0 26px',
  },
  cardContent: {
    flexGrow: 1,
  },
  ctaCards: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: 'inherit',
    }
  },

  showSomeone: {
    backgroundColor: theme.palette.primary.main,
    height: 376,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 300,
    },
  },
  showSomeoneTitle: {
    color: 'white',
    paddingTop: 60,
    paddingBottom: 15,
    letterSpacing: '-0.04em',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
      paddingBottom: 5,
      fontSize: '2em',
    },
  },
  showSomeoneSubtitle: {
    color: 'white',
    paddingBottom: 50,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 10,
    },
  },
  showSomeoneButton: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    border: 'none',
    borderRadius: 4,
    transition: 'backgroundColor 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 41px',
    fontSize: 20,
    fontWeight: 'bold',
    margin: '10px auto',
    '& span': {
      paddingLeft: 17,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      padding: '13px 25px',
      '& span': {
        paddingLeft: 10,
      },
    },
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(6),
      padding: theme.spacing(1),
    },
  },
  footerLogo: {
    maxWidth: 128,
    minWidth: 50,
    width: '90%',
  },
  footerContact: {
    paddingRight: 10
  },
  socialMediaButtons: {
    paddingTop: 36,
    paddingRight: 16,
    cursor: 'pointer',
  },
});
