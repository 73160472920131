export default (theme) => ({
  main: {
    marginLeft: '18.75vw',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      alignItems: 'center',
    },
  },
  dateWrapper: {
    marginBottom: theme.spacing(7.5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  dateText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '5rem',
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '2rem',
    },
  },
});
