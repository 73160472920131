import React from 'react';
import Signup from '../../containers/Signup';

function SignupPage() {
  return (
    <Signup />
  );
}

export default SignupPage;
