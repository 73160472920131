export const dreamDates = [
  {
    value: 3,
    label: '3',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 24,
    label: '24',
  },
];

export const translateDate = (key) => {
  const dates = {
    day: 'dia',
    week: 'semana',
    month: 'mês',
  };
  return dates[key];
};

export const daysDiff = (endDate, startDate) => {
  const diff = endDate - startDate;
  const millisecondsToDay = 1000 * 60 * 60 * 24;
  return diff / millisecondsToDay;
};

export const getWeekdaysDiff = (startDate, endDate) => {
  var count = 0;
  var curDate = startDate;
  while (curDate <= endDate) {
    var dayOfWeek = curDate.getDay();
    if(!((dayOfWeek === 6) || (dayOfWeek === 0)))
      count++;
    curDate.setDate(curDate.getDate() + 1);
  }
  return count;
}
