export default (theme) => ({
  main: {
    marginLeft: '12vw',
    paddingTop: '200px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: 0,
      paddingTop: theme.spacing(15),
    },
    '@media print': {
      paddingTop: '0px'
    }
  },
  agendorIntegrationButton: {
    margin: '0 auto 40px',
    display: 'flex',
    '@media print': {
      display: 'none'
    }
  },
  toast: {
    position: 'fixed',
    right: '30px',
    bottom: '30px'
  }
});
