import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Modal as MuiModal } from '@material-ui/core';

import styles from './styles';

function Modal({
	classes, children, onClose, open, title
}) {
	return (
    <MuiModal
      open={open}
      onClose={onClose}
      className={classes.root}
      aria-labelledby={title}
    >
      <div className={classes.modal}>
        <button className={classes.closeButton} onClick={onClose}>
          &#10005;
        </button>
        <h4 className={classes.title}>
          {title}
        </h4>
      	{children}
      </div>
    </MuiModal>
	);
}

export default withStyles(styles)(Modal);