export default (theme) => ({
  separatorLine: {
    border: `2px solid ${theme.palette.screenGrey.light}`,
    height: 'calc(38vh - 168px)',
    width: 0,
    margin: '0 auto',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(5),
    },
    '@media print': {
      height: '5px'
    }
  },
  button: {
    backgroundColor: '#B8A4F3',
    width: theme.spacing(9),
    height: theme.spacing(9),
    borderRadius: theme.spacing(3.5),
    border: 0,
    cursor: 'pointer',
    position: 'absolute',
    left: -35,
    top: 'calc(50% - 36px)',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:focus': {
      outline: 'none',
    },
    '@media print': {
      display: 'none'
    }
  },
  buttonArrowIcon: {
    stroke: '#fff',
    transform: 'rotate(-90deg)',
    transition: 'transform 0.2s',
  },
  buttonArrowIconUp: {
    transform: 'rotate(90deg)',
  },
});
