import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import styles from './styles.js';
import newAccountIcon from '../../assets/vectors/landingPage/new-account-icon.svg';
import whatsappIcon from '../../assets/vectors/landingPage/whatsapp.svg';
import scrollDown from '../../assets/vectors/landingPage/scroll-down.svg';
import lpImg from '../../assets/vectors/landingPage/quanto-falta-sonho.jpg';
import graphUp from '../../assets/vectors/landingPage/graph-up.svg';
import agendorIntegration from '../../assets/vectors/landingPage/agendor-integration.svg';
import person from '../../assets/vectors/landingPage/person.svg';
import dreamFunnelIcon from '../../assets/vectors/landingPage/dream-funnel-icon.svg';
import email from '../../assets/vectors/landingPage/footer-email.svg';
import facebook from '../../assets/vectors/landingPage/facebook.svg';
import instagram from '../../assets/vectors/landingPage/instagram.svg';
import agendor from '../../assets/vectors/landingPage/agendor-icon.svg';

import { getLink } from '../../utils/links';
import Analytics from '../../utils/analytics';

function LandingPage({ classes }) {
  const whatIsRef = React.useRef();

  useEffect(() => {
    Analytics.log("homepage_visited");
  }, []);

  const cards = [
    {
      icon: graphUp,
      title: 'Motive seu time',
      text: 'Ajude seus vendedores a sonharem mais alto e desenvolva profissionais de alta performance',
    },
    {
      icon: person,
      title: 'Engaje no CRM',
      text: 'O Funil dos Sonhos conecta os objetivos pessoais de vendedores com as atividades a serem realizadas no dia-a-dia de vendas para alcançá-los.',
    },
    {
      icon: agendorIntegration,
      title: 'Integre com Agendor',
      text: 'Acompanhe a evolução e veja quanto falta para realizar os sonhos do seu time automaticamente',
    },
  ];

  return (
    <React.Fragment>
      <Hidden smDown>
        <span style={{left: '50vw', top: 0}} className={clsx(classes.pastel)}></span>
        <span className={clsx(classes.lines, classes.linesPosition)}></span>
      </Hidden>
      <span className={clsx(classes.dots, classes.dotsPosition)}></span>
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="md" classes={{root: classes.mainContainer}}>
            <Hidden smDown>
              <span style={{ right: '-7%', top: '17%' }} className={clsx(classes.jujuba)}></span>
              <span className={clsx(classes.dreamer, classes.dreamerPosition)}></span>
            </Hidden>
            <span className={clsx(classes.emojiBubble, classes.emojiBubbleHatPosition)} role="img" aria-label="Formação">🎓</span>
            <span style={{ top: '-15%', right: '20%' }} className={clsx(classes.emojiBubble, classes.emojiBubbleSm)} role="img" aria-label="Carro">🚘</span>
            <span className={clsx(classes.ball, classes.topBallPosition)}></span>
            <span className={clsx(classes.ball, classes.bottomBallPosition)}></span>
            <span className={clsx(classes.triangleBlue, classes.triangleBluePosition)}></span>
            <span className={clsx(classes.triangleYellow, classes.triangleYellowPosition)}></span>
            <span className={clsx(classes.emojiBubble, classes.emojiBubbleBeachPosition)} role="img" aria-label="Viagem">🏖</span>
            <span className={clsx(classes.emojiBubble, classes.emojiBubbleLg, classes.emojiBubbleHousePosition)} role="img" aria-label="Casa">🏡</span>
            <div className={classes.title}>
              <Typography component="h2" variant="h2" align="left" color="textPrimary" gutterBottom className={classes.mainTitleText}>
                <Box fontWeight={500}>Vendedores motivados conquistam mais</Box>
              </Typography>
              <Typography variant="h6" align="left" color="textSecondary" paragraph className={classes.mainTitleSubtext}>
                Motive seu time, engaje no CRM<br/>e ajude seus vendedores a realizarem sonhos
              </Typography>
            </div>
            <div className={classes.ctaButtonMain}>
              <CTAButtons classes={classes} />
            </div>
          </Container>
        </div>
        <Container>
          <div className={clsx(classes.justifyCenter, classes.scrollDownButton)}>
            <img src={scrollDown} onClick={() => whatIsRef.current.scrollIntoView({block: "start", behavior: "smooth"})} alt="" />
          </div>
        </Container>
        <Container>
          <div className={classes.whatIs} ref={whatIsRef}>
            <Hidden smDown>
              <div className="">
                <img src={lpImg} className={classes.whatIsImg} alt="" />
              </div>
            </Hidden>
            <Container className={classes.whatIsText} maxWidth="sm">
              <Typography component="h3" variant="h3" align="left" color="textPrimary" gutterBottom>
                <Box fontWeight={500}>O que é o Funil dos Sonhos?</Box>
              </Typography>
              <Typography variant="h6" align="left" color="textSecondary" paragraph>
                <Box fontWeight={400}>Um dos maiores motivos para vendedores não engajarem no CRM é a falta de conexão entre as funcionalidades do CRM e os objetivos pessoais do vendedor.</Box>
                <br />
                <Box fontWeight={400}>Nós entendemos isso, afinal, todos nós precisamos de um propósito que norteia tudo o que fazemos.</Box>
                <br />
                <Box fontWeight={400}>O Funil dos Sonhos surgiu para resolver isso: <b>conectar os objetivos dos vendedores com os objetivos da empresa, mostrando as atividades de vendas diárias que precisam ser realizadas para alcançá-los.</b></Box>
              </Typography>
              <div className={clsx(classes.justifyCenter, classes.ctaButtonWhatIs)}>
                <CTAButtons classes={classes} />
              </div>
            </Container>
          </div>
        </Container>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map((card, i) => (
              <Grid item key={i} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <div className={clsx(classes.justifyCenter, classes.benefitsIcon)}>
                      <img src={card.icon} alt="" />
                    </div>
                    <Typography gutterBottom variant="h5" component="h5" className={classes.cardTitle}>
                      {card.title}
                    </Typography>
                    <Typography>
                      {card.text}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <div className={classes.justifyCenter}>
            <div className={clsx(classes.ctaCards)}>
              <CTAButtons classes={classes} />
            </div>
          </div>
        </Container>
        <div className={classes.showSomeone}>
          <Hidden xsDown>
            <span className={clsx(classes.pastel, classes.showSomeonePastelPosition)}></span>
          </Hidden>
          <span className={clsx(classes.triangleBlue, classes.showSomeoneTriangleBluePosition)}></span>
          <span className={clsx(classes.ball, classes.showSomeoneBallPosition)}></span>
          <span className={clsx(classes.darkJujuba, classes.showSomeoneJujubaPosition)}></span>
          <div style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
          }}>
            <Typography gutterBottom variant="h2" align="center" component="h2" className={classes.showSomeoneTitle}>
              Mostre o funil para alguém.
            </Typography>
            <Typography variant="h6" align="center" className={classes.showSomeoneSubtitle}>
              Compartilhe com alguém do seu time ou colega.
            </Typography>
            <button className={classes.showSomeoneButton} onClick={openWhatsapp}>
              <img className={classes.buttonIcon} src={whatsappIcon} alt="Whatsapp" />
              <span>Compartilhar por WhatsApp</span>
            </button>
          </div>
         </div>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Container>
          <Grid container spacing={2}>
            <Grid item md={2} sm={2} xs={2} justify="center">
              <img src={dreamFunnelIcon} className={classes.footerLogo} alt="Logotipo do Funil dos Sonhos" />
            </Grid>
            <Grid item md={4} sm={6} xs={9}>
              <Typography gutterBottom>
                <b>O Funil dos Sonhos</b>
              </Typography>
              <Typography>
                É uma ferramenta simples que mostra o que fazer no dia-a-dia de vendas para alcançar objetivos pessoais e conectá-los com as metas da empresa.
              </Typography>
            </Grid>
            <Grid item md={2} sm={1}>
            </Grid>
            <Grid item md={4} sm={6}>
              <Typography gutterBottom>
                <b>Atendimento</b>
              </Typography>
              <Typography>
                <img src={email} className={classes.footerContact} alt="Email" /> contato@agendor.com.br
              </Typography>
              <div style={{display: 'none'}}>
                <img src={instagram} className={classes.socialMediaButtons} alt="Instagram" />
                <img src={facebook} className={classes.socialMediaButtons} alt="Facebook" />
              </div>
            </Grid>
          </Grid>
          <hr style={{border: '1px solid #DCD8E6', marginTop: 70}} />
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 0' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href={getLink({link: 'AGENDOR_SITE'})} rel="noopener noreferrer" target="_blank" style={{ display: 'flex', alignItems: 'center' }}>
                <img src={agendor} style={{paddingRight: 16}} alt="Logotipo do Agendor" />
              </a>
              O Funil dos Sonhos é uma ferramenta&nbsp;<a href={getLink({link: 'AGENDOR_SITE'})} rel="noopener noreferrer" target="_blank">Agendor</a>
            </div>
            <div style={{color: '#A49AB8', fontSize: 14}}>
              © Todos os direitos reservados
            </div>
          </div>
        </Container>
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}

const openWhatsapp = () => {
  Analytics.log("whatsapp_share_clicked");
  window.open('https://web.whatsapp.com/send?text=Pessoal%2C%20olhem%20essa%20ferramenta%20que%20legal%3A%20http%3A%2F%2Fbit.ly%2Ffunil-sonhos-wpp.%20Ela%20ajuda%20a%20mapear%20o%20objetivo%20pessoal%20de%20voc%C3%AAs%20e%20mostra%20o%20que%20fazer%20no%20dia-a-dia%20de%20vendas%20para%20alcan%C3%A7%C3%A1-lo%21%20Voc%C3%AAs%20podem%20criar%20uma%20conta%20gr%C3%A1tis%2C%20d%C3%AAem%20uma%20olhada.', 'Whatsapp', 'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes');
};

const CTAButtons = ({ classes }) => {
  const history = useHistory();

  return (<div className={classes.heroButtons}>
    <Grid container spacing={2} justify="center">
      <Grid item md={6} justify="center">
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            startIcon={<img className={classes.buttonIcon} src={newAccountIcon} alt="" />}
            onClick={() => {
              Analytics.log("sign_up_clicked");
              history.push("/cadastrar");
            }}
          >
            <span className={classes.buttonText}>Criar uma <br/>conta grátis</span>
          </Button>
        </Box>
      </Grid>
      <Grid item md={6} justify="center">
        <Box display="flex" justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            startIcon={<img className={classes.buttonIcon} src={whatsappIcon} alt="Whatsapp" />}
            onClick={openWhatsapp}
          >
            <span className={clsx(classes.buttonText, classes.fontBlack)}>Compartilhar <br/>com meu time</span>
          </Button>
        </Box>
      </Grid>
    </Grid>
  </div>);
};

export default withStyles(styles)(LandingPage);
