/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

const Mautic = {
	exec: function(eventName, properties = {}, value = 0){
		if (eventName === 'sign_up') Mautic.signUp(properties);
		else if (eventName === 'profile_updated') Mautic.profileUpdated(properties);
	},
	signUp: function (properties) {
		properties = {
			email: properties.email,
			firstname: properties.name
		};
		Mautic.send("funil-sonhos-sign-up", properties);
	},
	profileUpdated: function (properties) {
		let data = {
			email: properties.email,
	    persona: properties.jobTitle,
	    ramo_atividade: properties.businessField,
	    qtd_vendedores: properties.teamSize,
	    phone: properties.phone,
	    companywebsite: properties.website,
	    como_conheceu: properties.leadOrigin
		};

		if (properties.newsletter) {
			data.newsletter = "Sim";
		}

		Mautic.send("funil-sonhos-profile-updated", data);
	},
	send: function(identifier, payload = {}) {
		let data = {
			page_title: identifier,
			page_url: identifier,
			...payload
		};

		mt("send", "pageview", {
			...data
		});
	}
};

export default Mautic;