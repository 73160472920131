import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

function Select({
  classes,
  value,
  onChange,
  options,
  className,
}) {
  return (
    <div className={classes.selectWrapper}>
      <select
        className={clsx(classes.select, className)}
        onChange={onChange}
        value={value}
      >
        {
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))
        }
      </select>
    </div>
  );
}

export default withStyles(styles)(Select);
