import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import Icon from '../Icon';

import styles from './styles';

import Analytics from '../../utils/analytics';

function PrintButton ({ classes }) {

	const handlePrint = () => {
		Analytics.log("print_clicked");
		window.print();
	};

	return (
	  <button className={classes.printButton} onClick={handlePrint}>
	    <Icon name="print" className={classes.printIcon} /> Imprimir
	  </button>
	);
};

export default withStyles(styles)(PrintButton);