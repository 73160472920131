import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import "firebase/analytics";

const firebaseConfig = {
  apiKey: 'AIzaSyCKHJaEfbxmwYrpUxZk9ADg6NRyLAVrVS4',
  authDomain: 'funil-sonhos-vendas.firebaseapp.com',
  projectId: 'funil-sonhos-vendas',
  messagingSenderId: '174090581940',
  appId: '1:174090581940:web:1452f65cce7e79184f64e1',
  measurementId: 'G-T9BJLSFE22',
};

firebase.initializeApp(firebaseConfig);
const firebaseAnalytics = firebase.analytics();

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const Firebase = {
	exec: function(eventName, properties = {}, value = 0){
		if (eventName === 'sign_up') firebaseAnalytics.logEvent(eventName);
		else if (eventName === 'profile_updated') Firebase.profileUpdated(eventName, properties);
		else firebaseAnalytics.logEvent(eventName, properties);
	},
	profileUpdated: function (eventName, properties) {
		let props = {
			...properties
		};
		delete props.email;
		delete props.phone;
		firebaseAnalytics.logEvent(eventName, props);
	},
	setUserProperties: function(properties = {}) {
		firebaseAnalytics.setUserProperties(properties);
	}
};