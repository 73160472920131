export default (theme) => ({
  main: {
    padding: theme.spacing(8, 12),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  navbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backButtonRoot: {
    color: theme.palette.text.main,
    height: theme.spacing(5),
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.spacing(1),
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  backButtonLabel: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
  },
  backButtonIcon: {
    stroke: theme.palette.primary.main,
  },
  closeButton: {
    color: theme.palette.primary.main,
    fontSize: '1.5rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2rem',
    letterSpacing: '-0.04em',
    color: '#303440',
    margin: theme.spacing(3, 1.75),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      fontSize: '1.5rem',
    },
  },
  list: {
    margin: theme.spacing(0, 0, 0, 0.5),
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      flexWrap: 'nowrap',
      overflow: 'auto',
      alignItems: 'stretch',
    },
  },
  updateButton: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(21.5),
    textAlign: 'end',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      width: 'calc(100% - 32px)',
      bottom: theme.spacing(2),
      margin: 0,
    },
  },
});
