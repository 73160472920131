import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '../Tooltip';

import { translateDate } from '../../utils/date';

import Icon from '../Icon';

import styles from './styles';

function CurrentAvg({
	classes,
	done,
	current,
	period,
	inline,
	myAvgText = 'Minha média:'
}) {

	let iconName = "checkMark";
	let tooltipText = "Você está dentro da meta, parabéns!";
	let avgText = current + "/" + translateDate(period);

	if (inline) avgText = current + " vs " + done + "/" + translateDate(period);

	if (done > current) {
		iconName = "arrowDown";
		tooltipText = "Sua média histórica é mais baixa do que a meta, fique atento";
	} else if (done < current) {
		iconName = "arrowUp";
		tooltipText = "Sua média histórica é mais alta do que a meta, parabéns!";
	}

	return (
		<div className={classes.root}>
		  {myAvgText}
			<Tooltip arrow={true} placement="top" title={tooltipText}>
			  <div className={classes.status}>
			    <Icon className={classes.icon} name={iconName} />
			    <span className={classes.text}>{avgText}</span>
			  </div>
			</Tooltip>
		</div>
	);
};

export default withStyles(styles)(CurrentAvg);