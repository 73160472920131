import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import QuestionStructure from '../../../components/QuestionStructure';
import Input from '../../../components/Input';

import styles from './styles';

function AvgTicketQuestion({
  classes,
  avgTicket,
  onBackClick,
  onForwardClick,
  onChange,
  questionDate,
  isIntegrationActive,
}) {
  const formatter = new Intl.NumberFormat('pt-BR');
  const formatedPrice = formatter.format(avgTicket);
  const value = formatedPrice === '0' ? '' : formatedPrice;

  return (
    <QuestionStructure
      titleNumber={1}
      title="Agora, informe seu ticket-médio"
      subtitle={`Para calcularmos quantas vendas vocês você precisará realizar até ${questionDate}, primeiro precisamos saber seu ticket-médio. Para isso, lembre de suas últimas 3 vendas, some o valor delas e divida por 3. Pronto, este é seu ticket-médio`}
      onBackClick={onBackClick}
      onForwardClick={onForwardClick}
      isForwardDisabled={!avgTicket}
    >
      <Input
        label="R$"
        type="text"
        value={value}
        onChange={onChange}
        placeholder="1.000"
        className={isIntegrationActive ? classes.integratedInput : {}}
      />
    </QuestionStructure>
  );
}

export default withStyles(styles)(AvgTicketQuestion);
