import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';

function Loading({ classes }) {
  return (
    <div className={classes.loadingWrapper}>
      <CircularProgress />
    </div>
  );
}

export default withStyles(styles)(Loading);
