export default (theme) => ({
  root: {
    color: ({ inline }) => inline ? '#000' : '#A49AB8',
    fontSize: '14px',
    fontWeight: ({ inline }) => inline ? '500' : 'bold',
    marginTop: theme.spacing(1),
    display: ({ inline }) => inline ? 'inline-flex' : 'block',
    alignItems: ({ inline }) => inline ? 'center' : null
  },
  text: {
    color: ({ inline }) => inline ? theme.palette.primary.main : theme.palette.grey.dark,
    fontWeight: ({ inline }) => inline ? '700' : null
  },
  status: {
    display: ({ inline }) => inline ? 'inline-flex' : 'flex',
    marginTop: ({ inline }) => inline ? '0px' : '5px',
    marginLeft: ({ inline }) => inline ? theme.spacing(1) : null
  },
  icon: {
    marginRight: '5px'
  },
});