import React, { useState, useContext, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { format, subDays } from 'date-fns';
import ptLocale from 'date-fns/locale/pt';

import Hidden from '@material-ui/core/Hidden';
import SectionIntro from '../../components/SectionIntro';
import SideBar from '../../components/SideBar';
import SurveyHeader from '../../components/SurveyHeader';
import AgendorIntegrationButton from '../../components/AgendorIntegrationButton';

import { FunnelContext } from '../../contexts/FunnelContext';
import Api from '../../services/api';

import AvgTicketQuestion from './AvgTicketQuestion';
import styles from './styles';

import { calculateNumberOfSales } from '../../utils';
import Analytics from '../../utils/analytics';

function AvgTicket({ classes }) {
  const { funnelState, funnelUpdate } = useContext(FunnelContext);

  const history = useHistory();
  const historyState = history.location && history.location.state;
  const step = historyState && historyState.step;

  const [showIntro, setShowIntro] = useState(!step);
  const [isIntegrationActive, setIsIntegrationActive] = useState(false);
  const [avgTicket, setAvgTicket] = useState(funnelState.avgTicket);
  const [numberOfSales, setNumberOfSales] = useState(funnelState.numberOfSales);

  useEffect(() => {
    let stepName = null;

    if (showIntro) stepName = 'Avg. Ticket Intro';
    else stepName = 'Avg. Ticket';

    Analytics.log("onboarding_step_viewed", {
      step_name: stepName
    });
  }, [showIntro]);

  const handleAvgTicketChange = (e) => {
    const { value } = e.target;
    const newAvgTicket = value.replace(/\./g, '');
    if (newAvgTicket.match(/^[0-9]*$/)) {
      setAvgTicket(Number(newAvgTicket));
      const salesQuantity = calculateNumberOfSales(funnelState.salesGoal, newAvgTicket);
      setNumberOfSales(salesQuantity);
      setIsIntegrationActive(false);
    }
  };

  const goToNextSection = () => {
    const newFunnelState = funnelUpdate({ type: 'UPDATE', avgTicket, numberOfSales });
    Api
      .patch(
        `/funnels/${funnelState.id}`,
        newFunnelState
      )
      .catch(() => alert('Houve um erro tente novamente mais tarde'));
    history.push('/atividades');
  };

  const handleIntegrationActivation = () => {
    return Api
      .get(
        `/agendor/deals`,
        {
          params: {
            startDate: `${format(subDays(new Date(), 90), 'yyyy/MM/dd')} 00:00:00`,
            endDate: `${format(new Date(), 'yyyy/MM/dd')} 23:59:59`,
          }
        },
      )
      .then((response) => {
        if (response.data.avg_amount) {
          const newAvgTicket = parseInt(response.data.avg_amount / 100);
          setAvgTicket(newAvgTicket);
          const salesQuantity = calculateNumberOfSales(funnelState.salesGoal, newAvgTicket);
          setNumberOfSales(salesQuantity);
        }
        setIsIntegrationActive(true);
      })
      .catch(() => alert('Houve um erro tente novamente mais tarde'));
  };

  const formatedDate = format(
    new Date(funnelState.dreamDate || null),
    'd, LLL. yyyy',
    { locale: ptLocale },
  );

  if (showIntro) {
    return (
      <SectionIntro
        header="Quantidade de vendas"
        title="Quantas vendas precisará fechar"
        subtitle="Agora vamos quantificar seu volume de vendas. Para isso, primeiro precisamos descobrir seu tícket-médio. Veja no vídeo abaixo como funciona essa matemática."
        videoSrc="https://www.youtube.com/embed/qtXiEB02N-Y?autoplay=1&rel=0&showinfo=0"
        onClick={() => setShowIntro(false)}
        onBackClick={() => history.push('/meta-de-vendas', { step: true })}
      />
    );
  }

  return (
    <>
      <SideBar />
      <main className={classes.main}>
        <Hidden smDown>
          <SurveyHeader
            title="Início"
            subTitle="Monte seu funil"
          />
        </Hidden>
        <AgendorIntegrationButton
          isActive={isIntegrationActive}
          onActiveClick={() => setIsIntegrationActive(false)}
          onInactiveClick={handleIntegrationActivation}
          location='Avg. Ticket'
        />
        <AvgTicketQuestion
          avgTicket={avgTicket}
          onBackClick={() => setShowIntro(true)}
          onForwardClick={goToNextSection}
          onChange={handleAvgTicketChange}
          questionDate={formatedDate}
          isIntegrationActive={isIntegrationActive}
        />
      </main>
    </>
  );
}

export default withStyles(styles)(AvgTicket);
