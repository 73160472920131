import { useState, useContext, useEffect } from 'react';
import { FunnelContext } from '../../contexts/FunnelContext';

export const useAvgSalesState = () => {
  const { funnelState } = useContext(FunnelContext);

  const [avgSales, setAvgSales] = useState(funnelState.avgSales);

  useEffect(() => {
    setAvgSales(funnelState.avgSales);
  }, [funnelState]);

  return [avgSales, setAvgSales];
};

export const useActivitiesState = () => {
  const { funnelState } = useContext(FunnelContext);

  const [activities, setActivities] = useState(funnelState.activitiesFrequency);

  useEffect(() => {
    setActivities(funnelState.activitiesFrequency);
  }, [funnelState]);

  return [activities, setActivities];
};
