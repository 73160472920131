import React, { createContext, useReducer } from 'react';

import { calculateActivityFrequency, calculateSalesGoal, calculateNumberOfSales, getAvgActivitiesPerMonth } from '../utils';

const initialState = {
  dreamType: '',
  dreamText: '',
  dreamPrice: 0,
  dreamDate: '',
  dreamInitDate: '',
  salesGoal: 0,
  commission: 0,
  avgTicket: 0,
  numberOfSales: 0,
  avgSales: 0,
  activitiesFrequency: [
    {
      activity: 'email',
      currentFrequency: '',
      period: 'day',
      frequency: '',
    },
    {
      activity: 'call',
      currentFrequency: '',
      period: 'day',
      frequency: '',
    },
    {
      activity: 'visit',
      currentFrequency: '',
      period: 'day',
      frequency: '',
    },
    {
      activity: 'meeting',
      currentFrequency: '',
      period: 'day',
      frequency: '',
    },
    {
      activity: 'proposal',
      currentFrequency: '',
      period: 'day',
      frequency: '',
    },
  ],
  competences: '',
  followUpDay: '',
  followUpTime: '',
  soldValue: 0,
  soldQuantity: 0,
  goalDistance: '',
  tasksLookbackDays: 30,
  updatedAt: '',
  isCompleted: false,
  viewedFunnelResult: false
};

const FunnelContext = createContext(initialState);

const update = (prevState, newData) => {
  const newState = {
    ...prevState,
    ...newData
  };

  newState.salesGoal = calculateSalesGoal(newState.dreamPrice, newState.commission);
  newState.numberOfSales = calculateNumberOfSales(newState.salesGoal, newState.avgTicket);

  newState.activitiesFrequency.forEach((activity) => {
    let avgActivitiesPerMonth = getAvgActivitiesPerMonth(activity.period, activity.currentFrequency);
    activity.frequency = calculateActivityFrequency({
      avgActivitiesPerMonth: avgActivitiesPerMonth,
      avgSalesPerMonth: newState.avgSales,
      numberOfSales: newState.numberOfSales,
      soldQuantity: newState.soldQuantity,
      endDate: newState.dreamDate,
      period: activity.period
    });
  });

  return newState;
}

const clearState = (state) => {
  const newState = {
    ...state
  };

  delete newState.id;
  delete newState.updatedAt;
  delete newState.createdAt;
  delete newState.type;
  return newState;
}

const FunnelProvider = ({ children }) => {
  const [funnelState, funnelDispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'UPDATE':
        return {
          ...prevState,
          ...action
        };
      case 'RESET':
        return initialState;
      default:
        return prevState;
    }
  }, initialState);

  const funnelUpdate = (action) => {
    const newState = update(funnelState, action);
    funnelDispatch(newState);
    const cleanState = clearState(newState);
    return cleanState;
  };

  return (
    <FunnelContext.Provider value={{ funnelState, funnelUpdate, funnelDispatch, clearState }}>
      {children}
    </FunnelContext.Provider>
  );
};

export { FunnelContext, FunnelProvider };
