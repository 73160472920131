import React, { useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import Icon from '../../../components/Icon';

import styles from './styles';

const DREAM_LIST = [
  { icon: '🛩', text: 'Viagem' },
  { icon: '🚘', text: 'Veículo' },
  { icon: '🏡', text: 'Imóvel' },
  { icon: '📚', text: 'Formação' },
  { icon: '❤️', text: 'Experiência' },
  { icon: '🌟', text: 'Outros' },
];

function DreamSelector({ classes, onClick, selectedValue }) {
  const initalState = getInitalDream(selectedValue);
  const [dreams, setDreams] = useState(initalState);

  const handleItemClick = (selectedText) => {
    const newDreams = dreams.map((dream) => {
      if (dream.text === selectedText) {
        return { ...dream, selected: true };
      }
      return { icon: dream.icon, text: dream.text };
    });
    setDreams(newDreams);
    onClick(selectedText);
  };

  return (
    <ul className={classes.wrapper}>
      {
        dreams.map(({ text, icon, selected }, i) => {
          const itemClasses = clsx(
            classes.item,
            { [classes.itemSelected]: selected },
          );
          return (
            <li
              key={text + i}
              className={itemClasses}
              onClick={() => handleItemClick(text)}
            >
              <div className={clsx({ [classes.check]: selected })}>
                {
                  selected && (
                    <Icon name="check" className={classes.checkIcon} />
                  )
                }
              </div>
              <span className={classes.textIcon}>
                { icon }
              </span>
              { text }
            </li>
          );
        })
      }
    </ul>
  );
}

const getInitalDream = (selectedValue) => DREAM_LIST.map((dream) => {
  if (dream.text === selectedValue) {
    return { ...dream, selected: true };
  }
  return dream;
});

export default withStyles(styles)(DreamSelector);
