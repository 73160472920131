export default (theme) => ({
  main: {
    marginLeft: '18.75vw',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      alignItems: 'center',
    },
  },
  cardsList: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    margin: 0,
    padding: 0,
    overflow: 'auto',
    height: `calc(100% - ${theme.spacing(2)}px)`,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
      overflow: 'auto',
      width: '90vw',
    },
  },
});
