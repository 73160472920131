import React from 'react';

import TooltipMui from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

function Tooltip({
	classes,
	children,
	arrow = true,
	placement = "top",
	title
}) {
	return (
		<TooltipMui arrow={arrow} placement={placement} title={title} classes={classes}>
			{children}
		</TooltipMui>
	);
};

export default withStyles(styles)(Tooltip);