import { createMuiTheme } from '@material-ui/core/styles';

import buttonTheme from './button';
import defaultTheme from './defaults';

const theme = createMuiTheme(defaultTheme);

export default createMuiTheme({
  overrides: {
    ...buttonTheme(theme),
  },
  ...defaultTheme,
});
