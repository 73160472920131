export default (theme) => ({
  wrapper: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  label: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    fontWeight: 'bold',
    letterSpacing: '-0.04em',
    margin: theme.spacing(2, 2, 0, 0),
  },
  input: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '5rem',
    border: 'none',
    fontFamily: 'inherit',
    width: '100%',
    caretColor: theme.palette.primary.main,
    '&::placeholder': {
      color: '#AB96E6',
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
  },
});
