export default (theme) => ({
  root: {
    width: '100%',
    'label + &': {
      fontFamily: 'inherit',
      marginTop: theme.spacing(1.5),
      width: '100%',
    },
  },
  input: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '1rem',
    border: 'none',
    fontFamily: 'inherit',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.screenGrey.main,
    textAlign: 'inherit',
    '&::placeholder': {
      color: '#AB96E6',
    },
    '&:focus': {
      outline: 'none',
    },
    '&::-webkit-outer-spin-button': {
      appearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0,
    },
  },
  label: {
    alignSelf: 'flex-start',
    fontWeight: 700,
    color: theme.palette.text.main,
    fontSize: '1rem',
    fontFamily: 'inherit',
  },
});
