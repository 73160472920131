export default (theme) => ({
  square: {
    width: theme.spacing(6),
    minWidth: theme.spacing(6),
    height: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.success.main,
  },
  squareLarge: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    borderRadius: theme.spacing(6),
  },
  checkIcon: {
    stroke: theme.palette.primary.main,
  },
  checkIconLarge: {
    width: theme.spacing(4),
    height: theme.spacing(3.5),
  },
});
