import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '../Button';
import ContainedInput from '../ContainedInput';
import Title from '../Title';

import styles from './styles.js';

function Auth({
  classes,
  title,
  subtitle,
  passCaption,
  buttonText,
  onClick,
  isSignup,
  isLoading,
  email
}) {
  const [form, setForm] = useState({
    name: '',
    email: email,
    password: ''
  });

  const handleChange = (key, e) => {
    setForm({ ...form, [key]: e.target.value });
  };

  const handleClick = (e) => {
    e.stopPropagation();
    onClick(form);
  };

  const emailLabel = isSignup ? 'E-mail corporativo' : 'Seu e-mail corporativo';

  const isDisabled = !form.email || !form.password || (isSignup && !form.name);
  return (
    <>
      <main className={classes.main}>
        <Title
          title={title}
          subtitle={subtitle}
        />
        <form className={classes.form}>
          {
            isSignup && (
              <div className={classes.inputField}>
                <ContainedInput
                  value={form.name}
                  onChange={(e) => handleChange('name', e)}
                  label="Seu nome"
                  autoFocus={true}
                />
              </div>
            )
          }
          <div className={classes.inputField}>
            <ContainedInput
              value={form.email}
              onChange={(e) => handleChange('email', e)}
              label={emailLabel}
              autoFocus={!isSignup}
            />
          </div>
          <div className={classes.inputField}>
            <ContainedInput
              value={form.password}
              onChange={(e) => handleChange('password', e)}
              label="Sua senha"
              type="password"
            />
            <small className={classes.helperText}>
              {passCaption}
            </small>
          </div>
          <div className={classes.confirmButton}>
            <Button
              fullWidth
              onClick={handleClick}
              disabled={isDisabled || isLoading}
            >
              {
                isLoading
                  ? <CircularProgress />
                  : buttonText
              }
            </Button>
          </div>
        </form>
      </main>
    </>
  );
}

export default withStyles(styles)(Auth);
