export default (theme) => ({
  link: {
    fontWeight: 700,
    color: '#5A57F7',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  subtitle: {
    fontSize: '1.125rem',
    fontWeigth: 'normal',
    margin: theme.spacing(0, 0, 5),
    color: '#A49AB8',
  },
  inputWrapper: {
    display: 'inline-block',
    width: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(16),
    },
  },
  soldText: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    lineHeight: '1.625rem',
    display: 'inline-block',
    color: theme.palette.primary.main,
  },
  totalSales: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    lineHeight: '1.625rem',
    color: '#2F291F',
  },
  trophy: {
    display: 'flex',
    alignItems: 'center',
  },
  progressHeader: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    color: '#A49AB8',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  saledLegend: {
    marginTop: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    color: '#A49AB8',
    paddingLeft: theme.spacing(2),
    display: 'inline-block',
    marginRight: theme.spacing(5),
    position: 'relative',
    '&:before': {
      content: '""',
      top: 7,
      left: 0,
      position: 'absolute',
      width: 6,
      height: 6,
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
  },
  goalLegend: {
    marginTop: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    color: '#A49AB8',
    paddingLeft: theme.spacing(2),
    display: 'inline-block',
    position: 'relative',
    '&:before': {
      content: '""',
      top: 7,
      left: 0,
      position: 'absolute',
      width: 6,
      height: 6,
      borderRadius: theme.spacing(1),
      backgroundColor: '#2F291F',
    },
  },
  updateButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    padding: theme.spacing(3, 4),
    fontSize: '1rem',
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(5),
    fontWeight: 'bold',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  proceedButton: {
    marginTop: theme.spacing(3),
    color: '#A49AB8',
    fontWeight: 'bold',
    fontSize: '1rem',
    border: 'none',
    backgroundColor: '#fff',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
});
