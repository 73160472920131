export default (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '90vw',
    margin: '0 auto',
  },
  iconWrapper: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
    },
  },
});
