import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import QuestionStructure from '../../../components/QuestionStructure';
import CalendarPicker from '../CalendarPicker';

import styles from './styles.js';

function DreamInitDate({
  classes,
  initDate,
  onDateChange,
  onBackClick,
  onForwardClick,
}) {

  const handlePickerChange = (initDate) => {
    onDateChange('initDate', initDate);
  };

  const isDisabled = Boolean(!initDate);

  return (
    <QuestionStructure
      titleNumber={4}
      title="Qual é/foi a data de início da sua busca pelo Sonho?"
      subtitle="Essa data será usada para calcular o valor vendido até o momento e quantas atividades deverá realizar por dia/semana/mês."
      onBackClick={onBackClick}
      onForwardClick={onForwardClick}
      isForwardDisabled={isDisabled}
    >
      <CalendarPicker
        value={initDate}
        onChange={handlePickerChange}
        maxDate={new Date()}
      />
    </QuestionStructure>
  );
}

export default withStyles(styles)(DreamInitDate);
