import background from '../../assets/vectors/background.svg';

export default (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.text.dark,
    padding: theme.spacing(5),
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: `calc(100vh - ${theme.spacing(20)}px)`,
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      background: 'none',
      margin: '0 auto',
      padding: 0,
      height: 'auto',
    },
  },
  form: {
    width: theme.spacing(50),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  inputField: {
    width: '100%',
    margin: theme.spacing(0, 0, 2),
  },
  confirmButton: {
    margin: theme.spacing(5, 0, 10),
  },
  helperText: {
    color: theme.palette.grey.main,
    margin: theme.spacing(0.5, 0),
    display: 'block',
  },
});
