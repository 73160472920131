/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import QuestionStructure from '../../../components/QuestionStructure';
import Input from '../../../components/Input';
import AgendorIntegrationButton from '../../../components/AgendorIntegrationButton';

import styles from './styles';

function AvgSales({
  classes,
  avgSales,
  onBackClick,
  onForwardClick,
  onChange,
  hasAgendorToken,
  onActiveClick,
  onInactiveClick,
  isIntegrationActive,
}) {
  const [inputWidth, setInputWidth] = useState(2);
  const formatter = new Intl.NumberFormat('pt-BR');
  const formatedPrice = formatter.format(avgSales);
  const value = formatedPrice === '0' ? '' : formatedPrice;

  const isLargeMedia = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const mediaFontMultiplier = isLargeMedia ? 3.2 : 2;

  useEffect(() => {
    if (avgSales) {
      return setInputWidth(formatter.format(avgSales).length);
    }
    setInputWidth(2.2);
  }, [avgSales]);

  return (
    <>
      <AgendorIntegrationButton
        isActive={isIntegrationActive}
        onActiveClick={onActiveClick}
        onInactiveClick={onInactiveClick}
        location='Activity Avg. Sales'
      />
      <QuestionStructure
        titleNumber={1}
        title="Quantos negócios você costuma fechar por mês?"
        onBackClick={onBackClick}
        onForwardClick={onForwardClick}
        isForwardDisabled={!avgSales}
      >
        <div className={classes.wrapper}>
          <Input
            style={{ width: `${inputWidth * mediaFontMultiplier}rem` }}
            value={value}
            onChange={onChange}
            placeholder="00"
            className={isIntegrationActive ? classes.integratedInput : {}}
          />
          <p className={classes.monthText}>/ mês</p>
        </div>
      </QuestionStructure>
    </>
  );
}

export default withStyles(styles)(AvgSales);
