import React, { forwardRef, useContext, useState } from 'react';
import ptLocale from 'date-fns/locale/pt';
import { format, subDays } from 'date-fns';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Icon from '../../../components/Icon';
import Select from '../../../components/Select';
import Tooltip from '../../../components/Tooltip';
import CurrentAvg from '../../../components/CurrentAvg';
import { FunnelContext } from '../../../contexts/FunnelContext';

import Api from '../../../services/api';
import { getActivityName, getPeriodName, calcAvgDoneActivities } from '../../../utils';

import styles from './styles';

const ActivitiesCard = forwardRef((props, ref) => {
  const {
    classes, activites, funnelUser, funnel, dreamDate, hasAgendor, isManager
  } = props;

  const history = useHistory();
  const { funnelState, funnelDispatch } = useContext(FunnelContext);
  const [isHovering, setIsHovering] = useState(false);

  const isSmallMedia = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleDateChange = (e) => {
    const days = parseInt(e.target.value);

    funnelDispatch({
      type: 'UPDATE',
      tasksLookbackDays: days
    });
    Api.patch(`/funnels/${funnelState.id}`, { tasksLookbackDays: days });

    const params = {
      startDate: `${format(subDays(new Date(), days), 'yyyy/MM/dd')} 00:00:00`,
      endDate: `${format(new Date(), 'yyyy/MM/dd')} 23:59:59`,
    };

    Api
      .get('/agendor/tasks', { params } )
      .then(({ data }) => {
        const newActivities = activites.map((activity) => {
          const agendorActivity = data.find(({ name }) => (
            name === activity.activity
          )) || { count: 0 };

          const avgDoneActivities = calcAvgDoneActivities(agendorActivity.count, days, activity.period);

          return {
            ...activity,
            doneActivities: avgDoneActivities,
          };
        });

        funnelDispatch({
          type: 'UPDATE',
          activitiesFrequency: newActivities
        });
      })
      .catch(() => alert('Houve um erro tente novamente mais tarde'));
  };

  const formatedUpdatedAt = funnel.updatedAt ? format(new Date(funnel.updatedAt), 'd, LLL. yyyy', { locale: ptLocale }, ) : '';
  const formatedDate = dreamDate ? format(new Date(dreamDate), 'd, LLL. yyyy', { locale: ptLocale }) : '';

  return (
    <div
      ref={ref}
      className={classes.card}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className={classes.header}>
        <h4 className={classes.title}>
          { hasAgendor
            ? (isManager ? `QUANTAS ATIVIDADES ${funnelUser.name} FINALIZOU` : 'QUANTAS ATIVIDADES FINALIZEI')
            : (isManager ? `META DE ATIVIDADES DE ${funnelUser.name}` : 'META DE ATIVIDADES')
          }
          {
            hasAgendor && (
              <div className={classes.integrationSelect}>
                <Select
                  options={[
                    { value: 30, label: 'nos últimos 30 dias' },
                    { value: 14, label: 'nos últimos 14 dias' },
                    { value: 7, label: 'nos últimos 7 dias' },
                  ]}
                  value={funnelState.tasksLookbackDays}
                  onChange={handleDateChange}
                />
              </div>
            )
          }
          { hasAgendor && (' / META DE ATIVIDADES') }
          <span className={classes.asterisk}> *</span>
        </h4>
        <p className={classes.legend}>
          * para realizar sonho em
          <span className={classes.legendHighlight}> {formatedDate}</span>
        </p>
        <div className={classes.updatedAt}>
          {
            isHovering && !isManager
              ? (<Tooltip arrow placement="top" title="Editar cadência de atividades atual">
                  <div><Icon name="edit" onClick={() => history.push("/atividades")} /></div>
                </Tooltip>)
              : !isSmallMedia && (
                <section>
                  atualizado em
                  <br />
                  {' '}
                  <b>{formatedUpdatedAt}</b>
                </section>
              )
          }
        </div>
      </div>
      <div className={classes.activities}>
        {
          activites.map((activity) => {
            const { doneActivities = 0 } = activity;
            const pctDone = (doneActivities / activity.frequency >= 1 || activity.frequency === 0) ? 100 : (doneActivities / activity.frequency) * 100;
            const classActivityIconIntegration = (pctDone === 100) ? classes.activityIconDoneIntegration : classes.activityIconIntegration;
            return (
              <div key={activity.activity} className={classes.activityWrapper}>
                {
                  hasAgendor
                    ? (
                      <CircularWithLabel value={pctDone}>
                        <div className={classes.activityIntegration}>
                          <Icon name={activity.activity} className={classActivityIconIntegration} />
                          <span className={classes.activityNumberIntegration}>
                            {doneActivities}
                            <span className={classes.activityTotalNumberIntegration}>
                              /
                              {activity.frequency}
                            </span>
                          </span>

                        </div>
                      </CircularWithLabel>
                    )
                    : (
                      <section>
                        <div className={classes.activity}>
                          <span className={classes.activityNumber}>
                            {activity.frequency}
                          </span>
                          <Icon name={activity.activity} className={classes.activityIcon} />
                        </div>
                      </section>
                    )
                }
                <p className={classes.activityText}>
                  {getActivityName(activity.activity)}
                  /
                  {getPeriodName(activity.period)}
                </p>
                { !hasAgendor && 
                  <CurrentAvg
                    done={doneActivities || activity.frequency}
                    current={activity.currentFrequency}
                    period={activity.period}
                  />
                }
              </div>
            )
          })
        }
      </div>
    </div>
  );
});

const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  bottom: {
    marginTop: 2,
    color: theme.palette.grey[200],
    '@media print': {
      maxWidth: '75px',
      maxHeight: '75px'
    }
  },
  top: {
    color: theme.palette.primary.main,
    position: 'absolute',
    left: 0,
    '@media print': {
      maxWidth: '80px',
      maxHeight: '80px'
    }
  },
  topDone: {
    color: theme.palette.success.main,
    position: 'absolute',
    left: 0,
    '@media print': {
      maxWidth: '80px',
      maxHeight: '80px'
    }
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

function Circular(props) {
  const classes = useStylesFacebook();
  const isSmallMedia = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={isSmallMedia ? 75 : 135}
        thickness={1}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        className={props.value >= 100 ? classes.topDone : classes.top}
        classes={{ circle: classes.circle }}
        size={isSmallMedia ? 80 : 140}
        thickness={3}
        {...props}
      />
    </div>
  );
}

function CircularWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex" mb={1}>
      <Circular {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default withStyles(styles)(ActivitiesCard);
