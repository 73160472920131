export default (theme) => ({
  card: {
    padding: theme.spacing(2.5, 0),
    width: theme.spacing(26),
    minWidth: theme.spacing(26),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.screenGrey.main,
    borderRadius: theme.spacing(1),
    margin: theme.spacing(1.5),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E9E1FA',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2.5, 2),
    },
  },
  cardSelected: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  icon: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: '1.125rem',
    letterSpacing: -0.4,
    textAlign: 'center',
    padding: theme.spacing(0, 2),
    fontWeight: 700,
    margin: 0,
  },
  button: {
    marginTop: theme.spacing(3),
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    padding: theme.spacing(1, 3),
    borderRadius: theme.spacing(2),
    border: 'none',
    cursor: 'pointer',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSelected: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
  checkIcon: {
    stroke: theme.palette.primary.main,
  },
});
