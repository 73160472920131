import React, { forwardRef, useState } from 'react';
import ptLocale from 'date-fns/locale/pt';
import { format } from 'date-fns';
import { differenceInCalendarMonths } from 'date-fns';
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';

import Icon from '../../../components/Icon';
import Tooltip from '../../../components/Tooltip';
import Progress from '../../../components/Progress';
import CurrentAvg from '../../../components/CurrentAvg';

import styles from './styles';

const SalesCard = forwardRef((props, ref) => {
  const {
    classes,
    avgTicket,
    numberOfSales,
    soldValue,
    soldQuantity,
    funnelUser,
    funnel,
    isManager
  } = props;

  const history = useHistory();
  const [isHovering, setIsHovering] = useState(false);

  const isSmallMedia = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const formatter = new Intl.NumberFormat('pt-BR');
  const formatedAvgTicket = formatter.format(avgTicket);
  const soldNumberOfSales = soldQuantity || parseInt(soldValue / avgTicket);
  const progress = (soldNumberOfSales * 100) / numberOfSales;
  const formatedDate = funnel.updatedAt ? format(new Date(funnel.updatedAt), 'd, LLL. yyyy', { locale: ptLocale }, ) : '';

  const goneMonthsFromStart = differenceInCalendarMonths(new Date(), new Date(funnel.dreamInitDate || funnel.createdAt)) || 1;
  const monthsFromToday = differenceInCalendarMonths(new Date(funnel.dreamDate), new Date());
  const numberOfSalesLeft = (numberOfSales - soldNumberOfSales) <= 0 ? 0 : numberOfSales - soldNumberOfSales;
  const numberOfSalesGoalPerMonth = Math.ceil(numberOfSalesLeft / monthsFromToday);
  const avgSoldPerMonth = Math.round(soldNumberOfSales / goneMonthsFromStart);

  return (
    <div
      ref={ref}
      className={classes.card}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className={classes.header}>
        <h4 className={classes.title}>
          {
            isManager
              ? `QUANTO ${funnelUser.name} JÁ VENDEU *`
              : 'QUANTO EU JÁ VENDI *'
          }
        </h4>
        <div className={classes.updatedAt}>
          {
            isHovering && !isManager
              ? <Tooltip arrow placement="top" title="Editar ticket-médio">
                  <div><Icon name="edit" onClick={() => history.push('/ticket-medio')} /></div>
                </Tooltip>
              : !isSmallMedia && (
                <section>
                  atualizado em
                  <br />
                  {' '}
                  <b>{formatedDate}</b>
                </section>
              )
          }
        </div>
      </div>
      <div>
        <div className={classes.progressHeader}>
          <span>
            <span className={classes.closedSales}>
              { soldNumberOfSales || 0}
            </span>
            <span> de </span>
            <span className={classes.totalSales}>{numberOfSales}</span>
            <span> negócios ganhos</span>
          </span>
          <span role="img" aria-label='trophy'>🏆</span>
        </div>
        <Progress value={progress || 0} />
        <div>
          <span className={classes.closedSalesLegend}>
            Vendas efetuadas
          </span>
          <span className={classes.totalSalesLegend}>
            Quantidade de vendas necessárias
          </span>
        </div>
      </div>
      <p className={classes.legend}>
        * Considerando seu
        <span className={classes.legendHighlight}> ticket-medio </span>
        de
        <span className={classes.legendHighlight}>
          {' '}
          R$
          {formatedAvgTicket}
          {' '}
        </span>
      </p>
      <CurrentAvg
        inline
        done={numberOfSalesGoalPerMonth}
        current={avgSoldPerMonth}
        period='month'
        myAvgText='* Minha média vs meta:'
      />
    </div>
  );
});
export default withStyles(styles)(SalesCard);
