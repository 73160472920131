const links = {
  AGENDOR_SITE: 'https://www.agendor.com.br',
  AGENDOR_REGISTRATION: 'https://www.agendor.com.br/cadastro/?pid=104',
  AGENDOR_INTEGRATIONS: 'https://web.agendor.com.br/sistema/integracoes',
  AGENDOR_ITUNES: 'https://apps.apple.com/br/app/agendor-crm-para-vendas/id804767405',
  AGENDOR_GPLAY: 'https://play.google.com/store/apps/details?id=br.com.agendor&hl=pt_BR&gl=US'
};

export const getLink = ({link, utmSource = 'funil-sonhos', utmMedium = 'referral', utmCampaign = 'site-funil-sonhos', utmContent = '', utmTerm = ''}) => {
	let finalLink = links[link];
	let UTMsArr = [
		"utm_source=" + encodeURIComponent(utmSource),
		"utm_medium=" + encodeURIComponent(utmMedium),
		"utm_campaign=" + encodeURIComponent(utmCampaign),
		"utm_content=" + encodeURIComponent(utmContent),
		"utm_term=" + encodeURIComponent(utmTerm)
	];
	let UTMs = UTMsArr.join("&");
	finalLink = (finalLink.indexOf("?") !== -1) ? (finalLink + "&" + UTMs) : (finalLink + "?" + UTMs);

	return finalLink;
};