import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Icon from '../Icon';

import styles from './styles';

function AgendorSignupModal({
  classes,
  open,
  onClose,
  onConfirm,
}) {
  return (
    <Modal
      onClose={onClose}
      open={open}
    >
      <div className={classes.wrapper}>
        <div className={classes.modal}>
          <button className={classes.closeButon} onClick={onClose}>
            &#10005;
          </button>
          <div className={classes.agendorSquare}>
            <Icon name="agendorLogo" />
          </div>
          <h1 className={classes.title}>
            Crie sua conta grátis no Agendor
          </h1>
          <p className={classes.text}>
            O Agendor é uma
            {' '}
            <b>plataforma de CRM</b>
            {' '}
            que atua como um assistente para equipes de vendas, dando visibilidade e controle na gestão.
            <br />
            Ao conectar com o Agendor, os dados de ticket-médio, total vendido e atividades serão automatizados em seu Funil dos Sonhos.
          </p>
          <button className={classes.connectButton} onClick={onConfirm}>
            Criar conta no Agendor
          </button>
          <p className={classes.bottomText} onClick={onClose}>
            Cancelar criação de conta
          </p>
        </div>
      </div>
    </Modal>
  );
}

export default withStyles(styles)(AgendorSignupModal);
