import { darken } from '@material-ui/core/styles';

export default (theme) => ({
  button: {
    marginLeft: '8vw',
    padding: theme.spacing(2, 2, 2, 4),
    fontWeight: 'bold',
    fontSize: '1rem',
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: theme.spacing(10),
    border: 'none',
    width: 'fit-content',
    position: 'relative',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '3vh 4vw 0',
      padding: theme.spacing(1, 1, 1, 2),
    },
  },
  activeButton: {
    backgroundColor: '#5C57FF',
    color: '#FFFFFF',
  },
  inactiveButton: {
    paddingRight: theme.spacing(4),
    border: '4px solid #F8F5FF',
    backgroundColor: '#fff',
    color: '#5C57FF',
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(2),
    },
  },
  icon: {
    paddingRight: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0.5),
    },
  },
  activeIcon: {
    fill: '#fff',
  },
  closeButton: {
    color: '#FFF',
    backgroundColor: darken('#5C57FF', 0.3),
    fontSize: '0.75rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderRadius: theme.spacing(2),
    minWidth: theme.spacing(4),
    minHeight: theme.spacing(4),
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },

});
