import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import { Button as MuiButton } from '@material-ui/core';
import Button from '../Button';
import SectionHeader from '../SectionHeader';
import Icon from '../Icon';
import Title from '../Title';

import styles from './styles';

function SectionIntro({
  classes,
  header,
  title,
  subtitle,
  videoSrc,
  onClick,
  onBackClick,
}) {
  const buttonWrapperClasses = clsx(
    classes.buttonWrapper,
    { [classes.onlyButton]: !onBackClick },
  );

  return (
    <>
      <SectionHeader
        showSignup={false}
        showLogin={false}
      />
      <div className={classes.wrapper}>
        <span className={classes.header}>
          <Icon name="trophy" className={classes.icon} />
          ・
          {header}
        </span>
        <hr className={classes.divider} />
        <Title
          title={title}
          subtitle={subtitle}
        />
        <iframe title={title} className={classes.video} src={videoSrc} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
        </iframe>
        <div className={buttonWrapperClasses}>
          {
            onBackClick && (
              <MuiButton
                startIcon={
                  <Icon name="arrow" className={classes.backButtonIcon} />
                }
                classes={{
                  root: classes.backButtonRoot,
                  label: classes.backButtonLabel,
                }}
                onClick={onBackClick}
              >
                VOLTAR
              </MuiButton>
            )
          }
          <Button onClick={onClick}>
            Começar!
          </Button>
        </div>
      </div>
    </>
  );
}

export default withStyles(styles)(SectionIntro);
