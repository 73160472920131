import React, { cloneElement } from 'react';

import { ReactComponent as LogoIcon } from '../../assets/vectors/logo.svg';
import { ReactComponent as LogoWhiteIcon } from '../../assets/vectors/logo-white.svg';
import { ReactComponent as LogoBlackIcon } from '../../assets/vectors/logo-black.svg';
import { ReactComponent as CheckIcon } from '../../assets/vectors/check.svg';
import { ReactComponent as TrophyIcon } from '../../assets/vectors/trophy.svg';
import { ReactComponent as SalesGoalIcon } from '../../assets/vectors/sales-goal.svg';
import { ReactComponent as CompetenceIcon } from '../../assets/vectors/competence.svg';
import { ReactComponent as ActivityIcon } from '../../assets/vectors/activity.svg';
import { ReactComponent as AvgTicketIcon } from '../../assets/vectors/avg-ticket.svg';
import { ReactComponent as PersonIcon } from '../../assets/vectors/person.svg';
import { ReactComponent as ArrowIcon } from '../../assets/vectors/arrow.svg';
import { ReactComponent as CalendarIcon } from '../../assets/vectors/calendar.svg';
import { ReactComponent as RightArrow } from '../../assets/vectors/right-arrow.svg';
import { ReactComponent as Email } from '../../assets/vectors/email.svg';
import { ReactComponent as Call } from '../../assets/vectors/call.svg';
import { ReactComponent as Proposal } from '../../assets/vectors/proposal.svg';
import { ReactComponent as Visit } from '../../assets/vectors/visit.svg';
import { ReactComponent as Meeting } from '../../assets/vectors/meeting.svg';
import { ReactComponent as ColorCalendar } from '../../assets/vectors/color-calendar.svg';
import { ReactComponent as ColorHourglass } from '../../assets/vectors/color-hourglass.svg';
import { ReactComponent as Hourglass } from '../../assets/vectors/hourglass.svg';
import { ReactComponent as Settings } from '../../assets/vectors/settings.svg';
import { ReactComponent as Menu } from '../../assets/vectors/menu.svg';
import { ReactComponent as FunnelFirstStepColored } from '../../assets/vectors/funnel-first-step-colored.svg';
import { ReactComponent as FunnelFirstStep } from '../../assets/vectors/funnel-first-step.svg';
import { ReactComponent as FunnelSecondStepColored } from '../../assets/vectors/funnel-second-step-colored.svg';
import { ReactComponent as FunnelSecondStep } from '../../assets/vectors/funnel-second-step.svg';
import { ReactComponent as FunnelThirdStepColored } from '../../assets/vectors/funnel-third-step-colored.svg';
import { ReactComponent as FunnelThirdStep } from '../../assets/vectors/funnel-third-step.svg';
import { ReactComponent as FunnelFourthStepColored } from '../../assets/vectors/funnel-fourth-step-colored.svg';
import { ReactComponent as FunnelFourthStep } from '../../assets/vectors/funnel-fourth-step.svg';
import { ReactComponent as Wallet } from '../../assets/vectors/wallet.svg';
import { ReactComponent as BlackCalendar } from '../../assets/vectors/black-calendar.svg';
import { ReactComponent as SparkleTrophy } from '../../assets/vectors/sparkle-trophy.svg';
import { ReactComponent as Edit } from '../../assets/vectors/edit.svg';
import { ReactComponent as Discipline } from '../../assets/vectors/discipline.svg';
import { ReactComponent as DisciplineSelected } from '../../assets/vectors/discipline-selected.svg';
import { ReactComponent as Comunication } from '../../assets/vectors/comunication.svg';
import { ReactComponent as ComunicationSelected } from '../../assets/vectors/comunication-selected.svg';
import { ReactComponent as InvestigativeAbility } from '../../assets/vectors/investigative-ability.svg';
import { ReactComponent as InvestigativeAbilitySelected } from '../../assets/vectors/investigative-ability-selected.svg';
import { ReactComponent as ResultsOrientation } from '../../assets/vectors/results-orientation.svg';
import { ReactComponent as ResultsOrientationSelected } from '../../assets/vectors/results-orientation-selected.svg';
import { ReactComponent as CommercialTenacity } from '../../assets/vectors/commercial-tenacity.svg';
import { ReactComponent as CommercialTenacitySelected } from '../../assets/vectors/commercial-tenacity-selected.svg';
import { ReactComponent as Agility } from '../../assets/vectors/agility.svg';
import { ReactComponent as AgilitySelected } from '../../assets/vectors/agility-selected.svg';
import { ReactComponent as SelfMotivation } from '../../assets/vectors/self-motivation.svg';
import { ReactComponent as SelfMotivationSelected } from '../../assets/vectors/self-motivation-selected.svg';
import { ReactComponent as AgendorLogo } from '../../assets/vectors/agendor-logo.svg';
import { ReactComponent as Integration } from '../../assets/vectors/integration.svg';
import { ReactComponent as AgendorIntegration } from '../../assets/vectors/agendor-integration.svg';
import { ReactComponent as Print } from '../../assets/vectors/print.svg';
import { ReactComponent as Face } from '../../assets/vectors/face.svg';
import { ReactComponent as PermIdentity } from '../../assets/vectors/perm-identity.svg';
import { ReactComponent as Close } from '../../assets/vectors/close.svg';
import { ReactComponent as ArrowUp } from '../../assets/vectors/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../assets/vectors/arrow-down.svg';
import { ReactComponent as CheckMark } from '../../assets/vectors/check-mark.svg';

const ICONS = {
  logo: <LogoIcon />,
  logoWhite: <LogoWhiteIcon />,
  logoBlack: <LogoBlackIcon />,
  check: <CheckIcon />,
  trophy: <TrophyIcon />,
  salesGoal: <SalesGoalIcon />,
  competence: <CompetenceIcon />,
  activity: <ActivityIcon />,
  avgTicket: <AvgTicketIcon />,
  person: <PersonIcon />,
  arrow: <ArrowIcon />,
  calendar: <CalendarIcon />,
  rightArrow: <RightArrow />,
  email: <Email />,
  call: <Call />,
  proposal: <Proposal />,
  visit: <Visit />,
  meeting: <Meeting />,
  colorCalendar: <ColorCalendar />,
  colorHourglass: <ColorHourglass />,
  hourglass: <Hourglass />,
  settings: <Settings />,
  menu: <Menu />,
  funnelFirstStepColored: <FunnelFirstStepColored />,
  funnelFirstStep: <FunnelFirstStep />,
  funnelSecondStepColored: <FunnelSecondStepColored />,
  funnelSecondStep: <FunnelSecondStep />,
  funnelThirdStepColored: <FunnelThirdStepColored />,
  funnelThirdStep: <FunnelThirdStep />,
  funnelFourthStepColored: <FunnelFourthStepColored />,
  funnelFourthStep: <FunnelFourthStep />,
  wallet: <Wallet />,
  blackCalendar: <BlackCalendar />,
  sparkleTrophy: <SparkleTrophy />,
  edit: <Edit />,
  discipline: <Discipline />,
  disciplineSelected: <DisciplineSelected />,
  comunication: <Comunication />,
  comunicationSelected: <ComunicationSelected />,
  investigativeAbility: <InvestigativeAbility />,
  investigativeAbilitySelected: <InvestigativeAbilitySelected />,
  resultsOrientation: <ResultsOrientation />,
  resultsOrientationSelected: <ResultsOrientationSelected />,
  commercialTenacity: <CommercialTenacity />,
  commercialTenacitySelected: <CommercialTenacitySelected />,
  agility: <Agility />,
  agilitySelected: <AgilitySelected />,
  selfMotivation: <SelfMotivation />,
  selfMotivationSelected: <SelfMotivationSelected />,
  agendorLogo: <AgendorLogo />,
  integration: <Integration />,
  agendorIntegration: <AgendorIntegration />,
  print: <Print />,
  face: <Face />,
  permIdentity: <PermIdentity />,
  close: <Close />,
  arrowUp: <ArrowUp />,
  arrowDown: <ArrowDown />,
  checkMark: <CheckMark />
};

function Icon({ name, className, onClick }) {
  return cloneElement(ICONS[name], { className, onClick });
}

export default Icon;
