import React from 'react';

import QuestionStructure from '../../../components/QuestionStructure';
import Textarea from '../../../components/Textarea';

function DreamText({
  text,
  dreamType,
  onTextChange,
  onBackClick,
  onForwardClick,
}) {
  const handleChange = (e) => onTextChange('text', e.target.value);
  const title = (dreamType === "Outros") ? 'Nos conte mais...' : dreamType + '! Que legal. Nos conte mais...';

  return (
    <QuestionStructure
      titleNumber={2}
      title={title}
      onBackClick={onBackClick}
      onForwardClick={onForwardClick}
      isForwardDisabled={!text}
    >
      <Textarea
        placeholder={getQuestionPlaceholder(dreamType)}
        value={text}
        onChange={handleChange}
        maxLength={140}
      />
    </QuestionStructure>
  );
}

const getQuestionPlaceholder = (type) => {
  const placeholders = {
    Viagem: 'Uma viagem de férias em...',
    Veículo: 'Um Civic, Corolla, Golf, Tesla...',
    Imóvel: 'Uma casa ou apartamento em...',
    Formação: 'Um MBA, Pós-graduação...',
    Experiência: 'Um passeio de balão...',
    Outros: 'Um celular, uma TV...',
  };

  return placeholders[type];
};

export default DreamText;
