import React, { useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { differenceInCalendarMonths, addMonths } from 'date-fns';

import QuestionStructure from '../../../components/QuestionStructure';
import Icon from '../../../components/Icon';
import CalendarRadio from '../CalendarRadio';
import CalendarPicker from '../CalendarPicker';
import CalendarSlider from '../CalendarSlider';

import styles from './styles.js';

const getMonths = (date) => {
  const monthsFromToday = differenceInCalendarMonths(date, new Date());
  return monthsFromToday % 3 === 0 ? monthsFromToday : 0;
}

function DreamDate({
  classes,
  date,
  onDateChange,
  onBackClick,
  onForwardClick,
}) {

  date = date ? date : addMonths(new Date(), 3);

  const monthsFromToday = differenceInCalendarMonths(date, new Date());
  const shouldShowDatePicker = !([3, 6, 12, 24].includes(monthsFromToday));

  const isSmallMedia = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isShowingDatePicker, setIsShowingDatePicker] = useState(shouldShowDatePicker);
  const [month, setMonth] = useState(getMonths(date));

  const handlePickerChange = (date) => {
    setMonth(getMonths(date));
    onDateChange('date', date);
  };

  const handleSelectorChange = (month) => {
    const newDate = addMonths(new Date(), month);
    onDateChange('date', newDate);
    setMonth(month);
  };

  const isDisabled = isShowingDatePicker ? !date : !(month && date);

  return (
    <QuestionStructure
      titleNumber={4}
      title="Em quanto tempo, você deseja realizar seu sonho?"
      onBackClick={onBackClick}
      onForwardClick={onForwardClick}
      isForwardDisabled={isDisabled}
    >
      <section>
        <div className={classes.tabsWrapper}>
          <button
            className={clsx(classes.tab, { [classes.tabActive]: !isShowingDatePicker })}
            onClick={() => {
              setIsShowingDatePicker(false);
            }}
          >
            <Icon
              className={classes.iconButton}
              name={!isShowingDatePicker ? 'colorHourglass' : 'hourglass'}
            />
            { isSmallMedia ? 'Prazo' : 'Escolha um prazo'}
          </button>
          <button
            className={clsx(classes.tab, { [classes.tabActive]: isShowingDatePicker })}
            onClick={() => setIsShowingDatePicker(true)}
          >
            <Icon
              className={classes.iconButton}
              name={isShowingDatePicker ? 'colorCalendar' : 'calendar'}
            />
            { isSmallMedia ? 'Data' : 'Escolha um data'}
          </button>
        </div>
        {
        !isShowingDatePicker && (
          <DateSelector
            onDateChange={handleSelectorChange}
            month={month}
          />
        )
      }
        {
        isShowingDatePicker && (
          <CalendarPicker
            value={date}
            onChange={handlePickerChange}
          />
        )
      }
      </section>
    </QuestionStructure>
  );
}

function DateSelector({ onDateChange, month }) {
  const isSmallMedia = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  if (isSmallMedia) {
    return (
      <CalendarRadio
        month={month}
        onChange={onDateChange}
      />
    );
  }

  return (
    <CalendarSlider
      month={month}
      onChange={onDateChange}
      valueLabelDisplay={month ? 'on' : 'off'}
    />
  );
}

export default withStyles(styles)(DreamDate);
