export default (theme) => ({
  slider: {
    width: theme.spacing(60),
    paddingLeft: theme.spacing(8),
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
    display: 'inline-block',
  },
  markLabel: {
    color: theme.palette.text.main,
    fontSize: 18,
    marginTop: '-68px',
  },
});
