import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Icon from '../Icon';

import styles from './styles';

function CompetenceModal({
  classes, open, onClose, icon, description, title,
}) {
  return (
    <Modal
      onClose={onClose}
      open={open}
      BackdropProps={{
        className: classes.backdrop,
      }}
    >
      <div className={classes.wrapper} onClick={onClose}>
        <div className={classes.modal}>
          <button className={classes.closeButon} onClick={onClose}>
            &#10005;
          </button>
          <Icon name={icon} className={classes.image} />
          <h1 className={classes.title}>{title}</h1>
          <p className={classes.description}>{description}</p>
        </div>
      </div>
    </Modal>
  );
}

export default withStyles(styles)(CompetenceModal);
