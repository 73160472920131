import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';

import { dreamDates } from '../../../utils/date';
import styles from './styles.js';

function CalendarSlider({ classes, onChange, valueLabelDisplay = 'on', month }) {
  return (
    <div className={classes.slider}>
      <Slider
        classes={{
          markLabel: classes.markLabel,
          thumb: classes.thumb,
          mark: classes.mark,
          markActive: classes.markActive,
          track: classes.track,
          rail: classes.rail,
        }}
        ValueLabelComponent={ValueLabelComponent}
        valueLabelFormat={(value) => `${value} meses`}
        defaultValue={0}
        value={month}
        step={3}
        marks={dreamDates}
        valueLabelDisplay={valueLabelDisplay}
        min={3}
        max={24}
        onChangeCommitted={(_, value) => onChange(value)}
      />
    </div>
  );
}

const useTooltipStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    boxShadow: '0px 6px 25px rgba(111, 130, 146, 0.25)',
    borderRadius: theme.spacing(1),
    color: '#303440',
    padding: theme.spacing(1.5, 3),
    fontSize: 18,
    fontWeight: 'bold',
    margin: theme.spacing(2),
  },
  arrow: {
    color: '#fff',
  },
}));

function ValueLabelComponent(props) {
  const { children, open, value } = props;
  const classes = useTooltipStyles();

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
      classes={classes}
    >
      {children}
    </Tooltip>
  );
}

export default withStyles(styles)(CalendarSlider);
