import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Icon from '../Icon';
import SignOutButton from '../SignOutButton';

import Analytics from '../../utils/analytics';

import styles from './styles';

function SectionHeader({ classes, title, subTitle, showSignup = true, showLogin = true, showSignOut = true }) {

  const history = useHistory();

  const handleLoginClick = () => {
    Analytics.log("login_clicked");
    history.push("/entrar");
  };

  const handleSignUpClick = () => {
    Analytics.log("sign_up_clicked");
    history.push("/cadastrar");
  };

  return (
    <header className={classes.header}>
      <Link to="/">
        <div className={classes.logoWrapper}>
          <Icon name="logo" />
          <span className={classes.title}>FUNIL DOS SONHOS</span>
        </div>
      </Link>
      <div className={classes.headerButtons}>
      { (showLogin) && (
        <Button
          startIcon={<Icon name="person" />}
          classes={{
            root: classes.loginButtonRoot,
            label: classes.buttonLabel,
          }}
          onClick={handleLoginClick}
        >
          Entrar
        </Button>
      )}
      { (showSignup) && (
        <Button
          classes={{
            root: classes.linkButtonRoot,
            label: classes.buttonLabel,
          }}
          onClick={handleSignUpClick}
        >
          Cadastre-se
        </Button>
      )}
      { (showSignOut) && (
        <SignOutButton
          classes={{
            root: classes.linkButtonRoot,
            label: classes.buttonLabel,
          }}
        />
      )}
      </div>
    </header>
  );
}

export default withStyles(styles)(SectionHeader);
