export default (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.text.dark,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
    },
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '2.625rem',
    letterSpacing: -0.8,
    margin: theme.spacing(5, 0, 3),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  subtitle: {
    fontWeight: 'normal',
    width: theme.spacing(71),
    fontSize: '1.125rem',
    margin: theme.spacing(0, 0, 6),
    textAlign: 'center',
    color: theme.palette.grey.main,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
});
