import React, { useState, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import QuestionStructure from '../../../components/QuestionStructure';
import Toast from '../../../components/Toast';
import { UserContext } from '../../../contexts/UserContext';

import Api from '../../../services/api';
import styles from './styles';

import Analytics from '../../../utils/analytics';

function InviteManager({
  classes,
  onBackClick,
  onForwardClick,
  managerEmail
}) {
  const [checkbox, setCheckbox] = useState(false);
  const [email, setEmail] = useState(managerEmail);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const { userDispatch } = useContext(UserContext);

  const inviteManager = (e) => {
    e.preventDefault()
    Api
      .post('/emails/invite-manager', { managerEmail: email })
      .then(() => {
        Analytics.log("manager_invited");
        setIsEmailSent(true);
      })
      .catch(() => alert('Houve um erro ao enviar o email'));
    userDispatch({ type: 'UPDATE', managerEmail: email });
  };

  return (
    <QuestionStructure
      titleNumber={1}
      title="Convide seu gestor para definir as competências com você"
      onBackClick={onBackClick}
      onForwardClick={() => onForwardClick(isEmailSent)}
      isForwardDisabled={!(checkbox || isEmailSent)}
    >
      <div>
        <form>
          <label className={classes.inputLabel}>
            Email do meu gestor / líder
          </label>
          <div className={classes.inputWrapper}>
            <input
              className={classes.input}
              placeholder="placeholder@email.com.br"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              className={classes.button}
              onClick={inviteManager}
            >
              Enviar
            </button>
          </div>
        </form>
        {
          isEmailSent && (
            <Toast
              title="Convite enviado!"
              text="Aguarde a resposta do seu gestor para continuar esta parte"
              toastClasses={{
                wrapper: classes.toast
              }}
            />
          )
        }
        <FormControlLabel
          classes={{ label: classes.label }}
          control={(
            <Checkbox
              checked={checkbox}
              onChange={() => setCheckbox(!checkbox)}
              color="primary"
            />
          )}
          label="Quero preencher esta parte sozinho"
        />
      </div>
    </QuestionStructure>
  );
}

export default withStyles(styles)(InviteManager);
