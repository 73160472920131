import React, { useState, useRef, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Hidden from '@material-ui/core/Hidden';
import Icon from '../../../components/Icon';

import styles from './styles';

function Separator({ classes, handleUpClick, handleDownClick }) {
  const ref = useRef();
  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    moveButton();
    window.addEventListener('scroll', moveButton);
    return () => window.removeEventListener('scroll', moveButton);
  }, []);

  const moveButton = () => {
    if (ref.current) {
      const linePosition = ref.current.getBoundingClientRect().y;
      setScrollPercentage(linePosition / window.innerHeight);
    }
  };

  const iconClasses = clsx(classes.buttonArrowIcon, {
    [classes.buttonArrowIconUp]: scrollPercentage < 0.5,
  });

  const handleClick = () => {
    if (scrollPercentage > 0.5) {
      handleDownClick();
    } else {
      handleUpClick();
    }
  };

  return (
    <div ref={ref} className={classes.separatorLine}>
      <Hidden smDown>
        <button className={classes.button} onClick={handleClick}>
          <Icon name="arrow" className={iconClasses} />
        </button>
      </Hidden>
    </div>
  );
}

export default withStyles(styles)(Separator);
