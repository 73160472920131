export default (theme) => ({
  label: {
    color: theme.palette.text.main,
  },
  inputWrapper: {
    marginBottom: theme.spacing(2),
  },
  inputLabel: {
    display: 'block',
    color: '#3D414C',
    marginBottom: theme.spacing(1.5),
    fontWeight: 700,
  },
  input: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '1.125rem',
    fontFamily: 'inherit',
    width: theme.spacing(50),
    boxSizing: 'border-box',
    padding: '15px 24px',
    caretColor: theme.palette.primary.main,
    border: '1px solid #DCD8E5',
    borderRadius: theme.spacing(1, 0, 0, 1),
    borderRight: 'none',
    '&::placeholder': {
      color: '#AB96E6',
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      width: 'calc(90vw - 96px)',
      padding: theme.spacing(1.5, 2),
    },
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(0, 1, 1, 0),
    border: 'none',
    color: '#fff',
    fontSize: '1.125rem',
    fontWeight: 700,
    height: 56,
    cursor: 'pointer',
    padding: theme.spacing(0, 3),
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      height: 47,
    },
  },
  toast: {
    width: theme.spacing(58.75)
  }
});
