import React from 'react';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';

import SuccessSquare from '../SuccessSquare';

import styles from './styles';

function Toast({
	classes,
	toastClasses = {},
	title,
	text,
	floating = false,
	slide = false,
	show = true,
	slideDirection = 'up',
	onEnter
}) {
	const wrapperClasses = clsx(classes.wrapper, toastClasses.wrapper);

	const content = (
		<div className={wrapperClasses}>
		  <SuccessSquare />
		  <div className={classes.textWrapper}>
		    <p className={classes.title}>
		      {title}
		    </p>
		    <p className={classes.text}>
		      {text}
		    </p>
		  </div>
		</div>
	);

	if (show && onEnter) onEnter();
	
	return slide ? (
		<Slide direction={slideDirection} in={show} mountOnEnter unmountOnExit>
			{content}
		</Slide>)
	: (show ? content : null);
};

export default withStyles(styles)(Toast);