/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect } from 'react';
import { format, subDays } from 'date-fns';
import { Redirect } from 'react-router-dom';

import Api from '../../services/api';

import { FunnelContext } from '../../contexts/FunnelContext';
import { UserContext } from '../../contexts/UserContext';

import Funnel from '../../containers/Funnel';
import FunnelHeader from '../../components/FunnelHeader';

import { getFirstUncompletedFunnelStep, calcAvgDoneActivities } from '../../utils';

function MyFunnelPage() {
  const { userState } = useContext(UserContext);
  const { funnelState, funnelUpdate } = useContext(FunnelContext);

  const getDataFromAgendor = () => {
    const dealsParams = {
      startDate: `${format(new Date(funnelState.dreamInitDate || funnelState.createdAt), 'yyyy/MM/dd')} 00:00:00`,
      endDate: `${format(new Date(), 'yyyy/MM/dd')} 23:59:59`
    };
    const tasksParams = {
      startDate: `${format(subDays(new Date(), funnelState.tasksLookbackDays), 'yyyy/MM/dd')} 00:00:00`,
      endDate: `${format(new Date(), 'yyyy/MM/dd')} 23:59:59`
    };

    Promise.all([
      Api.get('/agendor/deals', { params: dealsParams }),
      Api.get('/agendor/tasks', { params: tasksParams }),
    ])
    .then(([ dealsResp, tasksResp ]) => {

      const newActivities = funnelState.activitiesFrequency.map((activity) => {
        const agendorActivity = tasksResp.data.find(({ name }) => (
          name === activity.activity
        )) || { count: 0 };

        const avgDoneActivities = calcAvgDoneActivities(agendorActivity.count, funnelState.tasksLookbackDays, activity.period);

        return {
          ...activity,
          doneActivities: avgDoneActivities,
        };
      });

      const avgTicket = dealsResp.data.avg_amount ? Number(dealsResp.data.avg_amount / 100) : funnelState.avgTicket;
      const newFunnelState = funnelUpdate({
        type: 'UPDATE',
        updatedAt: new Date(),
        avgTicket: avgTicket,
        soldValue: Number(dealsResp.data.total_amount / 100),
        soldQuantity: Number(dealsResp.data.count),
        activitiesFrequency: newActivities
      });

      Api.patch(`/funnels/${funnelState.id}`, newFunnelState);

    })
    .catch(() => alert('Houve um erro tente novamente mais tarde'));
  }

  useEffect(() => {
    if (funnelState.isCompleted && Boolean(userState.agendorToken)) {
      getDataFromAgendor();
    }
  }, [funnelState.isCompleted])

  if ( !funnelState.isCompleted ) {
    const step = getFirstUncompletedFunnelStep(funnelState);
    return <Redirect to={step} />;
  } else if (userState && userState.id && funnelState && funnelState.id) {
    return (
      <section>
        <FunnelHeader userName={userState.name} />
        <Funnel
          funnel={funnelState}
          funnelUser={userState}
          getDataFromAgendor={getDataFromAgendor}
        />
      </section>
    );
  }

  return null;
}

export default MyFunnelPage;
