import React, { useEffect, useState } from 'react';

import Profile from '../../containers/Profile';

function ProfilePage() {
  const [inviteeId, setInviteeId] = useState('');

  useEffect(() => {
    setInviteeId(sessionStorage.getItem('inviteeId'));
  }, []);

  return (
    <Profile inviteeId={inviteeId} />
  );
}

export default ProfilePage;
