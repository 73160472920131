const roundEdge = {
  left: 11,
  width: 10,
  height: 12,
  content: "''",
  position: 'absolute',
  borderLeft: '5px solid #fff',
};

export default (theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    listStyle: 'none',
    flexWrap: 'wrap',
    padding: 0,
    margin: 0,
  },
  item: {
    fontWeight: 'bold',
    fontSize: '1.125rem',
    padding: theme.spacing(0, 2.5),
    backgroundColor: '#F8F5FF',
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: theme.spacing(7.5),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  itemSelected: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  check: {
    width: 30,
    height: 30,
    backgroundColor: '#fff',
    borderRadius: 15,
    position: 'absolute',
    top: 15,
    left: -15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:before': {
      ...roundEdge,
      top: -8,
      borderBottomLeftRadius: theme.spacing(2),
      transform: 'rotate(-18deg)',
    },
    '&:after': {
      ...roundEdge,
      bottom: -8,
      borderTopLeftRadius: theme.spacing(2),
      transform: 'rotate(18deg)',
    },
  },
  textIcon: {
    paddingRight: theme.spacing(2),
  },
  checkIcon: {
    stroke: theme.palette.primary.main,
  },
});
