export default (theme) => ({
  backdrop: {
    backgroundColor: '#ffffff33!important',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '&:focus': {
      outline: 'none',
    },
  },
  modal: {
    background: '#fff',
    boxShadow: '0px 48px 50px 0px #65568829',
    borderRadius: theme.spacing(3),
    padding: theme.spacing(6, 11, 3),
    width: theme.spacing(72),
    color: theme.palette.text.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    boxSizing: 'border-box',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(4, 2, 2),
    },
  },
  title: {
    margin: theme.spacing(5, 0, 2),
    color: theme.palette.text.main,
    fontSize: '2rem',
    letterSpacing: -0.4,
    textAlign: 'center',
    padding: theme.spacing(0, 2),
    fontWeigth: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
      margin: theme.spacing(2, 0, 1),
    },
  },
  text: {
    fontSize: '1rem',
    lineHeight: '178%',
    textAlign: 'center',
    color: '#3D414C',
    margin: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  closeButon: {
    color: '#A49AB8',
    right: theme.spacing(3),
    top: theme.spacing(3),
    position: 'absolute',
    width: theme.spacing(3),
    height: theme.spacing(3),
    border: 'none',
    borderRadius: theme.spacing(3),
    alignSelf: 'flex-end',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(2),
      top: theme.spacing(2),
    },
  },
  button: {
    marginTop: theme.spacing(2),
    backgroundColor: '#fff',
    padding: theme.spacing(1, 3),
    borderRadius: theme.spacing(2),
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  agendorSquare: {
    display: 'inline-flex',
    width: theme.spacing(11.5),
    height: theme.spacing(11.5),
    background: '#332DE6',
    borderRadius: theme.spacing(4.5),
    alignItems: 'center',
    justifyContent: 'center',
  },
  funnelSquare: {
    display: 'inline-flex',
    width: theme.spacing(11.5),
    height: theme.spacing(11.5),
    background: theme.palette.primary.main,
    borderRadius: theme.spacing(4.5),
    alignItems: 'center',
    justifyContent: 'center',
  },
  integrationIcon: {
    display: 'inline-block',
    margin: theme.spacing(1, 2),
  },
  tooltip: {
    boxShadow: '0px 16px 40px rgba(65, 81, 122, 0.2)',
    backgroundColor: '#ffffff',
    color: theme.palette.grey.dark,
    fontSize: '1rem',
    padding: theme.spacing(2),
    lineHeight: '178%',
    borderRadius: theme.spacing(1),
  },
  tooltipArrow: {
    color: '#ffffff',
  },
  inform: {
    color: '#fff',
    backgroundColor: '#5A57F7',
    borderRadius: 10,
    width: theme.spacing(3),
    height: theme.spacing(2.25),
    textAlign: 'center',
    fontWeight: 900,
    fontSize: '0.75rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0, 0.5),
  },
  tokenText: {
    fontWeight: 700,
    color: '#5A57F7',
  },
  inputWrapper: {
    width: '100%',
    display: 'block',
    margin: theme.spacing(3, 0, 2.5),
    textAlign: 'left',
  },
  connectButton: {
    backgroundColor: '#5A57F7',
    border: 'none',
    padding: theme.spacing(3, 5),
    fontWeight: 'bold',
    fontSize: '1rem',
    color: '#fff',
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(3),
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      backgroundColor: '#F8F5FF',
      color: '#C3BAEF',
    },
  },
  bottomText: {
    fontWeight: 'bold',
    fontSize: '1rem',
    margin: theme.spacing(4, 0, 3),
    color: '#5A57F7',
    cursor: 'pointer',
  },
});
