import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { format, addDays, getISODay } from 'date-fns';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputMask from 'react-input-mask';
import QuestionStructure from '../../../components/QuestionStructure';

import Analytics from '../../../utils/analytics';

import styles from './styles';

function ConfigCalendar({
  classes,
  onBackClick,
  inviteeEmail = '',
  onForwardClick,
  manager,
  funnelUser
}) {
  const forwardButtonDefaultLabel = 'Prosseguir';
  const forwardButtonCalendarLabel = 'Adicionar no calendário e prosseguir'
  const [checkbox, setCheckbox] = useState(false);
  const [weekDay, setWeekDay] = useState('');
  const [time, setTime] = useState('');
  const [forwardButtonLabel, setForwardButtonLabel] = useState(forwardButtonCalendarLabel);
  const isValidWeekDayAndTime = (weekDay && time !== "" && /^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(time));

  const customOnForwardClick = () => {
    if (!checkbox && isValidWeekDayAndTime) {
      Analytics.log("follow_up_defined");
      createGoogleEvent();
    } else {
      Analytics.log("follow_up_definition_skipped");
    }
    onForwardClick();
  };

  const onFollowupChange = (e) => {
    setCheckbox(!checkbox);
    setForwardButtonLabel(!checkbox ? forwardButtonDefaultLabel : forwardButtonCalendarLabel);
  }

  const createGoogleEvent = () => {
    const now = new Date();
    const todayWeekDay = getISODay(now);
    const daysToAdd = (7 - todayWeekDay) + Number(weekDay);
    const date = format(addDays(now, daysToAdd), 'yyyyMMdd');

    const startTime = time ? Number(time.replace(/:/, '')) : '1600';
    const endTime = time ? Number(time.replace(/:/, '')) + 100 : '1700';

    window.open(`
      https://www.google.com/calendar/render?action=TEMPLATE&text=Funil+dos+Sonhos+-+Acompanhamento+com+líder&dates=${date}T${startTime}Z0300/${date}T${endTime}Z0300&details=O+Funil+dos+Sonhos+é+uma+ferramenta+simples+que+mostra+o+que+fazer+no+dia-a-dia+de+vendas+para+alcançar+objetivos+pessoais+e+conectá-los+com+as+metas+da+empresa.+%0D%0A%0D%0AConheça+um+pouco+mais+no+link+abaixo:%0D%0A%0D%0Ahttps%3A%2F%2Fwww.funildossonhos.com.br%3Futm_source%3Dgoogle-calendar%26utm_medium%3Devent%26utm_campaign%3Dfollowup-event&recur=RRULE:FREQ=WEEKLY&add=${inviteeEmail.replace(/@/, '%40')}
    `);
  };
  const title = manager
    ? `Defina o dia e hora do acompanhamento semanal com ${funnelUser.name}.`
    : 'Defina o dia e hora para acompanhar a evolução dos seus resultados semanalmente';
  const subtitle = manager
    ? `Quer motivar ainda mais seu time? Sugerimos marcar uma reunião semanal para acompanhamento da evolução de ${funnelUser.name}.`
    : 'Sugerimos reservar um momento da sua semana para acessar o Funil dos Sonhos e acompanhar sua evolução.';
  return (
    <QuestionStructure
      titleNumber={manager ? 2 : 3}
      title={title}
      subtitle={subtitle}
      onBackClick={onBackClick}
      onForwardClick={customOnForwardClick}
      isForwardDisabled={!checkbox && !isValidWeekDayAndTime}
      forwardButtonLabel={forwardButtonLabel}
    >
      <div>
        <label className={classes.inputLabel}>
          Dia da semana
        </label>
        <div className={classes.selectWrapper}>
          <select
            className={classes.select}
            placeholder="Segunda"
            value={weekDay}
            onChange={(e) => setWeekDay(e.target.value)}
          >
            <option value="">Selecione o dia</option>
            <option value="1">Segunda</option>
            <option value="2">Terça</option>
            <option value="3">Quarta</option>
            <option value="4">Quinta</option>
            <option value="5">Sexta</option>
          </select>
        </div>
        <label className={classes.inputLabel}>
          Horário
        </label>
        <div className={classes.inputWrapper}>
          <InputMask
            mask="99:99"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                className={classes.input}
                placeholder="00:00"
              />
            )}
          </InputMask>

        </div>
        <FormControlLabel
          classes={{ label: classes.label }}
          control={(
            <Checkbox
              checked={checkbox}
              onChange={onFollowupChange}
              color="primary"
            />
          )}
          label="Não definir acompanhamento"
        />
      </div>
    </QuestionStructure>
  );
}

export default withStyles(styles)(ConfigCalendar);
