import React, { useEffect, useState } from 'react';

import Login from '../../containers/Login';

function LoginPage() {
  const [inviteeId, setInviteeId] = useState('');

  useEffect(() => {
    setInviteeId(sessionStorage.getItem('inviteeId'));
  }, []);

  return (
    <Login inviteeId={inviteeId} />
  );
}

export default LoginPage;
