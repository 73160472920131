/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import UpdateCompetences from '../../containers/UpdateCompetences';
import { FunnelContext } from '../../contexts/FunnelContext';
import { UserContext } from '../../contexts/UserContext';
import Api from '../../services/api';

function UpdateCompetencesPage() {
  const [funnel, setFunnel] = useState();

  const { userState } = useContext(UserContext);
  const { funnelState } = useContext(FunnelContext);

  let { id } = useParams();

  useEffect(() => {
    if (userState && userState.id) {
      if (id) {
        Api
          .get(`/funnels/${id}`)
          .then(({ data }) => setFunnel(data));
      } else {
        setFunnel(funnelState);
      }
    }
  }, [userState]);

  if (funnel) {
    return (
      <UpdateCompetences
        funnelId={funnel.id}
        currentCompetences={funnel.competences}
      />
    );
  }

  return null;
}

export default UpdateCompetencesPage;
