/* eslint-disable no-unused-vars */

import axios from 'axios';

const request = axios.create({
  baseURL: "https://api.rd.services",
  params: {
  	api_key: "KgkiQkVHSkQlDOgEhuffwivJBCYNDcvOwcze"
  }
});

// name: "name of the contact",
// email: "email@email.com",
// traffic_source: "Google",
// traffic_medium: "cpc",
// traffic_campaign: "easter-50-off",
// available_for_mailing: true,
// legal_bases: [{
//   "category": "communications",
//   "type": "consent",
//   "status": "granted"
// }],
// "cf_my_custom_field": "custom field value"

const RDStation = {
	exec: function(eventName, properties = {}, value = 0){
		if (eventName === 'sign_up') RDStation.signUp(properties);
		else if (eventName === 'profile_updated') RDStation.profileUpdated(properties);
	},
	signUp: function (properties) {
		RDStation.send("funil-sonhos-sign-up", properties);
	},
	profileUpdated: function (properties) {
		let data = {
			email: properties.email,
			available_for_mailing: true,
	    cf_persona: properties.jobTitle,
	    cf_ramo_atividade: properties.businessField,
	    cf_tamanho_empresa: properties.teamSize,
	    personal_phone: properties.phone,
	    website: properties.website,
	    cf_necessidade: properties.companyNeeds,
	    cf_como_conheceu: properties.leadOrigin
		};

		if (properties.newsletter) {
			data.legal_bases = [{
				category: "communications",
				type: "consent",
				status: "granted"
			}];
			data.cf_newsletter = "1";
		}

		RDStation.send("funil-sonhos-profile-updated", data);
	},
	send: function(identifier, payload = {}) {
		let data = {
			event_type: "CONVERSION",
			event_family:"CDP",
			payload: {
				conversion_identifier: identifier,
				...payload
			}
		};

		request.post("/platform/conversions", data)
		.then(function (response) {
			console.log(response);
		})
		.catch(function (error) {
			console.log(error);
		});
	}
};

export default RDStation;