import { useState, useContext, useEffect } from 'react';
import { FunnelContext } from '../../contexts/FunnelContext';

export const useGoalState = () => {
  const { funnelState } = useContext(FunnelContext);

  const [goal, setGoal] = useState(funnelState.salesGoal);

  useEffect(() => {
    setGoal(funnelState.salesGoal || 0);
  }, [funnelState]);

  return [goal, setGoal];
};

export const useCommisionState = () => {
  const { funnelState } = useContext(FunnelContext);

  const [commission, setCommission] = useState(funnelState.commission);

  useEffect(() => {
    setCommission(funnelState.commission || 0);
  }, [funnelState]);

  return [commission, setCommission];
};
