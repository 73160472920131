import React, { useState, useContext, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { format, subMonths } from 'date-fns';

import Hidden from '@material-ui/core/Hidden';
import SectionIntro from '../../components/SectionIntro';
import SideBar from '../../components/SideBar';
import SurveyHeader from '../../components/SurveyHeader';
import { UserContext } from '../../contexts/UserContext';
import { FunnelContext } from '../../contexts/FunnelContext';
import Api from '../../services/api';
import { calculateActivityFrequency, calcCurrentFrequency, getAvgActivitiesPerMonth } from '../../utils';
import Analytics from '../../utils/analytics';

import Activities from './Activities';
import AvgSales from './AvgSales';
import { useAvgSalesState, useActivitiesState } from './hooks';
import styles from './styles';

function Activity({ classes }) {

  const activitiesLookbackMonths = 3;

  const { funnelState, funnelUpdate } = useContext(FunnelContext);
  const { userState } = useContext(UserContext);

  const history = useHistory();
  const historyState = history.location && history.location.state;
  const step = historyState && historyState.step;

  const [isAvgSalesIntegrated, setIsAvgSalesIntegrated] = useState(false);
  const [isActivitesIntegrated, setIsActivitesIntegrated] = useState(false);
  const [showIntro, setShowIntro] = useState(!step);
  const [questionIndex, setQuestionIndex] = useState(step || 0);
  const [avgSales, setAvgSales] = useAvgSalesState();
  const [activities, setActivities] = useActivitiesState();

  useEffect(() => {
    let stepName = null;

    if (showIntro) stepName = 'Activity Intro';
    else if (questionIndex === 0) stepName = 'Activity Avg. Sales';
    else if (questionIndex === 1) stepName = 'Activity Activities';

    Analytics.log("onboarding_step_viewed", {
      step_name: stepName
    });
  }, [showIntro, questionIndex]);

  const handleAvgSales = (e) => {
    const { value } = e.target;
    const newavgSales = value.replace(/\./g, '');
    if (newavgSales.match(/^[0-9]*$/)) {
      setAvgSales(Number(newavgSales));
      setIsAvgSalesIntegrated(false);
    }
  };

  const handleFrequencyChange = (activityName, period, activityCount) => {
    let avgActivitiesPerMonth = getAvgActivitiesPerMonth(period, activityCount);

    const frequency = calculateActivityFrequency({
      avgActivitiesPerMonth: avgActivitiesPerMonth,
      avgSalesPerMonth: avgSales,
      numberOfSales: funnelState.numberOfSales,
      soldQuantity: funnelState.soldQuantity,
      endDate: funnelState.dreamDate,
      period: period
    });

    const newActivities = activities.map((activity) => {
      if (activity.activity === activityName) {
        return {
          ...activity,
          currentFrequency: activityCount,
          period,
          frequency,
        };
      }
      return activity;
    });

    setActivities(newActivities);
  };

  const handleBackClick = () => setQuestionIndex(questionIndex - 1);
  const handleForwardClick = () => setQuestionIndex(questionIndex + 1);

  const goToNextSection = () => {
    const newFunnelState = funnelUpdate({
      type: 'UPDATE',
      avgSales,
      activitiesFrequency: activities,
    });
    Api
      .patch(
        `/funnels/${funnelState.id}`,
        newFunnelState,
      )
      .catch(() => alert('Houve um erro tente novamente mais tarde'));
    history.push('/competencias');
  };

  const handleAvgSalesIntegration = () => {
    Api
      .get(
        `/agendor/deals`,
        {
          params: {
            startDate: `${format(subMonths(new Date(), 12), 'yyyy/MM/dd')} 00:00:00`,
            endDate: `${format(new Date(), 'yyyy/MM/dd')} 23:59:59`,
          }
        },
      )
      .then((response) => {
        const newAvgSales = parseInt(response.data.count / 12);
        setAvgSales(newAvgSales);
        setIsAvgSalesIntegrated(true);
      })
      .catch(() => alert('Houve um erro tente novamente mais tarde'));
  };

  const handleActivitesIntegration = () => {
    Api
    .get(
      `/agendor/tasks`,
      {
        params: {
          startDate: `${format(subMonths(new Date(), activitiesLookbackMonths), 'yyyy/MM/dd')} 00:00:00`,
          endDate: `${format(new Date(), 'yyyy/MM/dd')} 23:59:59`,
        }
      },
    )
    .then((response) => {
      const newActivities = activities.map((activity) => {
        const agendorActivity = response.data.find(({ name }) => (
          name === activity.activity
        )) || { count: 0 };

        const {period, currentFrequency} = calcCurrentFrequency({
          lookbackMonths: activitiesLookbackMonths,
          count: agendorActivity.count
        });

        const frequency = calculateActivityFrequency({
          avgActivitiesPerMonth: agendorActivity.count / activitiesLookbackMonths,
          avgSalesPerMonth: avgSales,
          numberOfSales: funnelState.numberOfSales,
          soldQuantity: funnelState.soldQuantity,
          endDate: funnelState.dreamDate,
          period: period
        });

        return {
          ...activity,
          currentFrequency,
          frequency,
          period
        };
      });

      setActivities(newActivities);
      setIsActivitesIntegrated(true);
    })
    .catch(() => alert('Houve um erro tente novamente mais tarde'));
  };

  const questionsList = {
    0: (
      <AvgSales
        avgSales={avgSales}
        onBackClick={() => setShowIntro(true)}
        onForwardClick={handleForwardClick}
        onChange={handleAvgSales}
        hasAgendorToken={Boolean(userState.agendorToken)}
        onActiveClick={() => setIsAvgSalesIntegrated(false)}
        onInactiveClick={handleAvgSalesIntegration}
        isIntegrationActive={isAvgSalesIntegrated}
      />
    ),
    1: (
      <Activities
        activities={activities}
        onBackClick={handleBackClick}
        onForwardClick={goToNextSection}
        onFrequencyChange={handleFrequencyChange}
        onPeriodChange={handleFrequencyChange}
        hasAgendorToken={Boolean(userState.agendorToken)}
        onActiveClick={() => setIsActivitesIntegrated(false)}
        onInactiveClick={handleActivitesIntegration}
        isIntegrationActive={isActivitesIntegrated}
      />
    ),
  };

  if (showIntro) {
    return (
      <SectionIntro
        header="Cadência de atividades"
        title="Vamos descobrir sua cadência de vendas"
        subtitle="Mais importante do que saber quanto vender, é entender o que fazer para chegar lá. Veja no vídeo abaixo a importância de ter uma cadência de atividades bem definida."
        videoSrc="https://www.youtube.com/embed/742FxqKTIt0?autoplay=1&rel=0&showinfo=0"
        onClick={() => setShowIntro(false)}
        onBackClick={() => history.push('/ticket-medio', { step: true })}
      />
    );
  }

  return (
    <>
      <SideBar />
      <main className={classes.main}>
        <Hidden smDown>
          <SurveyHeader
            title="Início"
            subTitle="Monte seu funil"
          />
        </Hidden>
        { questionsList[questionIndex] }
      </main>
    </>
  );
}

export default withStyles(styles)(Activity);
