import React, { useState, useContext, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import Hidden from '@material-ui/core/Hidden';
import SectionIntro from '../../components/SectionIntro';
import SideBar from '../../components/SideBar';
import SurveyHeader from '../../components/SurveyHeader';

import { FunnelContext } from '../../contexts/FunnelContext';
import { UserContext } from '../../contexts/UserContext';
import Api from '../../services/api';
import SelectCompetences from './SelectCompetences';
import InviteManager from './InviteManager';
import ConfigCalendar from './ConfigCalendar';
import styles from './styles';

import Analytics from '../../utils/analytics';

function Competences({ classes }) {
  const history = useHistory();
  const { funnelState, funnelDispatch } = useContext(FunnelContext);
  const { userState } = useContext(UserContext);

  const [isManagerInvite, setIsManagerInvite] = useState(false);
  const [funnelUser, setFunnelUser] = useState({});
  const [showIntro, setShowIntro] = useState(true);
  const [questionIndex, setQuestionIndex] = useState(0);

  useEffect(() => {
    const inviteeId = userState.lastViewedTeamMemberId;
    setIsManagerInvite(Boolean(inviteeId));
    if (inviteeId) {
      setQuestionIndex(1);
      Api.get(`/users/${inviteeId}`).then(({ data }) => setFunnelUser(data));
    }
  }, [userState.lastViewedTeamMemberId]);

  useEffect(() => {
    let stepName = null;

    if (showIntro) stepName = 'Competences Intro';
    else if (questionIndex === 0) stepName = 'Competences Invite Manager';
    else if (questionIndex === 1) stepName = 'Competences Select';
    else if (questionIndex === 2) stepName = 'Competences Follow Up';

    Analytics.log("onboarding_step_viewed", {
      step_name: stepName
    });
  }, [showIntro, questionIndex]);

  if (showIntro) {
    const title = isManagerInvite
      ? 'Competências de vendedores de alta performance'
      : 'Competências de alta performance';

    const subtitle = `Disciplina, comunicação, capacidade investigativa, orientação a resultados, tenacidade, agilidade e automotivação. Veja como desenvolver estas competências${isManagerInvite ? ' em seu time.' : '.'}`;
    return (
      <SectionIntro
        header="Competências"
        title={title}
        subtitle={subtitle}
        videoSrc="https://www.youtube.com/embed/PxrP8LwwMZc?autoplay=1&rel=0&showinfo=0"
        onClick={() => setShowIntro(false)}
        onBackClick={() => history.push('/atividades', { step: 1 })}
      />
    );
  }

  const handleCompetenceClick = (competenceTitle) => {
    if (funnelState.competences.includes(competenceTitle)) {
      funnelDispatch({
        type: 'UPDATE',
        competences: funnelState.competences.filter(selectedCompetence => {
          return selectedCompetence !== competenceTitle
        })
      });
    } else {
      funnelDispatch({
        type: 'UPDATE',
        competences: [
          ...funnelState.competences,
          competenceTitle
        ],
      });
    }
  };

  const competencesForwardClick = () => {
    Api
      .patch(`/funnels/${funnelState.id}`, { competences: funnelState.competences })
      .catch(() => alert('Houve um erro tente novamente mais tarde'));

    if (isManagerInvite) {
      history.push(`/funil/${funnelState.id}`);
    } else {
      history.push('/funil-completo');
    }
  };

  const inviteForwaredClick = (isEmailSent) => {
    if (isEmailSent) {
      history.push('/funil-completo');
    } else {
      Analytics.log("manager_invitation_skipped");
      setQuestionIndex(1);
    }
  };

  const questionsList = {
    0: (
      <InviteManager
        onBackClick={() => setShowIntro(true)}
        onForwardClick={inviteForwaredClick}
        managerEmail={userState.managerEmail}
      />
    ),
    1: (
      <SelectCompetences
        selectedCompetences={funnelState.competences}
        onBackClick={() => (isManagerInvite ? setShowIntro(true) : setQuestionIndex(0))}
        onClick={handleCompetenceClick}
        onForwardClick={() => setQuestionIndex(2)}
        funnelUser={funnelUser}
        manager={isManagerInvite}
      />
    ),
    2: (
      <ConfigCalendar
        selectedCompetences={funnelState.competences}
        onBackClick={() => setQuestionIndex(1)}
        onForwardClick={competencesForwardClick}
        manager={isManagerInvite}
        funnelUser={funnelUser}
        inviteeEmail={
          isManagerInvite
            ? funnelUser.email
            : userState.managerEmail
        }
      />
    ),
  };

  return (
    <>
      <SideBar />
      <main className={classes.main}>
        <Hidden smDown>
          <SurveyHeader
            title="Início"
            subTitle="Monte seu funil"
          />
        </Hidden>
        { questionsList[questionIndex] }
      </main>
    </>
  );
}

export default withStyles(styles)(Competences);
