export default (theme) => ({
  wrapper: {
    display: 'flex',
    margin: theme.spacing(6, 0, 2),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      margin: 0,
      padding: theme.spacing(0),
    },
  },
  label: {
    color: theme.palette.grey.main,
    fontSize: '1.25rem',
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(3, 0, 0),
    height: theme.spacing(6.5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3.5, 0, 0),
      fontSize: '1rem',
      height: 'initial',
      width: '90%',
    },
  },
  labelActive: {
    color: theme.palette.text.main,
  },
  box: {
    width: theme.spacing(50),
    height: theme.spacing(32),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(16.5),
      width: '100%',
    },
  },
  sourceBox: {
    borderRadius: theme.spacing(1, 0, 0, 1),
    backgroundColor: theme.palette.screenGrey.main,
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      borderRadius: theme.spacing(1, 1, 0, 0),
      padding: 0,
    },
  },
  targetBox: {
    borderRadius: theme.spacing(0, 1, 1, 0),
    backgroundColor: '#fff',
    paddingLeft: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      borderRadius: theme.spacing(0, 0, 1, 1),
      padding: 0,
    },
  },
  arrowSquare: {
    position: 'absolute',
    top: 'calc(50% - 47px)',
    left: 'calc(50% - 40px)',
    fontWeight: 'bold',
    backgroundColor: '#fff',
    borderRadius: theme.spacing(4),
    height: theme.spacing(10),
    width: theme.spacing(10),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      top: 'calc(50% - 28px)',
      left: 'calc(50% - 28px)',
      height: theme.spacing(7),
      width: theme.spacing(7),
      borderRadius: theme.spacing(2.5),
    },
  },
  arrowSquareActive: {
    '&::before': {
      content: "''",
      height: theme.spacing(6),
      width: theme.spacing(6),
      left: theme.spacing(2),
      top: `calc(50% - ${theme.spacing(3)}px)`,
      borderRadius: theme.spacing(2),
      backgroundColor: theme.palette.success.main,
      position: 'absolute',
      [theme.breakpoints.down('sm')]: {
        top: 'calc(50% - 20px)',
        left: 'calc(50% - 20px)',
        height: theme.spacing(5),
        width: theme.spacing(5),
      },
    },
  },
  arrowIcon: {
    fill: '#AB96E6',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      transform: 'rotate(90deg)',
    },
  },
  arrowIconActive: {
    fill: theme.palette.primary.main,
  },
  input: {
    color: 'inherit',
    caretColor: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '3rem',
    maxWidth: 300,
    backgroundColor: 'transparent',
    border: 'none',
    padding: theme.spacing(5, 0),
    fontFamily: 'inherit',
    '&::placeholder': {
      color: '#AB96E6',
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      padding: theme.spacing(1, 0),
    },
  },
  sourceInput: {
    color: theme.palette.primary.main,
    position: 'relative',
    '&::after': {
      content: "'%'",
      position: 'absolute',
      top: 50,
      left: -20,
      fontWeight: 'bold',
      color: '#AB96E6',
      [theme.breakpoints.down('sm')]: {
        top: 22,
      },
    },
  },
  sourceInputActive: {
    position: 'relative',
    '&::after': {
      color: theme.palette.primary.main,
    },
  },
  targetInput: {
    color: theme.palette.primary.main,
    position: 'relative',
    '&::after': {
      content: "'R$'",
      position: 'absolute',
      top: 50,
      left: -24,
      fontWeight: 'bold',
      color: '#AB96E6',
      [theme.breakpoints.down('sm')]: {
        top: 22,
      },
    },
  },
  targetInputActive: {
    position: 'relative',
    '&::after': {
      color: theme.palette.primary.main,
    },
  },
  targetInputFill: {
    color: theme.palette.success.main,
    position: 'relative',
    '&::after': {
      color: theme.palette.success.main,
    },
  },
});
