import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import SectionHeader from '../../components/SectionHeader';
import Title from '../../components/Title';
import ContainedInput from '../../components/ContainedInput';
import Select from '../../components/Select';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import { UserContext } from '../../contexts/UserContext';

import Api from '../../services/api';
import {
  roleOptions,
  fieldOptions,
  teamSizeOptions,
  handleErrors,
} from '../../utils';
import IntegrationModal from '../../components/IntegrationModal';
import AgendorSignupModal from '../../components/AgendorSignupModal';

import styles from './styles';

import { getLink } from '../../utils/links';
import Analytics from '../../utils/analytics';

function Profile({ classes, inviteeId }) {
  const history = useHistory();
  const { userState, userDispatch } = useContext(UserContext);

  // console.log(userState);

  const [isLoading, setIsLoading] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [isIntegrationOpen, setIsIntegrationOpen] = useState(false);
  const [isIntegrationActive, setIsIntegrationActive] = useState(false);
  const [agendorToken, setAgendorToken] = useState('');
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isManagerInvite] = useState(Boolean(userState.lastViewedTeamMemberId || inviteeId));

  const [form, setForm] = useState({
    jobTitle: userState.jobTitle,
    businessField: userState.businessField,
    teamSize: userState.teamSize,
    phone: userState.phone,
    website: userState.website,
    leadOrigin: userState.leadOrigin,
    newsletter: userState.newsletter
  });

  useEffect(() => {
    Analytics.log("profile_update_visited");
  }, []);

  const handleClick = () => {
    const token = (isTokenValid && isIntegrationActive) ? agendorToken : '';
    setIsLoading(true);
    Api
      .post('/profiles/me', {
        ...form,
        agendorToken: token
      })
      .then(() => {
        Analytics.log("profile_updated", {
          ...form,
          email: userState.email,
          is_manager: isManagerInvite
        });
        Analytics.setUserProperties({
          is_manager: isManagerInvite,
          team_size: form.teamSize,
          business_field: form.businessField,
          job_title: form.jobTitle
        });
        setIsLoading(false);
        userDispatch({ type: 'UPDATE', agendorToken: token });
        if (inviteeId) {
          history.push(`/convite/${inviteeId}`, { isManager: true });
        } else {
          history.push('/sonho');
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrors(error);
      });
  };

  const handleChange = (key, value) => setForm({ ...form, [key]: value });

  const requiredFields = [
    'jobTitle',
    'businessField',
    'teamSize',
    'phone',
    'website',
    'leadOrigin'
  ];
  const filledFields = requiredFields.filter(key => { return form[key] !== ''; });
  const isDisabled = filledFields.length !== requiredFields.length;

  return (
    <>
      <SectionHeader
        title="Início"
        subTitle="Monte seu funil"
        showLogin={false}
        showSignup={false}
      />
      <main className={classes.main}>
        <Title
          title="Conte-nos sobre você"
          subtitle="Agora precisamos saber um pouco mais sobre você para personalizar sua experiência."
        />
        <form className={classes.form}>
          <p className={classes.label}>Cargo *</p>
          <Select
            options={roleOptions}
            value={form.jobTitle}
            onChange={(e) => handleChange('jobTitle', e.target.value)}
          />
          <p className={classes.label}>Ramo de atividade *</p>
          <Select
            options={fieldOptions}
            value={form.businessField}
            onChange={(e) => handleChange('businessField', e.target.value)}
          />
          <p className={classes.label}>Qtd. de vendedores na equipe de vendas *</p>
          <Select
            options={teamSizeOptions}
            value={form.teamSize}
            onChange={(e) => handleChange('teamSize', e.target.value)}
          />
          <div className={classes.inputSpace}>
            <ContainedInput
              value={form.phone}
              onChange={(e) => handleChange('phone', e.target.value)}
              label="Celular/Whatsapp *"
              mask="(99) 99999-9999"
              type="tel"
            />
          </div>
          <div className={classes.inputSpace}>
            <ContainedInput
              value={form.website}
              onChange={(e) => handleChange('website', e.target.value)}
              label="Website *"
            />
          </div>
          <div className={classes.inputSpace}>
            <ContainedInput
              value={form.leadOrigin}
              onChange={(e) => handleChange('leadOrigin', e.target.value)}
              label="Como conheceu o Funil dos Sonhos *"
            />
          </div>
          <div className={classes.inputSpace}>
            <FormControlLabel
              classes={{ label: classes.checkboxLabel }}
              control={(
                <Checkbox
                  checked={form.newsletter}
                  color="primary"
                  onChange={(e) => handleChange('newsletter', !form.newsletter)}
                />
              )}
              label="Receber dicas de vendas do Agendor"
            />
          </div>
          {
            !isManagerInvite && (
          <div className={classes.agendorWrapper}>
            <div className={classes.checkboxWrapper} onClick={() => {
              if (isTokenValid && isIntegrationActive) {
                setIsIntegrationActive(false);
              } else {
                setIsIntegrationOpen(true);
              }
            }}>
              <FormControlLabel
                classes={{ label: classes.checkboxLabel }}
                control={(
                  <Checkbox
                    checked={isTokenValid && isIntegrationActive}
                    color="primary"
                  />
                )}
                label="Conectar com o Agendor"
              />
              <Tooltip
                title="Ao conectar com o Agendor todos os dados de ticket-médio, total vendido e atividades serão automatizados em seu Funil dos Sonhos."
                placement="top"
                classes={{
                  arrow: classes.tooltipArrow,
                  tooltip: classes.tooltip,
                }}
                arrow
              >
                <span className={classes.inform}>i</span>
              </Tooltip>
            </div>
            <div className={classes.agendor} onClick={() => setIsSignupOpen(true)}>
              <Icon name="agendorLogo" />
              <div>
                <p className={classes.knowsAgendorText}>
                  Ainda não conhece o Agendor?
                </p>
                <p className={classes.testAgendorText}>
                  Comece um teste grátis
                </p>
              </div>
            </div>
          </div>
            )
          }
          <Button
            fullWidth
            onClick={handleClick}
            disabled={isDisabled || isLoading}
            classes={{
              root: classes.submitButton
            }}
          >
            {
              isLoading
                ? <CircularProgress />
                : 'Atualizar perfil'
            }
          </Button>
        </form>
      </main>
      {
        isSignupOpen && (
          <AgendorSignupModal
            open={isSignupOpen}
            onClose={() => {
              setIsSignupOpen(false);
            }}
            onConfirm={() => {
              Analytics.log("agendor_sign_up_clicked", {
                location: 'Profile Update'
              });
              window.open(getLink({link: 'AGENDOR_REGISTRATION', utmContent: 'profile-page'}));
              setIsSignupOpen(false);
              setIsIntegrationOpen(true);
            }}
          />
        )
      }
      {
        isIntegrationOpen && (
          <IntegrationModal
            className={classes.tokenInput}
            open={isIntegrationOpen}
            onClose={() => setIsIntegrationOpen(false)}
            token={agendorToken}
            onTokenChange={(e) => setAgendorToken(e.target.value)}
            signupClick={() => {
              setIsSignupOpen(true);
              setIsIntegrationOpen(false);
            }}
            onConfirm={() => {
              Api
                .get('/agendor/verify', { params: { token: agendorToken }})
                .then(() => {
                  Analytics.log("integration_activated", {
                    location: 'Profile Update'
                  });
                  setIsTokenValid(true);
                  setIsIntegrationActive(true);
                  setIsIntegrationOpen(false);
                })
                .catch(() => {
                  setIsTokenValid(false);
                  alert('Token inválido!')
                });
            }}
          />
        )
      }
    </>
  );
}

export default withStyles(styles)(Profile);
