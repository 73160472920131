import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import styles from './styles.js';

function Progress({ classes, value }) {
	const progress = value > 100 ? 100 : value;
  return <LinearProgress classes={classes} value={progress} variant="determinate" />;
}

export default withStyles(styles)(Progress);
