import React, { useState, useContext, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import { FunnelContext } from '../../contexts/FunnelContext';

import SectionHeader from '../../components/SectionHeader';
import Auth from '../../components/Auth';

import Api from '../../services/api';
import { auth } from '../../services/firebase';

import { handleErrors, getFirstUncompletedFunnelStep } from '../../utils';
import Analytics from '../../utils/analytics';

function Login({ inviteeId }) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { userDispatch } = useContext(UserContext);
  const { funnelDispatch } = useContext(FunnelContext);

  useEffect(() => {
    Analytics.log("login_visited");
  }, []);

  const handleLoginClick = ({ email, password }) => {

    setIsLoading(true);

    auth
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        if (user) {
          return Api.get('/users/me')
            .then(userResp => {
              const isManager = Boolean(userResp.data.lastViewedTeamMemberId);
              inviteeId = inviteeId || userResp.data.lastViewedTeamMemberId
              const funnelId = isManager ? inviteeId : userResp.data.id;

              Analytics.log("login", {
                is_manager: isManager
              });

              return Api.get('/funnels/' + funnelId)
                .then(funnelResp => {
                  if (isManager) {
                    userResp.data.lastViewedTeamMemberId = inviteeId;
                    return Api.patch('/profiles/me', {
                        lastViewedTeamMemberId: inviteeId,
                      })
                      .then(result => {
                        return funnelResp;
                      });
                  } else {
                    return funnelResp;
                  }
                })
                .then(funnelResp => {
                  funnelDispatch({ type: 'UPDATE', ...funnelResp.data });
                  userDispatch({ type: 'UPDATE', ...userResp.data });

                  setIsLoading(false);

                  if (isManager) {
                    history.push(`/convite/${inviteeId}`, { isManager: true });
                  } else {
                    const step = getFirstUncompletedFunnelStep(funnelResp.data);
                    history.push(step, { isLoading: true });
                  }

                });
            });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.code === 'auth/user-not-found') {
          const msg = 'Não foi encontrado uma conta com o email ' + email + ', deseja cadastrar?';
          if (window.confirm(msg)) {
            history.push("/cadastrar", { email: email });
          }
        } else {
          handleErrors(error);
        }
      });
  };

  return (
    <section>
      <SectionHeader
        title="Início"
        subTitle="Monte seu funil"
        showSignup={true}
        showLogin={false}
        showSignOut={false}
      />
      <Auth
        title="Entrar"
        subtitle="Preencha seu email e senha abaixo para acessar sua conta."
        passCaption="(sua senha possui pelo menos 6 caracteres)"
        buttonText="Entrar"
        onClick={handleLoginClick}
        isLoading={isLoading}
      />
    </section>
  );
}

export default Login;
