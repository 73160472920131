export default (theme) => ({
  root: {
    width: '100%',
    height: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  colorPrimary: {
    backgroundColor: '#EDEBF3 ',
  },
  bar: {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
});
