/* eslint-disable react-hooks/exhaustive-deps */

import clsx from 'clsx';
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import ptLocale from 'date-fns/locale/pt';

import Icon from '../../../components/Icon';

import styles from './styles';

function SourceTarget({
  classes,
  sourceValue,
  onSourceChange,
  targetValue,
  onTargetChange,
  automaticFill,
  targetDate,
}) {
  const [isSourceActive, setIsSourceActive] = useState(false);
  const [isTargetActive, setIsTargetActive] = useState(false);

  const formatter = new Intl.NumberFormat('pt-BR');

  const handleSourceChange = (e) => {
    const { value } = e.target;
    let price = value.replace(/,/g, '.');
    if (price.match(/^[0-9]{0,3}(.(\d{1,2})?)?$/) && Number(price) <= 100) {
      onSourceChange(price);
    }
  };
  const handleTargetChange = (e) => {
    const { value } = e.target;
    const price = value.replace(/\./g, '');
    if (price.match(/^[0-9]*$/)) {
      onTargetChange(Number(price));
    }
  };

  const sourceLabelClasses = clsx(classes.label, {
    [classes.labelActive]: isSourceActive,
  });
  const sourceClasses = clsx(classes.sourceInput, {
    [classes.sourceInputActive]: isSourceActive,
  });
  const targetLabelClasses = clsx(classes.label, {
    [classes.labelActive]: isTargetActive || automaticFill,
  });
  const targetClasses = clsx(classes.targetInput, {
    [classes.targetInputActive]: isTargetActive || automaticFill,
    [classes.targetInputFill]: automaticFill,
  });

  const formatedTargetValue = formatter.format(targetValue);
  const formatedDate = format(
    new Date(targetDate || null),
    'd, LLL. yyyy',
    { locale: ptLocale },
  );

  const sourceInputLength = String(sourceValue).length <= 0 ? 2 : String(sourceValue).length;
  const targetInputLength = String(targetValue).length <= 1 ? 4 : String(targetValue).length;

  return (
    <div className={classes.wrapper}>
      <div className={clsx(classes.sourceBox, classes.box)}>
        <label className={sourceLabelClasses}>Minha comissão é ...</label>
        <span className={sourceClasses}>
          <input
            style={{ width: sourceInputLength + 'ch' }}
            className={classes.input}
            value={sourceValue}
            onChange={handleSourceChange}
            onFocus={() => setIsSourceActive(true)}
            placeholder="10"
          />
        </span>
      </div>
      <Arrow classes={classes} active={automaticFill} />
      <div className={clsx(classes.targetBox, classes.box)}>
        <label className={targetLabelClasses}>
          Seu volume de vendas acumuladas até
          {' '}
          {formatedDate}
          {' '}
          ...
        </label>
        <span className={targetClasses}>
          <input
            style={{ width: targetInputLength + 'ch' }}
            className={classes.input}
            value={formatedTargetValue === '0' ? '' : formatedTargetValue}
            onChange={handleTargetChange}
            onFocus={() => setIsTargetActive(true)}
            placeholder="1.000"
          />
        </span>
      </div>
    </div>
  );
}

function Arrow({ classes, active = false }) {
  const squareClasses = clsx(classes.arrowSquare, {
    [classes.arrowSquareActive]: active,
  });
  const iconClasses = clsx(classes.arrowIcon, {
    [classes.arrowIconActive]: active,
  });
  return (
    <div className={squareClasses}>
      <Icon name="rightArrow" className={iconClasses} />
    </div>
  );
}

export default withStyles(styles)(SourceTarget);
