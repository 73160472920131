import React from 'react';

import QuestionStructure from '../../../components/QuestionStructure';
import Input from '../../../components/Input';

function DreamPrice({
  price,
  onPriceChange,
  onBackClick,
  onForwardClick,
}) {
  const handleChange = (e) => onPriceChange(e.target.value);

  const formatter = new Intl.NumberFormat('pt-BR');
  const formatedPrice = formatter.format(price);
  const value = formatedPrice === '0' ? '' : formatedPrice;
  return (
    <QuestionStructure
      titleNumber={3}
      title="Quanto você precisa para realizar seu sonho?"
      onBackClick={onBackClick}
      onForwardClick={onForwardClick}
      isForwardDisabled={!price}
    >
      <Input
        label="R$"
        value={value}
        onChange={handleChange}
        placeholder="1.000"
      />
    </QuestionStructure>
  );
}

export default DreamPrice;
