import React from 'react';

import QuestionStructure from '../../../components/QuestionStructure';
import DreamSelector from '../DreamSelector';

function DreamType({
  type,
  onChange,
  onBackClick,
  onForwardClick,
}) {
  const handleDreamClick = (dreamType) => onChange('type', dreamType);
  return (
    <QuestionStructure
      titleNumber={1}
      title="Meu sonho é..."
      onBackClick={onBackClick}
      onForwardClick={onForwardClick}
      isForwardDisabled={!type}
    >
      <DreamSelector
        onClick={handleDreamClick}
        selectedValue={type}
      />
    </QuestionStructure>
  );
}

export default DreamType;
