export default (theme) => ({
  card: {
    border: `4px solid ${theme.palette.screenGrey.light}`,
    borderRadius: theme.spacing(1.5),
    width: theme.spacing(80),
    margin: '0 auto',
    position: 'relative',
    padding: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      width: '80vw',
      padding: theme.spacing(2),
      boxSizing: 'border-box',
    },
    '&:hover': {
      backgroundColor: theme.palette.screenGrey.light,
    },
  },
  title: {
    fontWeight: 'bold',
    letterSpacing: '0.04em',
    color: '#0C0030',
    marginTop: 0,
    textTransform: 'uppercase',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    '@media print': {
      marginBottom: '0px'
    }
  },
  updatedAt: {
    cursor: 'pointer',
    marginLeft: theme.spacing(10),
    display: 'inline-block',
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    textAlign: 'right',
    color: '#A49AB8',
    alignSelf: 'end',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    '@media print': {
      display: 'none'
    }
  },
  progressHeader: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    color: '#A49AB8',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  closedSales: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    lineHeight: '1.625rem',
    color: theme.palette.primary.main,
  },
  totalSales: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    lineHeight: '1.625rem',
    color: '#2F291F',
  },
  closedSalesLegend: {
    marginTop: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    color: '#A49AB8',
    paddingLeft: theme.spacing(2),
    display: 'inline-block',
    marginRight: theme.spacing(5),
    position: 'relative',
    '&:before': {
      content: '""',
      top: 7,
      left: 0,
      position: 'absolute',
      width: 6,
      height: 6,
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
  },
  totalSalesLegend: {
    marginTop: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    color: '#A49AB8',
    paddingLeft: theme.spacing(2),
    display: 'inline-block',
    position: 'relative',
    '&:before': {
      content: '""',
      top: 7,
      left: 0,
      position: 'absolute',
      width: 6,
      height: 6,
      borderRadius: theme.spacing(1),
      backgroundColor: '#2F291F',
    },
  },
  legend: {
    margin: theme.spacing(4, 0, 0),
    fontWeight: 500,
    fontSize: '0.875rem',
    '@media print': {
      display: 'none'
    }
  },
  legendHighlight: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
});
