import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import Loading from '../../pages/Loading';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { userState } = useContext(UserContext);
  const userStateNotSet = (!Object.keys(userState).length);
  const isLoggedUser = (userState && userState.id);

  return (
    <Route {...rest} render={(routeProps) => {
      if (userStateNotSet) {
        return <Loading />;
      } else if (userState && userState.id === null) {
        return <Redirect to="/" />;
      } else if (isLoggedUser) {
        return <Component {...routeProps} />;
      }
    }}/>
  );
};

export default PrivateRoute;
