export default (theme) => ({
  printButton: {
    backgroundColor: '#5C57FF',
    color: '#FFFFFF',
    padding: '12px 24px',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: theme.spacing(10),
    border: 'none',
    width: 'fit-content',
    position: 'relative',
    cursor: 'pointer',
    marginRight: '10px',
    '&:focus': {
      outline: 'none',
    }
  },
  printIcon: {
    fill: '#fff',
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    marginRight: '5px'
  }
});