import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import Api from '../../services/api';

import IntegrationModal from '../IntegrationModal';
import AgendorSignupModal from '../AgendorSignupModal';
import Icon from '../Icon';

import { UserContext } from '../../contexts/UserContext';

import styles from './styles';

import { getLink } from '../../utils/links';
import Analytics from '../../utils/analytics';

function AgendorIntegrationButton({
  classes,
  isActive = false,
  onActiveClick,
  onInactiveClick,
  inactiveButtonLabel = 'Preencher com dados do Agendor',
  activeButtonLabel = 'Preenchido com dados do Agendor',
  location
}) {

  const { userState, userDispatch } = useContext(UserContext);

  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [isIntegrationOpen, setIsIntegrationOpen] = useState(false);
  const [agendorToken, setAgendorToken] = useState(userState.agendorToken);

  const handleClick = () => {
    if (isActive) {
      return onActiveClick();
    } else {
      if (Boolean(userState.agendorToken)) {
        return onInactiveClick();
      } else {
        setIsIntegrationOpen(true);
      }
    }
  };

  const onIntegrationModalConfirm = () => {
    Api
      .get('/agendor/verify', { params: { token: agendorToken }})
      .then(() => {
        Analytics.log("integration_activated", {
          location: location
        });
        setIsIntegrationOpen(false);
        return Api.patch('/profiles/me', {
          agendorToken: agendorToken
        })
      })
      .then(onInactiveClick)
      .then(() => {
        userDispatch({ type: 'UPDATE', agendorToken: agendorToken });
      })
      .catch(() => {
        alert('Token inválido!')
      });
  };

  const buttonClasses = clsx(classes.button, {
    [classes.activeButton]: isActive,
    [classes.inactiveButton]: !isActive,
  });
  const iconClasses = clsx(classes.icon, {
    [classes.activeIcon]: isActive,
  });

  return (
    <section>
      <button className={buttonClasses} onClick={handleClick}>
        <Icon name="agendorIntegration" className={iconClasses} />
        {
          isActive
            ? activeButtonLabel
            : inactiveButtonLabel
        }
        { isActive && <span className={classes.closeButton}>&#10005;</span> }
      </button>
      {
        isSignupOpen && (
          <AgendorSignupModal
            open={isSignupOpen}
            onClose={() => {
              setIsSignupOpen(false);
            }}
            onConfirm={() => {
              Analytics.log("agendor_sign_up_clicked", {
                location: location
              });
              window.open(getLink({link:'AGENDOR_REGISTRATION', utmContent: 'agendor-signup-modal'}));
              setIsSignupOpen(false);
              setIsIntegrationOpen(true);
            }}
          />
        )
      }
      {
        isIntegrationOpen && (
          <IntegrationModal
            className={classes.tokenInput}
            open={isIntegrationOpen}
            onClose={() => setIsIntegrationOpen(false)}
            token={agendorToken}
            onTokenChange={(e) => setAgendorToken(e.target.value)}
            signupClick={() => {
              setIsSignupOpen(true);
              setIsIntegrationOpen(false);
            }}
            onConfirm={onIntegrationModalConfirm}
          />
        )
      }
    </section>
  );
}

export default withStyles(styles)(AgendorIntegrationButton);
