export default (theme) => ({
  tabsWrapper: {
    border: `2px solid ${theme.palette.screenGrey.main}`,
    borderRadius: theme.spacing(10),
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
      width: '90vw',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  tab: {
    fontSize: '1rem',
    fontWeight: 700,
    borderRadius: theme.spacing(10),
    padding: theme.spacing(2, 4),
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
    border: 'none',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 3),
      width: '100%',
      justifyContent: 'center',
    },
  },
  tabActive: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  iconButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: 24,
      marginRight: theme.spacing(1),
    },
  },
});
