import arrow from '../../../assets/vectors/arrow.svg';

export default (theme) => ({
  label: {
    color: theme.palette.text.main,
  },
  inputWrapper: {
    marginBottom: theme.spacing(2),
  },
  inputLabel: {
    display: 'block',
    color: '#3D414C',
    marginBottom: theme.spacing(1.5),
    fontWeight: 'bold',
  },
  input: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '1.125rem',
    fontFamily: 'inherit',
    width: theme.spacing(50),
    boxSizing: 'border-box',
    padding: '15px 24px',
    caretColor: theme.palette.primary.main,
    border: '1px solid #DCD8E5',
    borderRadius: theme.spacing(1),
    '&::placeholder': {
      color: '#AB96E6',
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      width: 'calc(90vw - 96px)',
      padding: theme.spacing(1.5, 2),
    },
  },
  selectWrapper: {
    marginBottom: theme.spacing(2),
    position: 'relative',
    display: 'inline-block',
    '&:after': {
      content: "''",
      position: 'absolute',
      top: theme.spacing(2.75),
      right: theme.spacing(2.5),
      backgroundImage: `url(${arrow})`,
      height: theme.spacing(1.5),
      width: theme.spacing(1),
      transform: 'rotate(-90deg)',
    },
  },
  select: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '1.125rem',
    fontFamily: 'inherit',
    width: theme.spacing(50),
    boxSizing: 'border-box',
    padding: '15px 24px',
    border: '1px solid #DCD8E5',
    borderRadius: theme.spacing(1),
    appearance: 'none',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      width: 'calc(90vw - 96px)',
      padding: theme.spacing(1.5, 2),
    },
  },

  emailSentWrapper: {
    background: '#5bebc81a',
    borderRadius: theme.spacing(1),
    width: theme.spacing(55),
    padding: theme.spacing(1.5, 2),
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      boxSizing: 'border-box',
      width: '100%',
    },
  },
  title: {
    margin: 0,
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    color: '#3D414C',
    fontWeight: 700,
  },
  text: {
    margin: 0,
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    color: '#3D414C',
  },
  textWrapper: {
    marginLeft: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});
