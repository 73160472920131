import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles.js';

function Input({
  classes,
  placeholder,
  value,
  onChange,
  onKeyDown,
  label,
  type,
  readOnly,
  style,
  className,
}) {
  return (
    <div className={classes.wrapper}>
      { label && <label className={classes.label}>{label}</label> }
      <input
        className={clsx(classes.input, className)}
        type={type}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        readOnly={readOnly}
        style={style}
      />
    </div>
  );
}

export default withStyles(styles)(Input);
