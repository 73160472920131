export default (theme) => ({
  wrapper: {
    marginTop: '5vh',
    marginLeft: '8vw',
    width: theme.spacing(88),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '90vw',
      alignItems: 'center',
    },
  },
  question: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      marginBottom: theme.spacing(2),
    },
  },
  text: {
    color: theme.palette.text.main,
    fontSize: '2rem',
    fontWeight: 'bold',
    margin: 0,
    letterSpacing: -0.8,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem'
    },
  },
  subtitle: {
    fontSize: '1.125rem',
    fontWeight: 400,
    color: '#A49AB8',
    margin: theme.spacing(1, 0, 0),
  },
  number: {
    position: 'absolute',
    left: '-8vw',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      left: 0,
      marginRight: theme.spacing(2),
    },
  },
  content: {
    height: '100%',
    maxHeight: '50vh',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      height: '100%',
      maxHeight: '100%',
      marginBottom: theme.spacing(3)
    },
  },
  stepButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
    width: theme.spacing(88),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '90vw',
      padding: 0
    },
  },
  backButtonRoot: {
    color: theme.palette.text.main,
    height: theme.spacing(5),
    padding: theme.spacing(2, 2.5),
    borderRadius: 1,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      width: '100%',
      height: '100%',
    },
  },
  backButtonLabel: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
  },
  backButtonIcon: {
    stroke: theme.palette.primary.main,
  },
});
