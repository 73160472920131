import axios from 'axios';
import { auth } from './firebase';

let baseURL;
if (process.env.NODE_ENV === 'development') {
  baseURL = 'http://localhost:3000/funil-sonhos-vendas/us-central1/app';
} else if (process.env.NODE_ENV === 'production') {
  baseURL = 'https://us-central1-funil-sonhos-vendas.cloudfunctions.net/app';
}

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(async (config) => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: await auth.currentUser?.getIdToken(),
  },
}), (error) => Promise.reject(error));

export default instance;
