import background from '../../../assets/vectors/star-background.svg';

export default (theme) => ({
  background: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    width: 'fit-content',
    margin: '0 auto calc(62vh - 248px)',
    padding: theme.spacing(12, 9),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#FCFBFF',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '95vw',
      padding: theme.spacing(2, 4, 6),
      boxSizing: 'border-box',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1rem',
    textTransform: 'uppercase',
    margin: theme.spacing(1.5, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0),
    },
  },
  dreamTitle: {
    fontWeight: 'bold',
    maxWidth: theme.spacing(56),
    minWidth: theme.spacing(32),
    minHeight: theme.spacing(5),
    margin: theme.spacing(0, 0, 3),
    textAlign: 'center',
    letterSpacing: '-0.01rem',
    fontSize: '2em',
    [theme.breakpoints.down('sm')]: {
      maxWidth: theme.spacing(38),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(0, 3),
      fontSize: '1rem'
    },
  },
  text: {
    fontWeight: 'bold',
    fontSize: '1.125',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.01em',
    color: '#FCFBFF',
    marginTop: theme.spacing(0.5),
  },
  dreamFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  dreamPriceWrapper: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2, 0, 0),
    borderRight: '2px dotted #000000',
    width: '50%'
  },
  dreamDateWrapper: {
    display: 'inline-flex',
    padding: theme.spacing(0, 0, 0 , 2),
    width: '50%'
  },
  centralize: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});
