import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { dreamDates } from '../../../utils/date';
import styles from './styles.js';

function CalendarRadio({ onChange, month }) {
  const handleChange = (event) => onChange(Number(event.target.value));
  return (
    <RadioGroup value={month} onChange={handleChange}>
      {
        dreamDates.map((date, i) => (
          <FormControlLabel
            key={i}
            value={date.value}
            control={<Radio />}
            label={`${date.label} meses`}
          />
        ))
      }
    </RadioGroup>
  );
}

export default withStyles(styles)(CalendarRadio);
