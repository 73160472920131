import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Icon from '../../../components/Icon';

import styles from './styles';

function SideFunnel({
  classes,
  selectedFunnel,
  firstStepClick,
  secondStepClick,
  thirdStepClick,
  fourthStepClick,
}) {
  return (
    <aside>
      <ul className={classes.wrapper}>
        <li
          className={clsx(classes.item,
            { [classes.itemSelected]: selectedFunnel === 'activities' })}
          onClick={firstStepClick}
        >
          <Icon
            name={`funnelFirstStep${selectedFunnel === 'activities' ? 'Colored' : ''}`}
            className={classes.sideIcon}
          />
        </li>
        <li
          className={clsx(classes.item,
            { [classes.itemSelected]: selectedFunnel === 'sales' })}
          onClick={secondStepClick}
        >
          <Icon
            name={`funnelSecondStep${selectedFunnel === 'sales' ? 'Colored' : ''}`}
            className={classes.sideIcon}
          />
        </li>
        <li
          className={clsx(classes.item,
            { [classes.itemSelected]: selectedFunnel === 'goal' })}
          onClick={thirdStepClick}
        >
          <Icon
            name={`funnelThirdStep${selectedFunnel === 'goal' ? 'Colored' : ''}`}
            className={classes.sideIcon}
          />
        </li>
        <li
          className={clsx(classes.item,
            { [classes.itemSelected]: selectedFunnel === 'dream' })}
          onClick={fourthStepClick}
        >
          <Icon
            name={`funnelFourthStep${selectedFunnel === 'dream' ? 'Colored' : ''}`}
            className={classes.sideIcon}
          />
        </li>
      </ul>
    </aside>
  );
}

export default withStyles(styles)(SideFunnel);
