import { darken, lighten } from '@material-ui/core/styles';

export default {
  palette: {
    primary: {
      light: lighten('#7249E8', 0.1),
      main: '#7249E8',
      dark: darken('#7249E8', 0.3),
    },
    secondary: {
      light: '#B593FF',
      main: '#7249E8',
      dark: '#08158C',
    },
    grey: {
      light: '#DCD8E6',
      main: '#9A96A3',
      dark: '#0C0030',
    },
    screenGrey: {
      lighter: '#F1F1F1',
      light: '#FBFAFF',
      main: '#F8F5FF',
      dark: darken('#F8F5FF', 0.05),
    },
    critic: {
      main: '##FA77AE',
    },
    success: {
      main: '#3EE2BB',
    },
    warning: {
      main: '#FFCB7C',
    },
    agendorBlue: {
      main: '#5C57FF',
    },
    text: {
      light: '#6F8292',
      main: '#303440',
      dark: '#001930',
    },
  },
  typography: {
    fontFamily: 'Open Sans, Helvetica, Arial, sans-serif',
    input: {
      fontSizeStyle: '1rem',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
};
