export default (theme) => ({
  backdrop: {
    backgroundColor: '#ffffff33!important',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '&:focus': {
      outline: 'none',
    },
  },
  modal: {
    background: '#fff',
    boxShadow: '0px 48px 50px 0px #65568829',
    borderRadius: theme.spacing(3),
    padding: theme.spacing(3, 5, 7),
    width: theme.spacing(48),
    color: theme.palette.text.main,
    display: 'flex',
    flexDirection: 'column',
    border: '4px solid #e7e3f080',
    alignItems: 'center',
    textAlign: 'center',
    boxSizing: 'border-box',
  },
  image: {
    width: 265,
    height: 200,
  },
  title: {
    fontSize: '1.5rem',
    letterSpacing: -0.4,
    textAlign: 'center',
    padding: theme.spacing(0, 2),
    fontWeigth: 700,
  },
  closeButon: {
    background: 'none',
    border: 'none',
    width: theme.spacing(3),
    alignSelf: 'flex-end',
    marginRight: -20,
    right: 0,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  button: {
    marginTop: theme.spacing(2),

    backgroundColor: '#fff',
    padding: theme.spacing(1, 3),
    borderRadius: theme.spacing(2),
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
    '&:focus': {
      outline: 'none',
    },
  },
});
