import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import QuestionStructure from '../../../components/QuestionStructure';
import CompetenceCard from '../../../components/CompetenceCard';

import { competences } from '../../../utils/competences';
import styles from './styles';

function SelectCompetences({
  classes,
  selectedCompetences,
  onBackClick,
  onClick,
  onForwardClick,
  manager,
  funnelUser
}) {
  const listRef = useRef();

  const [hasBottomScroll, setHasBottomScroll] = useState(false);
  const [hasTopScroll, setHasTopScroll] = useState(false);

  useEffect(() => {
    const { scrollHeight, offsetHeight, scrollTop } = listRef.current;
    const showBottom = (scrollTop + offsetHeight) !== scrollHeight;
    handleShowScroll(false, showBottom);
  }, []);

  const handleListScroll = (e) => {
    const { scrollHeight, offsetHeight, scrollTop } = e.target;
    const showTop = scrollTop >= 10;
    const showBottom = (scrollTop + offsetHeight) !== scrollHeight;
    handleShowScroll(showTop, showBottom);
  };

  const handleShowScroll = (showTop, showBottom) => {
    if (showTop) {
      setHasTopScroll(true);
    } else {
      setHasTopScroll(false);
    }
    if (showBottom) {
      setHasBottomScroll(true);
    } else {
      setHasBottomScroll(false);
    }
  };

  const title = manager
    ? `Quais competências ${funnelUser.name} precisa desenvolver?`
    : 'Quais competências você precisa desenvolver?';

  const listClasses = clsx(classes.cardsList,
    { [classes.topScroll]: hasTopScroll },
    { [classes.bottomScroll]: hasBottomScroll });

  return (
    <QuestionStructure
      titleNumber={manager ? 1 : 2}
      title={title}
      onBackClick={onBackClick}
      onForwardClick={onForwardClick}
      isForwardDisabled={!selectedCompetences.length}
    >
      <ul ref={listRef} className={listClasses} onScroll={handleListScroll}>
        {
          competences.map((competence, i) => (
            <CompetenceCard
              key={i}
              {...competence}
              selectedCompetences={selectedCompetences}
              onClick={onClick}
            />
          ))
        }
      </ul>
    </QuestionStructure>
  );
}

export default withStyles(styles)(SelectCompetences);
