export default (theme) => ({
  header: {
    display: "flex",
    padding: theme.spacing(5, 3, 5, 0),
    "justifyContent": "space-between",
    "alignItems": "baseline",
    width: `calc(8vw + ${theme.spacing(88)}px)`
  },
  headerTitle: {
    color: '#303440',
    fontSize: theme.spacing(2),
    fontWeight: 500,
    margin: 0,
  },
  buttonLabel: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
    marginLeft: theme.spacing(1),
    textDecoration: 'none',
  },
  linkButtonRoot: {
    borderRadius: theme.spacing(3),
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
    height: 48,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'inherit',
    },
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
      textDecorationColor: theme.palette.primary.main,
    },
  },
  gray: {
    color: '#6F8292',
  },
});
