import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

import CompetenceCard from '../../components/CompetenceCard';
import Button from '../../components/Button';
import Icon from '../../components/Icon';

import { FunnelContext } from '../../contexts/FunnelContext';
import Api from '../../services/api';
import { competences } from '../../utils/competences';

import styles from './styles';

function UpdateCompetences({ classes, funnelId, currentCompetences }) {
  const { funnelDispatch } = useContext(FunnelContext);
  const history = useHistory();

  const [
    selectedCompetences,
    setSelectedCompetences,
  ] = useState(currentCompetences || []);

  const handleBackClick = () => history.goBack();

  const handleClick = (competence) => {
    const hasSelected = selectedCompetences.some((selectedCompetence) => (
      selectedCompetence === competence
    ));

    if (hasSelected) {
      const newCompetences = selectedCompetences.filter((selectedCompetence) => (
        selectedCompetence !== competence
      ));
      return setSelectedCompetences(newCompetences);
    }

    return setSelectedCompetences([
      ...selectedCompetences,
      competence,
    ]);
  };

  const updateClick = () => {
    funnelDispatch({
      type: 'UPDATE',
      competences: selectedCompetences,
    });

    Api
      .patch(`/funnels/${funnelId}`, { competences: selectedCompetences })
      .catch(() => alert('Houve um erro tente novamente mais tarde'));

    history.goBack();
  };

  return (
    <main className={classes.main}>
      <nav className={classes.navbar}>
        <MuiButton
          startIcon={
            <Icon name="arrow" className={classes.backButtonIcon} />
          }
          classes={{
            root: classes.backButtonRoot,
            label: classes.backButtonLabel,
          }}
          onClick={handleBackClick}
        >
          VOLTAR
        </MuiButton>
        <div className={classes.closeButton} onClick={handleBackClick}>
          &#10005;
        </div>
      </nav>
      <h1 className={classes.title}>Competências à aprimorar</h1>
      <ul className={classes.list}>
        {
          competences.map((competence, i) => (
            <CompetenceCard
              key={i}
              {...competence}
              selectedCompetences={selectedCompetences}
              onClick={handleClick}
            />
          ))
        }
      </ul>
      <div className={classes.updateButton}>
        <Button onClick={updateClick}>
          Atualizar
        </Button>
      </div>
    </main>
  );
}

export default withStyles(styles)(UpdateCompetences);
