export default (theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2.5),
    position: 'fixed',
    zIndex: 10,
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    '@media print': {
      display: 'none'
    }
  },
  headerShadow: {
    paddingBottom: theme.spacing(2),
    boxShadow: '0px 8px 20px rgba(91, 76, 134, 0.1)',
  },
  linksWrapper: {
    width: '100%',
    marginLeft: '12vw',
  },
  selectedLink: {
    fontWeight: 'bold',
    fontSize: '1rem',
    letterSpacing: '-0.02em',
    color: '#0C0030',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      height: 6,
      width: 6,
      backgroundColor: theme.palette.primary.main,
      top: 8,
      left: -21,
      borderRadius: 3,
    },
  },
  menuWrapper: {
    maxWidth: theme.spacing(50),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(27),
    },
  },
  menuIcon: {
    cursor: 'pointer',
    minWidth: theme.spacing(3),
    minHeight: theme.spacing(3),
    paddingLeft: theme.spacing(2),
  },
  logoIcon: {
    width: theme.spacing(5),
    height: theme.spacing(6),
    marginLeft: theme.spacing(7),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(4),
    },
  },
  competenceCard: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    border: '2px solid #F8F5FF',
    borderRadius: theme.spacing(1.5),
    boxSizing: 'border-box',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  competenceIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  sectionTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    letterSpacing: '-0.02em',
    color: '#0C0030',
    margin: theme.spacing(3, 0, 2),
  },
  separator: {
    height: 100,
    width: 2,
    border: 'none',
    borderRight: '2px dotted #DCD8E6',
    margin: theme.spacing(2, 4, 0),
  },
  editIcon: {
    fill: '#DCD8E6',
    cursor: 'pointer',
    marginTop: theme.spacing(1),
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: theme.spacing(31),
  },
  sectionText: {
    fontWeight: 500,
    color: theme.palette.text.main,
    verticalAlign: 'super',
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  headerCompetences: {
    display: 'flex',
  },
  calendarIcon: {
    marginTop: theme.spacing(1),
  },
  separatorSmall: {
    height: theme.spacing(7),
  },
  sectionTextSmall: {
    marginLeft: 0,
    marginTop: -theme.spacing(1),
    display: 'block',
  },
  noCompetenceText: {
    fontWeight: 500,
    color: theme.palette.text.main,
    marginTop: theme.spacing(1.5),
    display: 'block',
    cursor: 'pointer',
  },
  name: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    color: '#2A2040',
    borderRadius: '24px',
    padding: theme.spacing(0.5, 2, 0.5, 0.5),
    backgroundColor: theme.palette.screenGrey.lighter,
    width: 'fit-content',
    display: 'inline-flex',
    alignItems: 'center',
    maxWidth: '200px'
  },
  nameText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block'
  },
  nameIcon: {
    width: '40px',
    height: '40px',
    'flexShrink': 0,
    display: 'inline-flex',
    'justifyContent': 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.grey.light,
    marginRight: '5px',
    borderRadius: '20px'
  },
  permIdentity: {
    fill: '#fff',
    height: '24px',
  },
  signOutButtonRoot: {
    width: '100%',
    padding: '20px 60px',
    borderRadius: '0px',
    marginLeft: '0px',
    height: 'auto',
    '&:hover': {
      '& span': {
        color: theme.palette.primary.main
      },
      background: 'none',
      textDecoration: 'none',
    },
  },
  signOutButtonLabel: {
    textAlign: 'left',
    display: 'block',
    color: '#A49AB8',
    fontWeight: 'bold',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    marginLeft: '0px',
    textDecoration: 'none'
  },
  drawer: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent'
    }
  },
  drawerPaper: {
    'boxShadow': '0px 8px 20px rgba(91, 76, 134, 0.1)'
  },
  drawerItemDense: {
    padding: '0px'
  },
  drawerButton: {
    color: '#A49AB8',
    padding: '20px 60px',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
      '& line': {
        stroke: theme.palette.primary.dark
      },
    }
  },
  drawerButtonAlignRight: {
    display: 'block',
    textAlign: 'right'
  },
  closeIcon: {
    stroke: '#A49AB8',
    width: theme.spacing(2),
    height: 'auto'
  },
});
