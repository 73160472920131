import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import Tooltip from '../Tooltip';
import Icon from '../Icon';

import styles from './styles';

const listIcons = [
  {
    path: '/sonho',
    icon: <Icon name="trophy" />,
    tooltip: 'Sonho',
  },
  {
    path: '/meta-de-vendas',
    icon: <Icon name="salesGoal" />,
    tooltip: 'Meta de vendas',
  },
  {
    path: '/ticket-medio',
    icon: <Icon name="avgTicket" />,
    tooltip: 'Ticket médio',
  },
  {
    path: '/atividades',
    icon: <Icon name="activity" />,
    tooltip: 'Atividades',
  },
  {
    path: '/competencias',
    icon: <Icon name="competence" />,
    tooltip: 'Compentências',
  },
];

function SideBar({ classes }) {
  const { pathname } = useLocation();

  let shouldCheck = true;

  return (
    <div className={classes.wrapper}>
      <span className={classes.logo}>
        <Icon name="logoWhite" />
      </span>
      <ul className={classes.list}>
        {
          listIcons.map((item, i) => {
            const isSamePath = pathname === item.path;
            if (isSamePath) {
              shouldCheck = false;
            }

            const listItemClasses = clsx(
              classes.listItem,
              { [classes.listItemSelected]: isSamePath },
              { [classes.checkedItem]: shouldCheck },
            );
            const itemClasses = clsx(
              { [classes.item]: !isSamePath },
              { [classes.checkedItem]: shouldCheck },
            );

            return (
              <li
                className={listItemClasses}
                key={item.path + i}
              >
                {
                  shouldCheck && (
                    <Icon name="check" className={classes.checkIcon} />
                  )
                }
                <Tooltip title={item.tooltip} placement="right">
                  <span className={itemClasses}>
                    {item.icon}
                  </span>
                </Tooltip>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
}

export default withStyles(styles)(SideBar);
