import { useState, useContext } from 'react';
import { FunnelContext } from '../../contexts/FunnelContext';

export const useDreamState = () => {
  const { funnelState } = useContext(FunnelContext);

  var today = new Date();
  var monthsAhead = new Date(today.setMonth(today.getMonth() + 3));

  const [dreamData, setDreamData] = useState({
    type: funnelState.dreamType || '',
    text: funnelState.dreamText || '',
    price: funnelState.dreamPrice || 0,
    date: funnelState.dreamDate ? new Date(funnelState.dreamDate) : monthsAhead,
    initDate: funnelState.dreamInitDate ? new Date(funnelState.dreamInitDate) : new Date()
  });

  return [dreamData, setDreamData];
};
