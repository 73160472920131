export default (theme) => ({
	wrapper: {
	  background: '#EFFDF9',
	  borderRadius: theme.spacing(1),
	  padding: theme.spacing(1.5, 2, 1.5, 2),
	  display: 'flex',
		boxShadow: ({floating}) => floating ? '0px 24px 40px rgba(65, 81, 122, 0.2)' : null,
	  [theme.breakpoints.down('sm')]: {
	    boxSizing: 'border-box',
	    width: '100%',
	  },
	},
  title: {
    margin: 0,
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    color: '#3D414C',
    fontWeight: 700,
  },
  text: {
    margin: 0,
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    color: '#3D414C',
  },
  textWrapper: {
    marginLeft: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }
});