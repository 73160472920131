import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Lottie from 'react-lottie';

import animationData from '../../assets/lotties/funnel.json';
import Title from '../../components/Title';
import Button from '../../components/Button';
import SuccessSquare from '../../components/SuccessSquare';
import { FunnelContext } from '../../contexts/FunnelContext';
import { UserContext } from '../../contexts/UserContext';
import Api from '../../services/api';

import styles from './styles';

function FunnelComplete({ classes }) {
  const [isLoading, setIsLoading] = useState(true);

  const { funnelDispatch } = useContext(FunnelContext);
  const { userState } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 9000);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid',
    },
  };

  if (isLoading) {
    return (
      <main className={classes.main}>
        <Lottie
          options={defaultOptions}
          height={260}
          width={250}
          isPaused={false}
          isStopped={false}
        />
      </main>
    );
  }

  return (
    <main className={classes.main}>
      <div className={classes.iconWrapper}>
        <SuccessSquare large />
      </div>
      <Title
        title="Seu Funil dos Sonhos está pronto!"
        subtitle="Fizemos todos os cálculos e encontramos o que você precisa fazer para conquistar seu Sonho!"
      />
      <Button onClick={() => {
        funnelDispatch({
          type: 'UPDATE',
          updatedAt: new Date(),
          createdAt: new Date(),
          isCompleted: true,
        });
        Api
          .patch(`/funnels/${userState.id}`, { isCompleted: true })
          .catch(() => alert('Houve um erro tente novamente mais tarde'));
        localStorage.setItem('lastLogin', new Date());
        history.push('/funil');
      }}
      >
        Ver meu funil
      </Button>
    </main>
  );
}

export default withStyles(styles)(FunnelComplete);
