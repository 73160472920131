export default (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: 'rgba(12, 0, 48, .6)',
    '&> div:first-child': {
      background: 'transparent!important'
    }
  },
  modal: {
    background: '#fff',
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(8, 10),
    width: theme.spacing(70),
    color: theme.palette.text.main,
    display: 'block',
    boxSizing: 'border-box',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 4),
    },
    '&:focus': {
      outline: 'none',
    },
  },
  closeButton: {
    color: '#A49AB8',
    backgroundColor: '#F4F3F7',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderRadius: theme.spacing(2),
    width: theme.spacing(4),
    height: theme.spacing(4),
    border: 'none',
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(4),
    '&:focus': {
      outline: 'none',
    },
  },
  title: {
    fontSize: '2rem',
    letterSpacing: '-0.04em',
    fontWeight: 'bold',
    margin: theme.spacing(0, 0, 2.5),
  }
});
