export default (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'fixed',
    height: '100%',
    width: theme.spacing(12.25),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      flexDirection: 'row',
      width: '100%',
      height: theme.spacing(9),
      justifyContent: 'space-around',
      alignItems: 'flex-end',
    },
  },
  logo: {
    margin: '24px 0 10vh',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      alignSelf: 'center',
    },
  },
  icon: {
    fill: '#fff',
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      width: '75vw',
      justifyContent: 'space-around',
    },
  },
  listItem: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: 0,
    },
  },
  item: {
    opacity: 0.3,
  },
  listItemSelected: {
    padding: theme.spacing(3.25, 3),
    background: '#3B28B4',
    borderRadius: theme.spacing(3, 0, 0, 3),
    alignSelf: 'flex-end',
    opacity: 1,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2, 1.5),
      borderRadius: theme.spacing(4, 4, 0, 0),
    },
  },
  checkedItem: {
    opacity: 1,
  },
  checkIcon: {
    margin: theme.spacing(0, 1, 0.75, 1.5),
    stroke: theme.palette.success.main,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 0, 1),
    },
  },

});
