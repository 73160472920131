/* eslint-disable react-hooks/exhaustive-deps */

import React, { forwardRef, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import ptLocale from 'date-fns/locale/pt';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Icon from '../../../components/Icon';
import Tooltip from '../../../components/Tooltip';
import EditGoalModal from '../EditGoalModal';
import styles from './styles';

const GoalCard = forwardRef((props, ref) => {
  const {
    classes, funnelUser, funnel, isManager, isOpenEditGoalModal, handleToggleEditGoalModal
  } = props;

  const [isHovering, setIsHovering] = useState(false);

  const isSmallMedia = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { salesGoal = 0, soldValue = 0 } = funnel
  const missingValue = (salesGoal - soldValue) < 0 ? 0 : (salesGoal - soldValue);
  const formatter = new Intl.NumberFormat('pt-BR');
  const formatedSalesGoal = formatter.format(salesGoal);
  const formatedSoldValue = formatter.format(soldValue);
  const formatedMissingValue = formatter.format(missingValue);
  const formatedDate = funnel.updatedAt ? format(new Date(funnel.updatedAt), 'd, LLL. yyyy', { locale: ptLocale }, ) : '';

  const soldProgress = (soldValue / salesGoal > 1) ? 100 : ((soldValue * 100) / salesGoal);
  const missingProgress = 100 - soldProgress;

  return (
    <div
      ref={ref}
      className={classes.card}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className={classes.header}>
        <h4 className={classes.title}>
          {
            isManager
              ? 'QUANTO FALTA PARA REALIZAR O SONHO?'
              : 'QUANTO FALTA PARA REALIZAR O MEU SONHO?'
          }
        </h4>
        <div className={classes.updatedAt}>
          {
            isHovering && !isManager
              ? <Tooltip arrow placement="top" title="Editar vendas até o momento">
                  <div><Icon name="edit" onClick={() => handleToggleEditGoalModal(true)} /></div>
                </Tooltip>
              : !isSmallMedia && (
                <section>
                  atualizado em
                  <br />
                  {' '}
                  <b>{formatedDate}</b>
                </section>
              )
          }
        </div>
      </div>
      <div>
        <div className={classes.progressHeader}>
          <span className={classes.missingSales}>
            {
              (missingValue === 0)
              ? 'R$ 0, parabéns pela conquista!'
              : 'R$ ' + formatedMissingValue
            }
          </span>
          <span className={classes.soldSales}>
            R$
            {' '}
            {formatedSoldValue}
          </span>
        </div>
        <div className={classes.barWrapper}>
          <span
            className={classes.missingBar}
            style={{ width: `${missingProgress}%` }}
          />
          <span
            className={classes.soldBar}
            style={{ width: `${soldProgress}%` }}
          />
        </div>
        <div className={classes.footer}>
          <div className={classes.legend}>
            <span className={classes.missingSalesLegend}>
              Quanto falta
            </span>
            <span className={classes.soldSalesLegend}>
              Quanto já vendi
            </span>
          </div>
          <div className={classes.extraInfo}>
            <div className={classes.infoWrapper}>
              <span className={classes.infoTitle}>
                Meta de Vendas (total)
              </span>
              <span className={classes.infoValue}>
                R$
                {' '}
                {formatedSalesGoal}
              </span>
            </div>
            <div className={classes.infoWrapper}>
              <span className={classes.infoTitle}>
                {
                  isManager
                    ? 'Comissão'
                    : 'Minha comissão'
                }
              </span>
              <span className={classes.infoValue}>
                {funnel.commission}
                %
              </span>
            </div>
          </div>
        </div>
      </div>
      <EditGoalModal
        open={isOpenEditGoalModal}
        onClose={() => handleToggleEditGoalModal(false)}
        hasIntegration={Boolean(funnelUser.agendorToken)}
      />
    </div>
  );
});
export default withStyles(styles)(GoalCard);
