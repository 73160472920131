import React from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import theme from './config/theme';
import { UserProvider } from './contexts/UserContext';
import { FunnelProvider } from './contexts/FunnelContext';

import Routes from './Routes';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
        <FunnelProvider>
          <UserProvider>
            <Routes />
          </UserProvider>
        </FunnelProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
