import React, { useContext } from 'react';
import { differenceInCalendarMonths, addMonths, format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import { FunnelContext } from '../../../contexts/FunnelContext';

import Modal from '../../../components/Modal';
import Button from '../../../components/Button';

import styles from './styles';

import Api from '../../../services/api';
import Analytics from '../../../utils/analytics';

function FunnelResultModal({
	classes,
	open,
	onClose,
	onChangePrice,
	onChangeDate
}) {
	let title = null;
	let subtitle = null;
	let proposedDreamDate = null;
	let proposedDreamPrice = 0;
	let labelAdjustDate = null;
	let labelAdjustDreamPrice = null;
	const healthySalesGoalGrowthMin = 1.3;
	const healthySalesGoalGrowthMax = 1.7;
	const deviation = 0.1;
	const maxDreamDateMonthsFromNow = 12 * 5;

	const history = useHistory();
	const { funnelState, funnelDispatch, funnelUpdate } = useContext(FunnelContext);

	const setViewedFunnelResult = (hint, skipped = false) => {
		const viewed = true;
		const event = !skipped ? "funnel_result_hint_accepted" : "funnel_result_skipped";
    
    funnelDispatch({ type: 'UPDATE', viewedFunnelResult: viewed });
		
		Api.patch(`/funnels/${funnelState.id}`, {
			viewedFunnelResult: viewed
		})
    .then(() => {
      Analytics.log(event, !skipped ? {
        hint: hint
      } : null);
    })
    .catch(() => alert('Não foi possível definir que você visualizou o resultado do seu Funil. Por favor, entre em contato com contato@agendor.com.br.'));
	}

  const updateDream = ({ proposedDreamDate, proposedDreamPrice }) => {
  	const newData = { type: 'UPDATE' };
  	let hint = null;

  	if (proposedDreamDate) {
  		newData.dreamDate = proposedDreamDate;
  		hint = 'Dream Date';
			onChangeDate();
  	} else if (proposedDreamPrice) {
  		newData.dreamPrice = proposedDreamPrice;
  		hint = 'Dream Price';
			onChangePrice();
  	}

    const newFunnelState = funnelUpdate(newData);

    setViewedFunnelResult(hint);

    Api
      .patch(
        `/funnels/${funnelState.id}`,
        newFunnelState,
      )
      .catch(() => alert('Houve um erro tente novamente mais tarde'));
  };

	const monthsFromToday = differenceInCalendarMonths(new Date(funnelState.dreamDate), new Date());
	const numberOfSalesLeft = (funnelState.numberOfSales - funnelState.soldQuantity) <= 0 ? 0 : funnelState.numberOfSales - funnelState.soldQuantity;
	const numberOfSalesGoalPerMonth = numberOfSalesLeft / monthsFromToday;
	const numberOfSalesGoalGrowth = numberOfSalesGoalPerMonth / funnelState.avgSales;

	const isConservative = (numberOfSalesGoalGrowth <= healthySalesGoalGrowthMin * (1-deviation));
	const isAudacious = (numberOfSalesGoalGrowth >= healthySalesGoalGrowthMax * (1+deviation));

	const betterSalesGoalPerMonth = funnelState.avgSales * funnelState.avgTicket * (isConservative ? healthySalesGoalGrowthMin : healthySalesGoalGrowthMax);
	const commission = Number(funnelState.commission) / 100;
	const monthsToAdd = Math.ceil((funnelState.salesGoal - funnelState.soldValue) / betterSalesGoalPerMonth);

	proposedDreamPrice = ((betterSalesGoalPerMonth * monthsFromToday) + funnelState.soldValue) * commission;
	proposedDreamDate = betterSalesGoalPerMonth ? addMonths(new Date(), monthsToAdd) : new Date();

	if (numberOfSalesLeft > 0) {
		if (isConservative) {
			title = "Seu Sonho parece muito conservador! 🤔";
			subtitle = "Com sua produtividade atual, seu Sonho pode ser " + (proposedDreamPrice / funnelState.dreamPrice).toFixed(1) + "x maior! 🤩  Você pode (e deve) sonhar mais alto! Sugerimos uma das seguintes ações:";
			labelAdjustDate = "Diminuir";
			labelAdjustDreamPrice = "Aumentar";
		} else if (isAudacious) {
			title = "Seu Sonho parece muito audacioso! 😳";
			subtitle = "Você teria que vender " + (numberOfSalesGoalGrowth).toFixed(1) + "x mais por mês! Para torná-lo mais tangível, sugerimos uma das seguintes ações:";
			labelAdjustDate = "Aumentar";
			labelAdjustDreamPrice = "Diminuir";
		} else {
			title = "Parabéns, você deu o primeiro passo da sua jornada! 😃";
			subtitle = "Fizemos todos os cálculos e concluímos que seu Sonho é desafiador e realista para sua produtividade atual! 👏";
		}
	}

	const shouldProposeNewDate = (differenceInCalendarMonths(proposedDreamDate, new Date()) <= maxDreamDateMonthsFromNow);

	const formattedProposedDreamDate = format(proposedDreamDate, 'dd/LL/yy');
	const formattedDreamDate = format(new Date(funnelState.dreamDate), 'dd/LL/yy');

  const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 0 });
  const formatedDreamPrice = formatter.format(funnelState.dreamPrice);
  const formatedProposedDreamPrice = formatter.format(proposedDreamPrice);

	return (
		(numberOfSalesLeft > 0) &&
	    <Modal
				open={open}
				onClose={onClose}
				title={title}
	    >
		    <p className={classes.subtitle}>
		      {subtitle}
		    </p>
		    { (labelAdjustDate || labelAdjustDreamPrice) && (
					<>
						{ shouldProposeNewDate && (
				      <Button size="small" margin={{ bottom: 12 }} onClick={ e => {
								updateDream({
									proposedDreamDate
								});
				      }}>
				        {labelAdjustDate} prazo de {formattedDreamDate} para {formattedProposedDreamDate}
				      </Button>
						)}
			      <Button size="small" margin={{ bottom: 12 }} onClick={ e => {
			      	updateDream({
								proposedDreamPrice
			      	});
			      }}>
			        {labelAdjustDreamPrice} valor de {formatedDreamPrice} para {formatedProposedDreamPrice}
			      </Button>
			      <Button size="small" variant="outlined" margin={{ bottom: 24 }} onClick={ e => {
							history.push('/sonho');
			      }}>
			        Mudar meu Sonho
			      </Button>
			      <Button size="small" block variant="text" onClick={ e => {
							setViewedFunnelResult(null, true);
							onClose();
			      }}>
			        Prefiro deixar como está
			      </Button>
			    </>
		    )}
		    { (!labelAdjustDate && !labelAdjustDreamPrice) && (
					<Button size="medium" margin={{top: 20}} onClick={ e => {
		      	setViewedFunnelResult(null, true);
		      	onClose();
		      }}>
						Ver meu funil
					</Button>
		    )}
	    </Modal>
	);
}

export default withStyles(styles)(FunnelResultModal);
