import React, { useContext } from 'react';

import { format } from 'date-fns';
import ptLocale from 'date-fns/locale/pt';

import { withStyles } from '@material-ui/core/styles';

import Modal from '../../../components/Modal';
import ContainedInput from '../../../components/ContainedInput';
import Progress from '../../../components/Progress';
import { FunnelContext } from '../../../contexts/FunnelContext';
import { UserContext } from '../../../contexts/UserContext';
import Api from '../../../services/api';
import styles from './styles';

import { getLink } from '../../../utils/links';
import Analytics from '../../../utils/analytics';

function EditGoalModal({
  classes, open, onClose, hasIntegration,
}) {
  const { funnelState, funnelUpdate, funnelDispatch, clearState } = useContext(FunnelContext);
  const { userState } = useContext(UserContext);

  const handleInputChange = (e) => {
    const { value } = e.target;
    let newSoldValue = value.replace(/\./g, '');
    if (newSoldValue.match(/^[0-9]*$/)) {
      newSoldValue = Number(newSoldValue);
      let soldQuantity = parseInt(newSoldValue / funnelState.avgTicket);
      funnelUpdate({
        type: 'UPDATE',
        soldValue: newSoldValue,
        soldQuantity
      });
    }
  };

  const save = () => {
    const updatedAt = new Date();
    Api
      .patch(`/funnels/${userState.id}`, clearState(funnelState))
      .then(Analytics.log("total_sales_updated"))
      .catch(() => alert('Houve um erro tente novamente mais tarde'));
    funnelDispatch({ type: 'UPDATE', updatedAt: updatedAt });
    onClose();
  }

  const handleSaveClick = () => {
    return save();
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      return save();
    }
  };

  const formatter = new Intl.NumberFormat('pt-BR');
  const formatedSalesGoal = formatter.format(funnelState.salesGoal);
  const formatedSoldValue = formatter.format(funnelState.soldValue);

  const dreamInitDate = funnelState.dreamInitDate || funnelState.createdAt;
  const formatedDreamInitDate = dreamInitDate ? format(new Date(dreamInitDate), 'd, LLL. yyyy', { locale: ptLocale }) : '';

  const progress = (funnelState.soldValue * 100) / funnelState.salesGoal;

  const title = hasIntegration ? 'Seu funil foi atualizado' : 'Atualize seu funil';

  return (
    <Modal
      onClose={onClose}
      open={open}
      title={title}
    >
      <p className={classes.subtitle}>
        {
          hasIntegration
            ? <span>
                { 'O valor vendido até o momento foi atualizado ' }
                <strong>automaticamente</strong> com base nas suas vendas do <a className={classes.link} href={getLink({link:'AGENDOR_SITE', utmContent: 'sales-goal-modal'})} target="_blank" rel="noopener noreferrer">Agendor</a>
                { formatedDreamInitDate ? ' desde ' + formatedDreamInitDate : ''}.
              </span>
            : 'Informe o valor vendido até o momento para atualizarmos as informações no seu funil'
        }
      </p>
      <div className={classes.progressHeader}>
        <span>
          {
            hasIntegration
              ? (
                <span className={classes.soldText}>
                  R$ {formatedSoldValue}
                </span>
              )
              : (
                <span className={classes.inputWrapper}>
                  <ContainedInput
                    type="text"
                    value={formatedSoldValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    autoFocus={true}
                  />
                </span>
              )
          }
          <span> de </span>
          <span className={classes.totalSales}>
            R$
            {formatedSalesGoal}
          </span>
        </span>
        <span className={classes.trophy} role="img" aria-label='trophy'>🏆</span>
      </div>
      <Progress value={progress} />
      <div>
        <span className={classes.saledLegend}>
          Valor vendido
        </span>
        <span className={classes.goalLegend}>
          Meta de vendas
        </span>
      </div>
      <div>
        {
          hasIntegration
            ? (
              <button className={classes.updateButton} onClick={onClose}>
                Prosseguir
              </button>
            )
            : (
              <>
                <button className={classes.updateButton} onClick={handleSaveClick}>
                  Atualizar valor vendido
                </button>
                <button className={classes.proceedButton} onClick={onClose}>
                  Prosseguir sem atualizar
                </button>
              </>
            )
        }
      </div>
    </Modal>
  );
}

export default withStyles(styles)(EditGoalModal);
