const style = (theme) => {
  return {
    root: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      border: 'none',
      cursor: 'pointer',
      display: ({ block }) => block ? 'block' : null,
      marginTop: ({ margin }) => margin ? margin.top : null,
      marginBottom: ({ margin }) => margin ? margin.bottom : null,
      padding: ({ size }) => {
        if (size === 'small') return theme.spacing(2, 3);
        else if (size === 'medium') return theme.spacing(3, 4);
        else return theme.spacing(2.5, 3);
      },
      boxShadow: ({ size }) => {
        if (size === 'small') return 'none';
        else if (size === 'medium') return 'none';
      },
      borderRadius: theme.spacing(1),
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        boxShadow: ({ size }) => {
          if (size === 'small') return 'none';
          else if (size === 'medium') return 'none';
        },
      },
      '&$disabled': {
        backgroundColor: '#F8F5FF',
        color: '#B1A8DC',
        '&:hover': {
          backgroundColor: '#F8F5FF',
        }
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: theme.spacing(2),
      },
    },
    label: {
      fontWeight: 'bold',
      fontSize: ({ size }) => {
        if (size === 'small') return '14px';
        else if (size === 'medium') return '1rem';
        else return '1.25rem';
      },
      lineHeight: ({ size }) => {
        if (size === 'small') return '14px';
        else if (size === 'medium') return '1rem';
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    disabled: {
      [theme.breakpoints.down('sm')]: {
        boxShadow: 'none!important',
      },
    },
    complete: {
      borderRadius: theme.spacing(4),
      width: theme.spacing(4),
      height: theme.spacing(4),
      backgroundColor: '#3034404d',
      marginLeft: theme.spacing(3),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1),
      },
    },
    outlined: {
      backgroundColor: '#fff',
      boxSizing: 'border-box',
      color: theme.palette.primary.main,
      border: '2px solid ' + theme.palette.primary.main,
      '&:hover': {
        backgroundColor: '#fff',
        color: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark
      },
    },
    text: {
      backgroundColor: 'transparent',
      color: '#A49AB8',
      fontSize: '16px',
      lineHeight: '28px',
      padding: theme.spacing(2, 0) + '!important',
      '&:hover': {
        backgroundColor: '#fff',
        color: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark
      },
    },
    icon: {
      stroke: theme.palette.success.main,
    }
  }
};

export default style;