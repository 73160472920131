export default (theme) => ({
  headers: {
    display: 'flex',
    width: 700,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  headersText: {
    fontWeight: 'bold',
    fontSize: '0.85rem',
    color: theme.palette.text.main,
    width: theme.spacing(43),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  headerWidth: {
    width: theme.spacing(22.5),
  },
  divider: {
    border: 'none',
    margin: 0,
    borderBottom: `1px solid ${theme.palette.screenGrey.main}`,
    width: '100%',
  },
  activitiesList: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    margin: 0,
    padding: 0,
    overflow: 'auto',
    maxHeight: 'calc(50vh - 80px)',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'none',
      overflow: 'none',
      width: '90vw'
    },
  },
  bottomScroll: {
    '&:after': {
      height: theme.spacing(2),
      content: '""',
      position: 'absolute',
      background: 'linear-gradient(0deg, #e4e3e3 -186.21%, rgb(241 241 241 / 0%) 95%)',
      width: '100%',
      bottom: 0,
    },
  },
  topScroll: {
    '&:before': {
      height: theme.spacing(2),
      content: '""',
      position: 'absolute',
      background: 'linear-gradient(180deg, #e4e3e3 -186.21%, rgb(241 241 241 / 0%) 95%)',
      width: '100%',
    },
  },
});
