import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Hidden from '@material-ui/core/Hidden';
import SectionIntro from '../../components/SectionIntro';
import SideBar from '../../components/SideBar';
import SurveyHeader from '../../components/SurveyHeader';
import QuestionStructure from '../../components/QuestionStructure';

import { FunnelContext } from '../../contexts/FunnelContext';
import Api from '../../services/api';

import SourceTarget from './SourceTarget';
import { useGoalState, useCommisionState } from './hooks';
import styles from './styles';

import { calculateSalesGoal } from '../../utils';
import Analytics from '../../utils/analytics';

function SalesGoal({ classes }) {
  const history = useHistory();
  const historyState = history.location && history.location.state;
  const step = historyState && historyState.step;

  const { funnelState, funnelUpdate } = useContext(FunnelContext);

  const [showIntro, setShowIntro] = useState(!step);
  const [goal, setGoal] = useGoalState();
  const [commission, setCommission] = useCommisionState();
  const [automaticFill, setAutomaticFill] = useState(false);

  useEffect(() => {
    let stepName = null;

    if (showIntro) stepName = 'Sales Goal Intro';
    else stepName = 'Sales Goal';

    Analytics.log("onboarding_step_viewed", {
      step_name: stepName
    });
  }, [showIntro]);

  const handleBackClick = () => setShowIntro(true);
  const handleForwardClick = () => {
    const newFunnelState = funnelUpdate({ type: 'UPDATE', salesGoal: goal, commission });
    Api
      .patch(`/funnels/${funnelState.id}`, newFunnelState)
      .catch(() => alert('Houve um erro tente novamente mais tarde'));
    history.push('/ticket-medio');
  };

  const handleSourceChange = (commision) => {
    setCommission(commision);
    if (commision > 0) {
      setGoal(calculateSalesGoal(funnelState.dreamPrice, commision));
      setAutomaticFill(true);
    }
  };

  const handleTargetChange = (goal) => {
    setGoal(Number(goal));
    setAutomaticFill(false);
  };

  if (showIntro) {
    return (
      <SectionIntro
        header="Meta de vendas"
        title="Quanto você precisará vender"
        subtitle="Agora que já sabemos quanto custa seu Sonho, precisamos encontrar quanto você precisará vender para realizá-lo. Veja no vídeo abaixo como iremos calcular sua meta de vendas."
        videoSrc="https://www.youtube.com/embed/vesBSZ0T3zE?autoplay=1&rel=0&showinfo=0"
        onClick={() => setShowIntro(false)}
        onBackClick={() => history.push('/sonho', { step: 4 })}
      />
    );
  }

  return (
    <>
      <SideBar />
      <main className={classes.main}>
        <Hidden smDown>
          <SurveyHeader
            title="Início"
            subTitle="Monte seu funil"
          />
        </Hidden>
        <QuestionStructure
          titleNumber={1}
          title="Descubra a sua meta de vendas, informando quanto você ganha de comissão por venda"
          onBackClick={handleBackClick}
          onForwardClick={handleForwardClick}
          isForwardDisabled={!(goal && commission)}
        >
          <SourceTarget
            sourceValue={commission}
            onSourceChange={handleSourceChange}
            targetValue={goal}
            onTargetChange={handleTargetChange}
            automaticFill={automaticFill}
            targetDate={funnelState.dreamDate}
          />
        </QuestionStructure>
      </main>
    </>
  );
}

export default withStyles(styles)(SalesGoal);
