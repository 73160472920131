import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { isAndroid, isIOS } from "react-device-detect";

import Modal from '@material-ui/core/Modal';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '../Icon';
import ContainedInput from '../ContainedInput';

import styles from './styles';

import { getLink } from '../../utils/links';

function IntegrationModal({
  classes,
  open,
  onClose,
  signupClick,
  token,
  onTokenChange,
  onConfirm,
}) {

  let agendorLogoLink = null;
  if (isAndroid) agendorLogoLink = 'AGENDOR_GPLAY';
  else if (isIOS) agendorLogoLink = 'AGENDOR_ITUNES';
  else agendorLogoLink = 'AGENDOR_SITE';

  return (
    <Modal
      onClose={onClose}
      open={open}
    >
      <div className={classes.wrapper}>
        <div className={classes.modal}>
          <button className={classes.closeButon} onClick={onClose}>
            &#10005;
          </button>
          <div>
            <a className={classes.agendorSquare} href={getLink({link: agendorLogoLink, utmContent: 'integration-modal'})} target="_blank" rel="noopener noreferrer">
              <Icon name="agendorLogo" />
            </a>
            <Icon name="integration" className={classes.integrationIcon} />
            <div className={classes.funnelSquare}>
              <Icon name="logoWhite" />
            </div>
          </div>
          <h1 className={classes.title}>
            Conecte seu Agendor com o Funil dos Sonhos
          </h1>
          <p className={classes.text}>
            <strong>Copie o </strong>
            <a className={classes.tokenText} href={getLink({link:'AGENDOR_INTEGRATIONS', utmContent: 'integration-modal'})} target="_blank" rel="noopener noreferrer"> Token da sua conta Agendor</a>
            <Tooltip
              title="Encontre o token em &quot;Menu&quot; > &quot;Integrações&quot; > &quot;Token para usar a API do Agendor&quot;"
              placement="top-end"
              classes={{
                arrow: classes.tooltipArrow,
                tooltip: classes.tooltip,
              }}
              arrow
              interactive
            >
              <span className={classes.inform}>i</span>
            </Tooltip>
            e insira no campo abaixo:
          </p>
          <div className={classes.inputWrapper}>
            <ContainedInput
              value={token}
              onChange={onTokenChange}
              inputClasses={classes.tokenInput}
            />
          </div>
          <em className={classes.textInfo}>Ao fazer isso, todos os cálculos de ticket-médio e atividades serão automatizados.</em>
          <button
            className={classes.connectButton}
            disabled={!token || !(token.length > 32)}
            onClick={onConfirm}
          >
            Conectar com Agendor
          </button>
          <p className={classes.bottomText}>
            Ainda não tem o Agendor?
            <span
              className={classes.signUpAgendor}
              onClick={signupClick}
            >
              {' '}
              Cadastre-se
            </span>
          </p>
        </div>
      </div>
    </Modal>
  );
}

export default withStyles(styles)(IntegrationModal);
