import background from '../../assets/vectors/background.svg';

export default (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.text.dark,
    padding: theme.spacing(5),
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      background: 'none',
      margin: '0 auto',
      padding: 0,
      height: 'auto',
    },
  },
  form: {
    width: theme.spacing(50),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  inputSpace: {
    width: '100%',
    margin: theme.spacing(2, 0, 0),
  },
  label: {
    alignSelf: 'flex-start',
    fontWeight: 700,
    color: theme.palette.text.main,
    fontSize: '1rem',
    fontFamily: 'inherit',
    marginBottom: theme.spacing(1),
  },
  agendorWrapper: {
    margin: theme.spacing(4, 0, 0, 0),
    cursor: 'pointer',
  },
  submitButton: {
    margin: theme.spacing(4, 0, 0, 0),
  },
  checkboxWrapper: {
    border: '2px solid #F8F5FF',
    backgroundColor: '#fff',
    borderBottom: 'none',
    borderRadius: theme.spacing(1, 1, 0, 0),
    padding: theme.spacing(1.5, 3.5),
  },
  checkboxLabel: {
    fontWeight: 700,
    fontSize: '1rem',
    fontFamily: 'inherit',
    color: theme.palette.text.main,
  },
  tooltip: {
    boxShadow: '0px 16px 40px rgba(65, 81, 122, 0.2)',
    backgroundColor: '#ffffff',
    color: theme.palette.text.main,
    fontSize: '1rem',
    padding: theme.spacing(2),
    maxWidth: theme.spacing(42),
    lineHeight: '178%',
    textAlign: 'center',
    borderRadius: theme.spacing(1),
  },
  tooltipArrow: {
    color: '#ffffff',
  },
  inform: {
    color: '#332DE6',
    border: '2px solid #332DE6',
    borderRadius: 10,
    width: theme.spacing(3),
    height: theme.spacing(2.25),
    textAlign: 'center',
    fontWeight: 900,
    fontSize: '0.75rem',
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      marginLeft: -theme.spacing(1),
    },
  },
  agendor: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#332DE6',
    padding: theme.spacing(1, 3.5),
    borderRadius: theme.spacing(0, 0, 1, 1),
  },
  knowsAgendorText: {
    fontWeight: 'bold',
    fontSize: '1rem',
    paddingLeft: theme.spacing(2),
    color: '#FFFFFF',
    margin: theme.spacing(1, 0, 0),
  },
  testAgendorText: {
    fontWeight: 'bold',
    fontSize: '1rem',
    paddingLeft: theme.spacing(2),
    color: '#FFE0CB',
    margin: theme.spacing(0, 0, 1),
  },
});
