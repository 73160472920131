export default (theme) => ({
  textarea: {
    color: theme.palette.primary.main,
    caretColor: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '2rem',
    resize: 'none',
    border: 'none',
    width: '100%',
    height: theme.spacing(15),
    marginBottom: theme.spacing(2),
    fontFamily: 'inherit',
    '&::placeholder': {
      color: '#AB96E6',
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
});
