export default (theme) => ({
  row: {
    margin: theme.spacing(1.5, 0),
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  centralize: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  activityIcon: {
    width: 28,
    height: 28,
    fill: theme.palette.grey.light,
  },
  activityName: {
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.palette.text.main,
    margin: theme.spacing(0, 0, 0, 3),
    [theme.breakpoints.down('sm')]: {
      margin: '0px',
      fontSize: '.75rem'
    },
  },
  inputWidth: {
    minWidth: theme.spacing(10),
    textAlign: 'center',
  },
  noName: {
    fontWeight: 'bold',
    fontSize: '1rem',
    color: theme.palette.grey.light,
    margin: theme.spacing(0, 2),
  },
  rightArrowIcon: {
    fill: theme.palette.grey.light,
    marginLeft: theme.spacing(4),
  },
  activityText: {
    color: theme.palette.grey.light,
    fontWeight: 'bold',
    fontSize: '1rem',
    width: theme.spacing(13),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      margin: theme.spacing(1, 0),
    },
  },
  activityTextSuccess: {
    color: theme.palette.success.main,
  },
  activityColumn: {
    width: theme.spacing(22),
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      display: 'block'
    },
  },
  frequencyColumn: {
    width: theme.spacing(45),
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(33.25),
    },
  },
  emptySuccessSquare: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  divider: {
    border: 'none',
    margin: 0,
    borderBottom: `1px solid ${theme.palette.screenGrey.main}`,
    width: '100%',
  },
  headersText: {
    fontWeight: 'bold',
    fontSize: '0.85rem',
    color: theme.palette.grey.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  margin: {
    margin: theme.spacing(0, 3),
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  selectWrapper: {
    minWidth: theme.spacing(16),
  },
  integratedInput: {
    border: '2px solid #5C57FF',
    color: '#5C57FF',
  },
});
