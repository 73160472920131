import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import InputMask from 'react-input-mask';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';

import styles from './styles';

function ContainedInput({
  classes,
  value,
  onChange,
  label,
  type,
  placeholder,
  inputClasses,
  mask,
  autoFocus = false,
  onKeyDown
}) {
  return (
    <>
      {
        label && (
          <InputLabel classes={{ root: classes.label }}>
            {label}
          </InputLabel>
        )
      }
      {
        mask
          ? (
            <InputMask
              mask={mask}
              value={value}
              onChange={onChange}
              onKeyDown={onKeyDown}
            >
              {(inputProps) => (
                <InputBase
                  {...inputProps}
                  type={type}
                  classes={{
                    root: classes.root,
                    input: clsx(classes.input, inputClasses),
                  }}
                  placeholder={placeholder}
                  autoFocus={autoFocus}
                />
              )}
            </InputMask>
          )
          : (
            <InputBase
              type={type}
              value={value}
              onChange={onChange}
              onKeyDown={onKeyDown}
              classes={{
                root: classes.root,
                input: clsx(classes.input, inputClasses),
              }}
              placeholder={placeholder}
              autoFocus={autoFocus}
            />
          )
      }

    </>
  );
}

export default withStyles(styles)(ContainedInput);
