import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

function Title({
  classes,
  title,
  subtitle,
}) {
  return (
    <div className={classes.wrapper}>
      <h1 className={classes.title}>{title}</h1>
      <h2 className={classes.subtitle}>{subtitle}</h2>
    </div>
  );
}

export default withStyles(styles)(Title);
