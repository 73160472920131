import React from 'react';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';
import { Button as MuiButton } from '@material-ui/core';

import Icon from '../Icon';
import styles from './styles';

function Button({
  classes,
  buttonClasses = {},
  children,
  disabled,
  onClick,
  fullWidth = false,
  completed,
  variant = 'contained',
  size = 'large',
  margin = {},
  block = false
}) {
  const rootClasses = clsx(classes.root, buttonClasses.root);

  return (
    <MuiButton
      variant={variant}
      onClick={onClick}
      classes={{
        root: rootClasses,
        label: classes.label,
        disabled: classes.disabled,
        outlined: classes.outlined,
        text: classes.text
      }}
      disabled={disabled}
      fullWidth={fullWidth}
      type="submit"
    >
      {children}
      {
        completed && (
          <span className={classes.complete}>
            <Icon name="check" className={classes.icon} />
          </span>
        )
      }

    </MuiButton>
  );
}

export default withStyles(styles)(Button);
