import React, { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import Hidden from '@material-ui/core/Hidden';
import QuestionStructure from '../../../components/QuestionStructure';
import AgendorIntegrationButton from '../../../components/AgendorIntegrationButton';

import ActivityRow from '../ActivityRow';
import styles from './styles.js';

function Activities({
  classes,
  activities,
  onBackClick,
  onForwardClick,
  onFrequencyChange,
  onPeriodChange,
  hasAgendorToken,
  onActiveClick,
  onInactiveClick,
  isIntegrationActive,
}) {
  const listRef = useRef();

  const [hasBottomScroll, setHasBottomScroll] = useState(false);
  const [hasTopScroll, setHasTopScroll] = useState(false);

  useEffect(() => {
    const { scrollHeight, offsetHeight, scrollTop } = listRef.current;
    const showBottom = (scrollTop + offsetHeight) !== scrollHeight;
    handleShowScroll(false, showBottom);
  }, []);

  const handleListScroll = (e) => {
    const { scrollHeight, offsetHeight, scrollTop } = e.target;
    const scrollBottom = scrollHeight - (scrollTop + offsetHeight);
    const showTop = scrollTop > 0;
    const showBottom = scrollBottom > 0;
    handleShowScroll(showTop, showBottom);
  };

  const handleShowScroll = (showTop, showBottom) => {
    if (showTop) {
      setHasTopScroll(true);
    } else {
      setHasTopScroll(false);
    }
    if (showBottom) {
      setHasBottomScroll(true);
    } else {
      setHasBottomScroll(false);
    }
  };

  const listClasses = clsx(classes.activitiesList,
    { [classes.topScroll]: hasTopScroll },
    { [classes.bottomScroll]: hasBottomScroll });

  return (
    <>
      <AgendorIntegrationButton
        isActive={isIntegrationActive}
        onActiveClick={onActiveClick}
        onInactiveClick={onInactiveClick}
        location='Activity Activities'
      />
      <QuestionStructure
        titleNumber={2}
        title="Quantas atividades você tem feito?"
        subtitle="Informe nos campos abaixo a média de atividades que você faz por dia, semana ou mês. Isso será essencial para calcularmos a produtividade ideal para realizar seu Sonho."
        onBackClick={onBackClick}
        onForwardClick={onForwardClick}
        isForwardDisabled={!isAllCompleted(activities)}
      >
        <>
          <div className={classes.headers}>
            <Hidden smDown>
              <p className={classes.headersText}>ATIVIDADE</p>
            </Hidden>
            <p className={classes.headersText}>MINHA FREQUÊNCIA</p>
          </div>
          <Hidden smDown>
            <hr className={classes.divider} />
          </Hidden>
          <ul ref={listRef} className={listClasses} onScroll={handleListScroll}>
            {
              activities.map((activity, i) => (
                <ActivityRow
                  key={i}
                  {...activity}
                  handleFrequencyChange={onFrequencyChange}
                  handlePeriodChange={onPeriodChange}
                  isIntegrationActive={isIntegrationActive}
                  noDivider={Boolean(i === activities.length - 1)}
                />
              ))
            }
          </ul>
        </>
      </QuestionStructure>
    </>
  );
}

const isAllCompleted = (activites) => {
  const completedActivties = activites.filter((activity) => (
    (typeof activity.frequency === 'number' && activity.currentFrequency !== '')
  ));
  return completedActivties.length === activites.length;
};

export default withStyles(styles)(Activities);
