import React, { forwardRef } from 'react';
import { format } from 'date-fns';
import ptLocale from 'date-fns/locale/pt';
import { withStyles } from '@material-ui/core/styles';

import Icon from '../../../components/Icon';
import styles from './styles';

const DreamStep = forwardRef((props, ref) => {
  const {
    classes,
    dreamText,
    dreamPrice,
    dreamDate,
    isManager
  } = props;

  const formatter = new Intl.NumberFormat('pt-BR');
  const formatedPrice = formatter.format(dreamPrice);
  const formatedDate = dreamDate ? format(
    new Date(dreamDate),
    'd, LLL. yyyy',
    { locale: ptLocale },
  ) : '';
  return (
    <div ref={ref} className={classes.background}>
      <Icon name="sparkleTrophy" />
      <h2 className={classes.title}>
        {
          isManager
            ? `SONHO`
            : 'MEU SONHO'
        }
      </h2>
      <h3 className={classes.dreamTitle}>
        {dreamText}
      </h3>
      <div className={classes.dreamFooter}>
        <div className={classes.dreamPriceWrapper}>
          <div className={classes.centralize}>
            <Icon name="wallet" />
            <div className={classes.text}>
              R$
              {formatedPrice}
            </div>
          </div>
        </div>
        <div className={classes.dreamDateWrapper}>
          <div className={classes.centralize}>
            <Icon name="blackCalendar" />
            <div className={classes.text}>
              {formatedDate}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default withStyles(styles)(DreamStep);
