import arrow from '../../assets/vectors/arrow.svg';

export default (theme) => ({
  selectWrapper: {
    position: 'relative',
    '&:after': {
      content: "''",
      position: 'absolute',
      top: theme.spacing(2.75),
      right: theme.spacing(2.5),
      backgroundImage: `url(${arrow})`,
      height: theme.spacing(1.5),
      width: theme.spacing(1),
      transform: 'rotate(-90deg)',
      pointerEvents: 'none',
    },
  },
  select: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '1rem',
    border: 'none',
    fontFamily: 'inherit',
    padding: theme.spacing(2, 2),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.screenGrey.main,
    width: '100%',
    textAlign: 'center',
    position: 'relative',
    appearance: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
});
