export default (theme) => ({
  wrapper: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    position: 'fixed',
    top: 'calc(50vh - 157px)',
    left: theme.spacing(8),
    '@media print': {
      display: 'none'
    }
  },
  item: {
    padding: theme.spacing(2.5),
    border: '2px solid #f7f5ff',
    borderRadius: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
    width: 66,
    height: 66,
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  itemSelected: {
    padding: theme.spacing(2.5, 6, 2.5, 2.5),
    backgroundColor: theme.palette.screenGrey.light,
    borderRadius: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
    border: '2px solid transparent',
    width: 'auto',
    height: 'auto',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      height: 6,
      width: 6,
      backgroundColor: '#0C0030',
      top: 30,
      right: 21,
      borderRadius: 3,
    },
  },
  sideIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
});
