export default (theme) => ({
  card: {
    border: `4px solid ${theme.palette.screenGrey.light}`,
    borderRadius: theme.spacing(1.5),
    width: 'fit-content',
    margin: '0 auto',
    position: 'relative',
    padding: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      padding: theme.spacing(3),
      boxSizing: 'border-box',
    },
    '&:hover': {
      backgroundColor: theme.palette.screenGrey.light,
    },
  },
  title: {
    fontWeight: 'bold',
    letterSpacing: '0.04em',
    color: '#0C0030',
    marginTop: 0,
    marginBottom: '5px',
    textTransform: 'uppercase',
  },
  header: {
    display: 'block',
    position: 'relative',
    marginBottom: theme.spacing(4),
    '@media print': {
      marginBottom: '0px'
    }
  },
  updatedAt: {
    cursor: 'pointer',
    display: 'inline-block',
    position: 'absolute',
    top: '0px',
    right: '0px',
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    textAlign: 'right',
    color: '#A49AB8',
    '@media print': {
      display: 'none'
    }
  },
  asterisk: {
    color: '#A49AB8',
  },
  activities: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  activityWrapper: {
    margin: theme.spacing(0, 4),
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 1, 2.5),
      '&:first-child': {
        margin: theme.spacing(1, 1, 2.5),
      },
      '&:last-child': {
        margin: theme.spacing(1, 1, 2.5),
      },
    },
    '@media print': {
      marginBottom: '0px!important'
    }
  },
  activity: {
    display: 'flex',
    alignItems: 'center'
  },
  activityIntegration: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  activityNumber: {
    color: '#0C0030',
    letterSpacing: '-0.04rem',
    fontWeight: 'bold',
    fontSize: '3.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    }
  },
  activityNumberIntegration: {
    fontWeight: 'bold',
    fontSize: '2.125rem',
    color: theme.palette.grey.dark,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  activityTotalNumberIntegration: {
    fontWeight: 'bold',
    fontSize: '1rem',
    color: theme.palette.grey.dark,
  },
  activityIconIntegration: {
    fill: theme.palette.primary.main,
  },
  activityIconDoneIntegration: {
    fill: theme.palette.success.main,
  },
  activityIcon: {
    marginLeft: theme.spacing(1),
    fill: theme.palette.primary.main,
  },
  activityText: {
    margin: 0,
    color: '#0C0030',
    letterSpacing: '-0.02rem',
    fontWeight: 'bold',
    fontSize: '1rem',
    textAlign: ({ hasAgendor }) => hasAgendor ? 'center' : null
  },
  legend: {
    margin: 0,
    fontWeight: 500,
    fontSize: '0.875rem',
    color: '#A49AB8',
    '@media print': {
      display: 'none'
    }
  },
  legendHighlight: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  integrationSelect: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'inline-block',
    width: theme.spacing(25),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 0),
      width: '100%',
    },
    '@media print': {
      display: 'none'
    }
  },
});
