export default (theme) => ({
  cardsList: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    margin: 0,
    padding: 0,
    overflow: 'auto',
    maxHeight: '50vh',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
      maxHeight: 'none',
      overflow: 'auto',
      width: '90vw',
      alignItems: 'stretch',
    },
  },
  bottomScroll: {
    '&:after': {
      height: theme.spacing(2),
      content: '""',
      position: 'absolute',
      background: 'linear-gradient(0deg, #e4e3e3 -186.21%, rgb(241 241 241 / 0%) 95%)',
      width: '100%',
      bottom: 0,
    },
  },
  topScroll: {
    '&:before': {
      height: theme.spacing(2),
      content: '""',
      position: 'absolute',
      background: 'linear-gradient(180deg, #e4e3e3 -186.21%, rgb(241 241 241 / 0%) 95%)',
      width: '100%',
    },
  },
});
