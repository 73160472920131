import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles.js';

function Textarea({
  classes, placeholder, value, onChange, maxLength,
}) {
  return (
    <textarea
      className={classes.textarea}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      maxLength={maxLength}
    />
  );
}

export default withStyles(styles)(Textarea);
