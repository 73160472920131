import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Hidden from '@material-ui/core/Hidden';
import SuccessSquare from '../../../components/SuccessSquare';
import Icon from '../../../components/Icon';
import ContainedInput from '../../../components/ContainedInput';
import Select from '../../../components/Select';
import { getActivityName } from '../../../utils';

import styles from './styles';

const OPTIONS = [
  { value: 'day', label: 'dia' },
  { value: 'week', label: 'semana' },
  { value: 'month', label: 'mês' },
];

function ActivityRow({
  classes,
  activity,
  currentFrequency,
  period,
  frequency,
  handleFrequencyChange,
  handlePeriodChange,
  isIntegrationActive,
  noDivider = false
}) {
  const handleInputChange = (e) => {
    handleFrequencyChange(activity, period, e.target.value);
  };
  const handleSelectChange = (e) => {
    handlePeriodChange(activity, e.target.value, currentFrequency);
  };

  return (
    <>
      <li className={classes.row}>
        <div className={clsx(classes.centralize, classes.activityColumn)}>
          <Icon name={activity} className={classes.activityIcon} />
          <p className={classes.activityName}>
            {getActivityName(activity)}
          </p>
        </div>
        <div className={clsx(classes.centralize, classes.frequencyColumn)}>
          <span className={classes.inputWidth}>
            <ContainedInput
              type="number"
              placeholder="00"
              value={currentFrequency}
              onChange={handleInputChange}
              inputClasses={isIntegrationActive ? classes.integratedInput : {}}
            />
          </span>
          <p className={classes.noName}>por</p>
          <div className={classes.selectWrapper}>
            <Select
              onChange={handleSelectChange}
              value={period}
              options={OPTIONS}
              className={isIntegrationActive ? classes.integratedInput : {}}
            />
          </div>
          <Hidden smDown>
            <span className={classes.margin}>
              {
                (currentFrequency !== '')
                  ? <SuccessSquare />
                  : <div className={classes.emptySuccessSquare} />
              }
            </span>
          </Hidden>
        </div>
      </li>
      {
        !noDivider && (
        <Hidden mdUp>
          <hr className={classes.divider} />
        </Hidden>
        )
      }
    </>
  );
}

export default withStyles(styles)(ActivityRow);
