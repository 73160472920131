export default (theme) => ({
  wrapper: {
    display: 'flex',
  },
  monthText: {
    marginTop: theme.spacing(8),
    fontSize: '1rem',
    color: theme.palette.primary.main,
    letterSpacing: -0.8,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  integratedInput: {
    color: '#5C57FF',
  },
});
