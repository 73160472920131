import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

import SignOutButton from '../SignOutButton';

function SurveyHeader({ classes, title, subTitle }) {

  return (
    <header className={classes.header}>
      <h1 className={classes.headerTitle}>
        { title }
        {' '}
        ・
        <span className={classes.gray}>
          { subTitle }
        </span>
      </h1>
      <SignOutButton
        classes={{
          root: classes.linkButtonRoot,
          label: classes.buttonLabel,
        }}
      />
    </header>
  );
}

export default withStyles(styles)(SurveyHeader);
