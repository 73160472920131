import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { DatePicker } from '@material-ui/pickers';

function CalendarPicker({ value, onChange, maxDate }) {
  return (
    <DatePicker
      variant="dialog"
      inputVariant="outlined"
      format="d, LLL. yyyy"
      margin="normal"
      maxDate={maxDate}
      value={value}
      onChange={onChange}
      TextFieldComponent={Calendar}
    />
  );
}

const useCalendarStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '5rem',
    margin: theme.spacing(5, 0),
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '2.5rem',
    },
  },
}));

function Calendar(props) {
  const classes = useCalendarStyles();
  return (
    <p className={classes.text} onClick={props.onClick}>
      {props.value}
    </p>
  );
}

export default CalendarPicker;
