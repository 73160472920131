import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import CompetenceModal from '../CompetenceModal';
import Icon from '../Icon';

import styles from './styles';

function CompetenceCard({
  classes,
  icon,
  title,
  description,
  selectedCompetences,
  onClick,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleClick = (e) => {
    e.stopPropagation();
    onClick(title);
  };

  const isSelected = selectedCompetences.includes(title);

  const cardClasses = clsx(
    classes.card,
    { [classes.cardSelected]: isSelected },
  );
  const buttonClasses = clsx(
    classes.button,
    { [classes.buttonSelected]: isSelected },
  );

  return (
    <>
      <li className={cardClasses} onClick={handleOpenModal}>
        <Icon
          name={isSelected ? `${icon}Selected` : icon}
          className={classes.icon}
        />
        <h1 className={classes.title}>{title}</h1>
        <button
          className={buttonClasses}
          onClick={handleClick}
        >
          {
            isSelected
              ? <Icon name="check" className={classes.checkIcon} />
              : 'escolher'
          }
        </button>
      </li>
      <CompetenceModal
        open={isModalOpen}
        onClose={handleCloseModal}
        icon={icon}
        description={description}
        title={title}
      />
    </>
  );
}

export default withStyles(styles)(CompetenceCard);
